







































import Vue from "vue";
import Component from "vue-class-component";
import { Action, State } from "vuex-class";

import { UserModel } from "@/models/user/user.model";
import { Prop } from "vue-property-decorator";
import toast from "@/functions/toast.function";

@Component
export default class InputEmail extends Vue {
  /* VUEX */

  /* ACTION */
  @Action("actionForgotPassword", { namespace: "user" })
  private actionForgotPassword: any;

  /* STATE */
  @State("user_auth", { namespace: "user" })
  private user_auth!: UserModel;

  @Prop({ default: false })
  private isShow!: boolean;

  /* INITIALIZATION */

  /* METHODS */
  private onForgotPassword() {
    this.actionForgotPassword({
      success: () => {
        toast.success("OTP has been sent! Please check your email");
        this.$emit("on-success");
      },
    });
  }
}
