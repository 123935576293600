/* eslint-disable @typescript-eslint/no-unused-vars */
import { ActionTree } from "vuex";
import { AxiosResponse } from "axios";

import { RootState } from "../types";
import { QuestionState } from "./types";

import { TOKEN_INVALID } from "@/constants/enum.constant";
import { ResponseModel } from "@/models/response/response.model";
import {
  deleteToken,
  getRequestKey,
  getResponseKey,
} from "@/functions/token.function";
import {
  DecryptAES,
  EncryptAES,
  RefreshKeyFromToken,
} from "@/functions/crypto.function";

import router from "@/router";
import toast from "@/functions/toast.function";
import QuestionService from "@/services/question.service";

import {
  QuestionAnswerModel,
  QuestionChoiceModel,
  QuestionModel,
} from "@/models/question/question.model";

const service: QuestionService = new QuestionService();
// const questionType = QuestionType;

export const actions: ActionTree<QuestionState, RootState> = {
  actionGetListQuestion({ state }, callback: any) {
    service.serviceQuestionList().then((response: AxiosResponse) => {
      if (response.status == 200) {
        if (typeof response.data === "string") {
          // Decrypt model response with AES using ResponseKey
          const resJSON = DecryptAES(response.data, getResponseKey());
          const mResponse = new ResponseModel();

          mResponse.setObject(resJSON);
          if (mResponse.token) {
            RefreshKeyFromToken(mResponse.token);
          }

          console.log("RESPONSE USER/QUESTION : ", mResponse);
          if (mResponse.status) {
            state.array_question = new Array<QuestionModel>();

            mResponse.results.forEach((item: any) => {
              const modelItem = new QuestionModel();
              modelItem.setObject(item);

              state.array_question.push(modelItem);
            });
            if (callback && callback.success) {
              callback.success(mResponse);
            }
          } else {
            if (callback && callback.fail) {
              callback.fail();
            }
          }
        } else {
          const { message } = response.data;
          toast.error(message);
          if (callback && callback.fail) {
            callback.fail();
          }
          if (TOKEN_INVALID.includes(message)) {
            deleteToken();
            router.push({ name: "signin" }, () => {
              location.reload();
            });
          }
        }
      } else {
        if (callback && callback.fail) {
          callback.fail();
        }
      }
    });
  },
  actionSaveAnswer({ state }, callback: any) {
    const { question, answer, is_write = false } = callback.params;

    let answer_data: any = null;

    if (is_write) {
      answer_data = answer;
    } else {
      answer_data = answer.filter((choice: QuestionChoiceModel) => {
        return choice.question_id == question.id;
      });
    }

    if (answer_data.length < 1) {
      toast.warning("Please select answer!");
      return;
    }

    const answer_params = new QuestionAnswerModel();

    answer_params.id = question.id;
    answer_data.forEach((item: any) => {
      if (is_write) {
        answer_params.choice.push(item);
      } else {
        answer_params.choice.push(item.text);
      }
    });

    const plainText = JSON.stringify(
      Object.assign(
        {},
        {
          id: answer_params.id.toString(),
          choice: answer_params.choice,
        }
      )
    );

    // Encrypt model request with AES using RequestKey
    const params = EncryptAES(plainText, getRequestKey());

    service.serviceQuestionAnswer(params).then((response: AxiosResponse) => {
      if (response && response.status == 200) {
        if (typeof response.data === "string") {
          // Decrypt model response with AES using ResponseKey
          const resJSON = DecryptAES(response.data, getResponseKey());
          const mResponse = new ResponseModel();

          mResponse.setObject(resJSON);
          if (mResponse.token) {
            RefreshKeyFromToken(mResponse.token);
          }

          console.log("RESPONSE USER/ANSWER-QUESTION : ", mResponse);

          if (mResponse.status) {
            toast.success("Answer question succesfully");
            if (callback && callback.success) {
              callback.success(mResponse);
            }
          } else {
            if (callback && callback.fail) {
              callback.fail();
            }
          }
        } else {
          const { message } = response.data;
          toast.error(message);
          if (callback && callback.fail) {
            callback.fail();
          }
          if (TOKEN_INVALID.includes(message)) {
            deleteToken();
            router.push({ name: "signin" }, () => {
              location.reload();
            });
          }
        }
      }
    });
  },
  actionSelectQuestionAnswer({ state }, payload: any) {
    state.answer_question.setObject(payload);
  },
  actionSelectDeleteAnswer({ state }, payload: any) {
    state.delete_answer.setObject(payload);
  },
  actionDeleteAnswerQuestion({ state }, callback: any) {
    const { answer_id } = state.delete_answer;

    if (!answer_id) {
      toast.warning("Answer question is required!");
      return;
    }

    const plainText = JSON.stringify({ id: answer_id });

    // Encrypt model request with AES using RequestKey
    const params = EncryptAES(plainText, getRequestKey());

    service
      .serviceQuestionDelete(params)
      .then((response: AxiosResponse) => {
        if (response.status == 200) {
          if (typeof response.data === "string") {
            // Decrypt model response with AES using ResponseKey
            const resJSON = DecryptAES(response.data, getResponseKey());
            const mResponse = new ResponseModel();

            mResponse.setObject(resJSON);
            if (mResponse.token) {
              RefreshKeyFromToken(mResponse.token);
            }

            state.delete_answer = new QuestionModel();
            console.log("RESPONSE QUESTION/DELETE-ANSWER : ", mResponse);

            if (callback.success) {
              callback.success(mResponse);
            }
          } else {
            const { message } = response.data;
            toast.error(message);
            if (TOKEN_INVALID.includes(message)) {
              deleteToken();
              router.push({ name: "signin" }, () => {
                location.reload();
              });
            }
          }
        }
      })
      .catch((e: any) => {
        const { message } = e.response.data;
        toast.error(message);
        if (TOKEN_INVALID.includes(message)) {
          deleteToken();
          router.push({ name: "signin" }, () => {
            location.reload();
          });
        }
      });
  },
};
