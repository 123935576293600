<template>
  <div class="PWAPromptIOS" v-if="shownIOS">
    <div class="pwa-card">
      <span class="btn-close" @click="shownIOS = false"></span>

      <img
        src="@/assets/images/logo_smixy_dark.svg"
        alt="logo"
        class="pwa-card__logo"
      />

      <div class="pwa-card__body">
        <h2>Install Smixy</h2>

        <span>
          Install this application on your home screen for quick and easy access
          when you're on the go.
        </span>
      </div>

      <div class="pwa-card__footer">
        <span>
          Just tap
          <img src="@/assets/icons/icon_share.svg" alt="ic_share" />
          then 'Add to Home Screen'
        </span>
      </div>
    </div>
  </div>
  <div v-else-if="shown" class="PWAPrompt">
    <span>Add Smixy to home screen?</span>

    <span class="btn-close" @click="dismissPrompt"></span>

    <div class="button-wrap">
      <button class="custom-button mr-3" @click="installPWA">Install</button>

      <!-- <button class="custom-button" @click="dismissPrompt">No, thanks</button> -->
    </div>
  </div>
</template>

<script lang="js">
/**
 * https://developerx.atlassian.net/browse/SMX-578
 * https://developerx.atlassian.net/browse/SMX-579 (Updated)
 * https://developerx.atlassian.net/browse/SMX-802 (Updated)
 *
 * Created at : 02 Feb 2022           Updated at : 14 Feb 2022
 * Created by : Risman Abdilah        Updated by : Risman Abdilah
 *
 * Version 1.0.0
 */

import $ from "jquery";

export default {
  name: "PWAPrompt",
  data: () => ({
    shown: false,
    shownIOS: false,
  }),

  beforeMount() {
    const deviceWidth = $(window).width();

    if (this.isApple() && !this.isInStandaloneMode()) {
      this.shownIOS = true;
    }

    window.addEventListener('beforeinstallprompt', e => {
      e.preventDefault();
      window.pwa_event = e;
      this.installEvent = e;
      this.shown = true;
      if (deviceWidth > 764) {
        $(".scrollToTop").css({ "bottom": "105px" });
      } else {
        $(".scrollToTop").css({ "bottom": "130px" });
      }
    });
  },

  methods: {
    dismissPrompt() {
      this.shown = false
      $(".scrollToTop").css({ "bottom": "30px" });
    },

    installPWA() {
      this.installEvent.prompt()
      this.installEvent.userChoice.then(choice => {
        this.dismissPrompt() // Hide the prompt once the user's clicked
        if (choice.outcome === 'accepted') {
          // Do something additional if the user chose to install
        } else {
          // Do something additional if the user declined
        }
      })
    },

    isApple() {
      const userAgent = window.navigator.userAgent.toLowerCase();
      return /iphone|ipad|ipod/.test(userAgent);
    },

    isInStandaloneMode() {
      const navigator = window.navigator;

      if ("standalone" in navigator && navigator.standalone) {
        return true;
      }
      return false;
    },
  }
}
</script>
