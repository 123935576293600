import { BaseModel } from "../base/base.model";
import {
  InvoiceType,
  MembershipType,
  PaymentIntentType,
  PaymentMethodBillingType,
  PaymentMethodCardType,
  PaymentMethodType,
} from "./payment.types";

export class MembershipModel implements MembershipType {
  public id = 0;
  public name = "";
  public number_duration = "";
  public unit_duration = "";
  public price = "";
}

export class InvoiceModel implements InvoiceType {
  public id = 0;
  public invoice_id = "";
  public description = "";
  public discount = "";
  public price = "";
  public from = "";
  public until = "";
}

export class PaymentMethodModel extends BaseModel implements PaymentMethodType {
  public id = "";
  public type = "";
  public object = "";
  public billing_details = new PaymentMethodBillingModel();
  public card = new PaymentMethodCardModel();
}
export class PaymentIntentModel extends BaseModel implements PaymentIntentType {
  public id = "";
  public status = "";
  public client_secret = "";
}

export class PaymentMethodBillingModel implements PaymentMethodBillingType {
  public name = "";
  public phone = "";
  public email = "";
}

export class PaymentMethodCardModel implements PaymentMethodCardType {
  public brand = "";
  public country = "";
  public exp_month = 0;
  public exp_year = 0;
}
