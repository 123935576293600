




















































































































import Vue from "vue";
import Component from "vue-class-component";
import { Action, Getter, State } from "vuex-class";

import firebase from "@/firebase";

import { ASSETS } from "@/services/base/apibase";
import { UserModel } from "@/models/user/user.model";
import { ChannelModel } from "@/models/chat/chat.model";

@Component
export default class Navbar extends Vue {
  /* VUEX */

  /* ACTIONS */
  @Action("changeLocale")
  private changeLocale: any;
  @Action("actionLogout", { namespace: "user" })
  private actionLogout: any;

  /* STATE */
  @State("locale")
  private locale: any;
  @State("user_profile", { namespace: "user" })
  private user_profile!: UserModel;
  @State("array_channel", { namespace: "chat" })
  private array_channel!: Array<ChannelModel>;

  @Getter("isLoggedIn", { namespace: "user" })
  private isLoggedIn!: boolean;


  public get chat_unread_count(): number  {
    let accumulator = 0
    this.array_channel.forEach((list, i) => {
      accumulator += list.unread_count
    });
    return accumulator
  }


  /* INITIALIZATION */
  private language = null;

  private created() {
    this.language = this.locale;
  }

  /* METHODS */
  private isLanding() {
    const { name } = this.$route;

    return name === "landing";
  }

  private async clickLogout() {
    if (firebase) {
      const fcm_token = await firebase.fcmToken;
      if (fcm_token) {
        this.actionLogout({
          params: fcm_token,
          success: () => {
            this.clickMenu("landing", true);
          },
        });
      } else {
        this.defaultLogout();
      }
    } else {
      this.defaultLogout();
    }
  }

  private defaultLogout() {
    this.actionLogout({
      params: null,
      success: () => {
        this.clickMenu("landing", true);
      },
    });
  }

  private clickMenu(menu: string, isReload = false) {
    const { name } = this.$route;

    if (name !== menu) {
      this.$router.push({ name: menu }, () => {
        if (isReload) {
          location.reload();
        }
      });
    } else if (menu === "landing") {
      location.reload();
    }
  }

  private checkMenuActive(menus: Array<string>) {
    if (menus) {
      return menus.includes(String(this.$route.name));
    }
    return false;
  }

  private getImage(image: string) {
    if (image) {
      return ASSETS + "user/photo/" + image;
    }
    return "";
  }

  private toggleChat() {
    if (this.$route.name === 'dashboard') {
      this.$router.replace({name: 'dashboard', query:{'chat': '1'}})
    }else this.$router.push({name: 'dashboard', query:{'chat': '1'}})
  }
}
