import { MutationTree } from "vuex";
import { MiscState } from "./types";

export const mutations: MutationTree<MiscState> = {
  mutationList(state, payload: any) {
    state.array_ethnic = new Array<any>();
    state.array_eye_color = new Array<any>();
    state.array_gender = new Array<any>();
    state.array_hair_color = new Array<any>();
    state.array_language = new Array<any>();
    state.array_location = new Array<any>();
    state.array_looking_for = new Array<any>();
    state.array_membership = new Array<any>();
    state.array_reason_deleted = new Array<any>();
    state.array_report_type = new Array<any>();
    state.array_wear_glasses = new Array<any>();

    console.log("RESPONSE FILTER LIST : ", payload);

    const {
      ethnic,
      eye_color,
      gender,
      hair_color,
      language,
      location,
      looking_for,
      membership,
      reason_deleted,
      report_type,
      wear_glasses,
    } = payload.results;

    if (ethnic) {
      ethnic.forEach((item: any) => {
        state.array_ethnic.push(item.name);
      });
    }
    if (eye_color) {
      eye_color.forEach((item: any) => {
        state.array_eye_color.push(item.name);
      });
    }
    if (gender) {
      gender.forEach((item: any) => {
        state.array_gender.push(item.name);
      });
    }
    if (hair_color) {
      hair_color.forEach((item: any) => {
        state.array_hair_color.push(item.name);
      });
    }
    if (language) {
      language.forEach((item: any) => {
        state.array_language.push(item.name);
      });
    }
    if (location) {
      location.forEach((item: any) => {
        // state.array_location.push(item.name);
        state.array_location.push(item);
      });
    }
    if (looking_for) {
      looking_for.forEach((item: string) => {
        state.array_looking_for.push(item);
      });
    }
    if (membership) {
      membership.forEach((item: any) => {
        state.array_membership.push(item.name);
      });
    }
    if (reason_deleted) {
      reason_deleted.forEach((item: any) => {
        state.array_reason_deleted.push(item.name);
      });
    }
    if (report_type) {
      report_type.forEach((item: any) => {
        state.array_report_type.push(item.name);
      });
    }
    if (wear_glasses) {
      wear_glasses.forEach((item: string) => {
        state.array_wear_glasses.push(item);
      });
    }
  },
  mutationFAQList(state, payload: any) {
    state.array_faq = payload;
  },
};
