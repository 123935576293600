












































































































import Vue from "vue";
import Component from "vue-class-component";
import { Action, State } from "vuex-class";

import { ASSETS } from "@/services/base/apibase";
import { UserModel } from "@/models/user/user.model";

@Component
export default class Sidebarleft extends Vue {
  /* VUEX */

  /* ACTIONS */
  @Action("actionListChannel", { namespace: "chat" })
  private actionListChannel: any;

  /* STATE */
  @State("user_profile", { namespace: "user" })
  private user_profile!: UserModel;
  @State("unread_count", { namespace: "notification" })
  private unread_count!: number;

  /* INITIALIZATION */

  private get isCenterClass() {
    if (this.user_profile.membership_id) {
      return "center";
    }

    if (this.user_profile.total_favourite < 1) {
      return "center";
    }

    return "";
  }

  /* METHODS */
  private onShowMessage() {
    this.actionListChannel({
      params: this.user_profile.id,
    });
    this.$emit("on-change", 2);
  }

  private getImage(image: string) {
    if (image) {
      return ASSETS + "user/photo/" + image;
    }
    return "";
  }
}
