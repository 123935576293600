























































import Vue from "vue";
import moment from "moment";
import Component from "vue-class-component";
import { Action, State } from "vuex-class";

import { ASSETS } from "@/services/base/apibase";
import { NotificationModel } from "@/models/notification/notification.model";

@Component
export default class ModalNotification extends Vue {
  /* VUEX */

  /* ACTIONS */
  @Action("actionSelectNotification", { namespace: "notification" })
  private actionSelectNotification: any;
  @Action("actionUpdateNotification", { namespace: "notification" })
  private actionUpdateNotification: any;

  /* STATE */
  @State("array_notification_unread", { namespace: "notification" })
  private array_notification_unread!: Array<number>;
  @State("array_notification_general", { namespace: "notification" })
  private array_notification_general!: Array<NotificationModel>;
  @State("array_notification_profile", { namespace: "notification" })
  private array_notification_profile!: Array<NotificationModel>;

  /* INITIALIZATION */
  private get array_notification() {
    return [
      ...this.array_notification_general,
      ...this.array_notification_profile,
    ];
  }

  /* METHODS */
  private getTime(value: any) {
    return moment(value).fromNow();
  }

  private isActive(notification: NotificationModel) {
    return this.array_notification_unread.includes(notification.id);
  }

  private onReadAll() {
    this.$bvModal.show("lds-loader");
    this.actionUpdateNotification({
      params: { id: this.array_notification_unread },
      success: () => {
        this.actionSelectNotification();
        this.$bvModal.hide("lds-loader");
      },
    });
  }

  private onDetail(notification: NotificationModel) {
    this.actionUpdateNotification({
      params: { id: [notification.id] },
      success: () => {
        this.$bvModal.hide("usernotification");
        if (notification.user.id) {
          this.$router.push({
            name: "detail-seeker",
            params: { id: notification.user.id.toString() },
          });
        }
      },
    });
  }

  private userPhoto(filename: string, useThumb = false) {
    if (filename) {
      if (useThumb) {
        return ASSETS + "user/photo/thumb/" + filename;
      }
      return ASSETS + "user/photo/" + filename;
    }
    return "";
  }
}
