import { ActionTree } from "vuex";
import { AxiosResponse } from "axios";

import { RootState } from "../types";
import { RegionState } from "./types";

import { LocationModel } from "@/models/region/region.model";
import { ResponseModel } from "@/models/response/response.model";
import { deleteToken, getResponseKey } from "@/functions/token.function";
import { DecryptAES, RefreshKeyFromToken } from "@/functions/crypto.function";

import router from "@/router";
import toast from "@/functions/toast.function";
import RegionService from "@/services/region.service";
import { TOKEN_INVALID } from "@/constants/enum.constant";

const service: RegionService = new RegionService();

export const actions: ActionTree<RegionState, RootState> = {
  actionGetLocation({ state }, callback: any) {
    service.serviceLocation().then((response: AxiosResponse) => {
      if (response.status === 200) {
        if (typeof response.data === "string") {
          // Decrypt model response with AES using ResponseKey
          const resJSON = DecryptAES(response.data, getResponseKey());
          const mResponse = new ResponseModel();

          mResponse.setObject(resJSON);
          if (mResponse.token) {
            RefreshKeyFromToken(mResponse.token);
          }

          if (mResponse.status) {
            state.array_location = new Array<LocationModel>();

            if (mResponse.results) {
              mResponse.results.forEach((item: LocationModel) => {
                state.array_location.push(item);
              });
            }

            if (callback && callback.success) {
              callback.success(mResponse);
            }
          }
        } else {
          const { message } = response.data;
          toast.error(message);
          if (TOKEN_INVALID.includes(message)) {
            deleteToken();
            router.push({ name: "signin" }, () => {
              location.reload();
            });
          }
        }
      } else {
        if (callback && callback.fail) {
          callback.fail();
        }
      }
    });
  },
  actionResetState({ state }) {
    state.array_state = new Array<LocationModel>();
  },
  actionGetState({ state }, callback: any) {
    let locationId;

    if (callback.params) {
      locationId = callback.params;
    }

    service.serviceState(locationId).then((response: AxiosResponse) => {
      if (response.status === 200) {
        if (typeof response.data === "string") {
          // Decrypt model response with AES using ResponseKey
          const resJSON = DecryptAES(response.data, getResponseKey());
          const mResponse = new ResponseModel();

          mResponse.setObject(resJSON);
          if (mResponse.token) {
            RefreshKeyFromToken(mResponse.token);
          }

          console.log("RESPONSE REGION STATE : ", mResponse);

          if (mResponse.status) {
            state.array_state = new Array<LocationModel>();
            if (mResponse.results) {
              mResponse.results.forEach((item: LocationModel) => {
                state.array_state.push(item);
              });
            }

            if (callback && callback.success) {
              callback.success(mResponse);
            }
          }
        } else {
          const { message } = response.data;
          toast.error(message);
          if (TOKEN_INVALID.includes(message)) {
            deleteToken();
            router.push({ name: "signin" }, () => {
              location.reload();
            });
          }
        }
      }
    });
  },
  actionGetLocationDummy({ state }) {
    state.array_location = new Array<LocationModel>();
    dummy_location.forEach((item) => {
      const locModel = new LocationModel();
      locModel.setObject(item);

      state.array_location.push(locModel);
    });
  },
};

const dummy_location = [
  { id: 1, name: "United Kingdom", locale: "gb" },
  { id: 2, name: "United States", locale: "us" },
  { id: 3, name: "Australia", locale: "au" },
  { id: 4, name: "Canada", locale: "kn" },
];
