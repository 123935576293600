import { getAuthKey, getToken } from "@/functions/token.function";
import { GetterTree } from "vuex";
import { RootState } from "../types";
import { UserState } from "./types";

export const getters: GetterTree<UserState, RootState> = {
  isLoggedIn() {
    const token = getToken();
    const auth = getAuthKey();

    if (!auth && token) {
      return true;
    }
    return false;
  },
};
