




import Vue from "vue";
import moment from "moment";
import Component from "vue-class-component";
import { Action, Getter, State } from "vuex-class";

import { UserModel } from "./models/user/user.model";

import AuthLayout from "@/layouts/AuthLayout.vue";
import AppLayout from "@/layouts/AppLayout.vue";
import { deleteToken, getToken } from "./functions/token.function";

@Component({
  components: {
    AuthLayout,
    AppLayout,
  },
})
export default class App extends Vue {
  /* VUEX */

  /* ACTIONS */
  @Action("authLayout")
  private authLayout: any;
  @Action("appLayout")
  private appLayout: any;
  @Action("actionGenerateToken", { namespace: "handshake" })
  private actionGenerateToken: any;
  @Action("actionCheckUser", { namespace: "user" })
  private actionCheckUser: any;
  @Action("actionGetMembership", { namespace: "user" })
  private actionGetMembership: any;
  @Action("actionExploreSeeker", { namespace: "seeker" })
  private actionExploreSeeker: any;
  @Action("actionFavoriteSeeker", { namespace: "seeker" })
  private actionFavoriteSeeker: any;
  @Action("actionListChannel", { namespace: "chat" })
  private actionListChannel: any;
  @Action("actionSelectNotification", { namespace: "notification" })
  private actionSelectNotification: any;
  @Action("actionListMembership", { namespace: "payment" })
  private actionListMembership: any;
  @Action("actionMembershipHistory", { namespace: "payment" })
  private actionMembershipHistory: any;
  @Action("actionGetListPhoto", { namespace: "user" })
  private actionGetListPhoto: any;

  /* STATE */
  @State("layout")
  private layout: any;
  @State("locale")
  private locale: any;
  @State("user_profile", { namespace: "user" })
  private user_profile!: UserModel;

  @Getter("isLoggedIn", { namespace: "user" })
  private isLoggedIn!: boolean;

  /* INITIALIZATION */
  private created() {

    this.$i18n.locale = this.locale;
    moment.locale(this.locale);

    this.authLayout();
  }

  private mounted() {
    const { name } = this.$route;
    if (getToken() && this.isLoggedIn) {
      if (name === "signin") {
        this.navigate("dashboard", true);
      } else {
        this.actionCheckUser({
          success: () => {
            this.onCheckUserSuccess();
          },
          fail: () => {
            // Todo
          },
        });
      }
    } else {
      deleteToken();
      this.generateToken();
    }
  }

  /* METHODS */
  private onCheckUserSuccess() {
    const { name } = this.$route;

    this.actionGetMembership();
    if (name === "dashboard") {
      this.getExplore();
      this.actionListChannel({
        params: this.user_profile.id,
      });
    }
    // else if (name === "profile") {
    //   this.actionGetListPhoto();
    // } else if (name === "membership") {
    //   this.actionListMembership();
    // } else if (name === "purchase-history") {
    //   this.actionMembershipHistory();
    // }
  }

  private generateToken() {
    this.actionGenerateToken({
      params: null,
      success: () => {
        // TODO
      },
      fail: () => {
        // TODO
      },
    });
  }

  private getExplore() {
    this.actionExploreSeeker({
      success: () => {
        this.actionSelectNotification();
        this.actionFavoriteSeeker();
      },
    });
  }

  private navigate(routeName: string, isReload = false) {
    const { name } = this.$route;

    if (name !== routeName) {
      this.$router.push({ name: routeName }, () => {
        if (isReload) {
          location.reload();
        }
      });
    }
  }
}
