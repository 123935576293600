














































































































































































































































































































import Vue from "vue";
import $ from "jquery";
import Component from "vue-class-component";
import { Action, State } from "vuex-class";

import { ASSETS } from "@/services/base/apibase";
import { UserModel } from "@/models/user/user.model";
import { ChannelModel } from "@/models/chat/chat.model";
import { QuestionModel } from "@/models/question/question.model";
import { SeekerModel, SeekerPhotoModel } from "@/models/seeker/seeker.model";

import ModalMessageComponent from "@/components/modal/Message.vue";
import { ResponseModel } from "@/models/response/response.model";

@Component({
  components: {
    ModalMessageComponent,
  },
})
export default class DetailSeeker extends Vue {
  /* VUEX */

  /* ACTIONS */
  @Action("appLayout")
  private appLayout: any;
  @Action("actionDetailSeeker", { namespace: "seeker" })
  private actionDetailSeeker: any;
  @Action("actionSeekerPhoto", { namespace: "seeker" })
  private actionSeekerPhoto: any;
  @Action("actionSetFavorite", { namespace: "seeker" })
  private actionSetFavorite: any;
  @Action("actionCheckOpponent", { namespace: "chat" })
  private actionCheckOpponent: any;
  @Action("actionSetOpponent", { namespace: "chat" })
  private actionSetOpponent: any;
  @Action("actionCreateChannel", { namespace: "chat" })
  private actionCreateChannel: any;
  @Action("actionListChannel", { namespace: "chat" })
  private actionListChannel: any;
  @Action("actionGetFilterList", { namespace: "misc" })
  private actionGetFilterList!: ()=>void;
  @Action("actionReportSeeker", { namespace: "seeker" })
  private actionReportSeeker!: (params: {id: number, type_name: string})=>Promise<ResponseModel>;
  @Action("actionBlockSeeker", { namespace: "seeker" })
  private actionBlockSeeker!: (params: { id: number; }) => Promise<ResponseModel>;
  @Action("actionExploreSeeker", { namespace: "seeker" })
  private actionExploreSeeker!: ()=>void;

  /* STATE */
  @State("user_profile", { namespace: "user" })
  private user_profile!: UserModel;
  @State("seeker_detail", { namespace: "seeker" })
  private seeker_detail!: SeekerModel;
  @State("seeker_detail_question", { namespace: "seeker" })
  private seeker_detail_question!: Array<QuestionModel>;
  @State("seeker_photo", { namespace: "seeker" })
  private seeker_photo!: Array<SeekerPhotoModel>;
  @State("array_channel", { namespace: "chat" })
  private array_channel!: Array<ChannelModel>;
  @State("free_chat", { namespace: "chat" })
  private free_chat!: UserModel;
  @State("array_report_type", { namespace: "misc" })
  private report_type!: string[];

  /* INITIALIZATION */
  private selected = "01";
  private selected_photo: any = null;

  private get showAdditional() {
    const { work, occupation, income, salary } = this.seeker_detail;

    if (work || occupation || income || salary) {
      return true;
    }

    return false;
  }

  private get isUnitedStates() {
    if (this.user_profile.location === "United States") {
      return true;
    }
    return false;
  }

  private created() {
    this.appLayout();

    const { id } = this.$route.params;

    if (id) {
      this.actionDetailSeeker({
        id,
        success: () => {
          this.actionSeekerPhoto({
            id,
            success: () => {
              this.selected_photo = this.seeker_photo.find(
                (item: SeekerPhotoModel) => item.is_main === true
              );
            },
          });
        },
      });
    }
  }

  private mounted() {
    this.loadOwl();
    this.scrollToTop();
    if(this.report_type.length === 0) this.actionGetFilterList();
  }

  /* METHODS */
  OnSubmitReport(ev: Event) {
    let form = new FormData(ev.target as HTMLFormElement)
    let params:any = {}
    form.forEach((v, k) => params[k] = v)
    this.actionReportSeeker({ id: this.seeker_detail.id, ...(params as { type_name: string; }) }).then((response) => {
      this.$router.push({name: "dashboard"},()=>this.actionExploreSeeker())
    })
  }

  OnSubmitBlock() {
    this.actionBlockSeeker({ id: this.seeker_detail.id }).then((response) => {
      this.$router.push({name: "dashboard"},()=>this.actionExploreSeeker())
    })
  }

  private clickFavorite(seeker: SeekerModel) {
    this.actionSetFavorite({
      params: seeker,
      success: () => {
        this.seeker_detail.is_favourite = !seeker.is_favourite;
      },
    });
  }

  private clickChat(seeker: SeekerModel) {
    this.$bvModal.show("lds-loader");

    const channel_data = new ChannelModel();
    channel_data.user_from_id = this.user_profile.id;
    channel_data.user_from.setObject(this.user_profile);

    if (this.user_profile.membership.id) {
      channel_data.user_to_id = seeker.id;
      channel_data.user_to.setObject(seeker);
      this.actionCreateChannel({
        params: channel_data,
        success: () => {
          this.$bvModal.hide("lds-loader");
          this.actionListChannel({ params: this.user_profile.id });
          this.$bvModal.show("usermessage");
        },
        error: () => {
          this.$bvModal.hide("lds-loader");
        },
      });
    } else {
      this.actionCheckOpponent({
        params: { id: seeker.id },
        success: () => {
          if (this.free_chat.id) {
            if (seeker.id === this.free_chat.id) {
              channel_data.user_to_id = this.free_chat.id;
              channel_data.user_to.setObject(this.free_chat);
              this.actionCreateChannel({
                flag: "free-chat",
                params: channel_data,
                success: () => {
                  this.$bvModal.hide("lds-loader");
                  this.actionListChannel({ params: this.user_profile.id });
                  this.$bvModal.show("usermessage");
                },
                error: () => {
                  this.$bvModal.hide("lds-loader");
                },
              });
            } else {
              this.$bvModal.hide("lds-loader");
              this.$bvModal.show("modal-no-subscribe");
            }
          } else {
            channel_data.user_to_id = seeker.id;
            channel_data.user_to.setObject(seeker);
            this.actionCreateChannel({
              params: channel_data,
              success: () => {
                this.$bvModal.hide("lds-loader");
                this.actionListChannel({ params: this.user_profile.id });
                this.$bvModal.show("usermessage");
              },
              error: () => {
                this.$bvModal.hide("lds-loader");
              },
            });
            // this.actionSetOpponent({
            //   params: { id: seeker.id },
            //   success: () => {
            //   },
            // });
          }
        },
        error: () => {
          this.$bvModal.hide("lds-loader");
        },
      });
    }
  }

  private getFeetHeight(value: string) {
    const splited = value.split("'");

    const ft = splited[0];
    const inch = parseInt(splited[1]);

    if (inch > 0) {
      return `${ft} feet ${inch} inch`;
    }

    return `${ft} feet`;
  }

  private getArrayToString(array_value: any) {
    if (array_value) {
      let array = [];

      if (typeof array_value === "string") {
        array = JSON.parse(array_value);
      } else {
        array = array_value;
      }

      return array.join(", ");
    }

    return null;
  }

  private userPhoto(filename: string, useThumb = false) {
    if (filename) {
      if (useThumb) {
        return ASSETS + "user/photo/thumb/" + filename;
      }
      return ASSETS + "user/photo/" + filename;
    }
    return "";
  }

  private loadOwl() {
    $(document).ready(function () {
      // eslint-disable-next-line no-undef
      const jquery: any = jQuery;
      jquery(".all-slider").owlCarousel({
        loop: false,
        dots: false,
        nav: true,
        navText: [
          "<i class='fa fa-angle-left'></i>",
          "<i class='fa fa-angle-right'></i>",
        ],
        margin: 0,
        autoplay: false,
        items: 4,
        autoplayTimeout: 6000,
        smartSpeed: 1000,
        responsive: {
          0: {
            items: 4,
          },
          768: {
            items: 4,
          },
        },
      });
    });
  }

  private checkActive(data: SeekerPhotoModel) {
    return data.is_main;
  }

  private clickImage(data: SeekerPhotoModel) {
    this.seeker_photo.forEach((item: SeekerPhotoModel, index) => {
      if (item.id === data.id) {
        data.is_main = true;
      } else {
        this.seeker_photo[index].is_main = false;
      }
    });

    this.selected_photo = data;
  }

  private onBack() {
    this.$router.back();
  }

  private scrollToTop() {
    $("html,body").animate({ scrollTop: 0 }, 800);
  }
}
