import router from "@/router";
import toast from "@/functions/toast.function";

import { ActionTree } from "vuex";
import { RootState } from "../types";
import { SeekerState } from "./types";
import { AxiosResponse } from "axios";

import { ResponseModel } from "@/models/response/response.model";
import {
  DecryptAES,
  EncryptAES,
  RefreshKeyFromToken,
} from "@/functions/crypto.function";
import {
  deleteToken,
  getRequestKey,
  getResponseKey,
} from "@/functions/token.function";

import SeekerService from "@/services/seeker.service";
import { createPagination } from "@/functions/global.function";
import { SeekerModel } from "@/models/seeker/seeker.model";
import { TOKEN_INVALID } from "@/constants/enum.constant";
import { QuestionModel } from "@/models/question/question.model";

const service: SeekerService = new SeekerService();

export const actions: ActionTree<SeekerState, RootState> = {
  actionExploreSeeker({ state }, callback: any) {
    const dataFilter: any = {};

    const filter_data: any = Object.assign({}, {});

    if (state.filter_explore.age_start || state.filter_explore.age_end) {
      filter_data.ages = [
        state.filter_explore.age_start
          ? parseInt(state.filter_explore.age_start)
          : 0,
        state.filter_explore.age_end
          ? parseInt(state.filter_explore.age_end)
          : 0,
      ];
    }

    if (state.filter_explore.filter_location) {
      filter_data.location = [state.filter_explore.filter_location];
    }

    if (state.filter_explore.filter_state) {
      filter_data.state = [state.filter_explore.filter_state];
    }

    if (state.filter_explore.gender) {
      filter_data.gender = [state.filter_explore.gender];
    }

    const page = callback?.page || 1;
    console.log(callback);

    if (callback?.page) {
      const temp = state.pagination_temp[page];
      if (temp) {
        state.pagination_explore = temp.pagination;
        state.array_explore = temp.seekers
        console.log("set");

        if (callback.success) {
          callback.success(temp);
          return 0
        }
      }

      dataFilter.page = callback.page;
      if (callback.page > 1) {
        dataFilter.user_list = state.pagination_explore.user_list;
      }
    }

    if (filter_data) {
      dataFilter.filter = filter_data;
    }

    const plainText = JSON.stringify(dataFilter);

    console.log("PAYLOAD EXPLORE : ", plainText);

    // Encrypt model request with AES using RequestKey
    const params = EncryptAES(plainText, getRequestKey());

    service.serviceExplore(params).then((response: AxiosResponse) => {
      if (response.status === 200) {
        if (typeof response.data === "string") {
          // Decrypt model response with AES using ResponseKey
          const resJSON = DecryptAES(response.data, getResponseKey());
          const mResponse = new ResponseModel();

          mResponse.setObject(resJSON);
          if (mResponse.token) {
            RefreshKeyFromToken(mResponse.token);
          }

          const { data } = mResponse.results;
          state.array_explore = data;
          state.pagination_explore = createPagination(mResponse.results);
          state.pagination_temp[page] = {
            pagination: state.pagination_explore,
            seekers: data
          }

          console.log("RESPONSE EXPLORE : ", mResponse);

          if (callback.success) {
            callback.success(mResponse);
          }
        } else {
          const { message } = response.data;
          toast.error(message);
          if (TOKEN_INVALID.includes(message)) {
            deleteToken();
            router.push({ name: "signin" }, () => {
              location.reload();
            });
          }
        }
      }
    });
  },
  actionDetailSeeker({ state }, callback: any) {
    let data: any = null;

    if (callback && callback.id) {
      data = Object.assign({}, { id: callback.id });
    }

    if (!data) {
      toast.warning("Seeker not Found!");
      return;
    }

    const plainText = JSON.stringify(data);

    console.log("PAYLOAD DETAIL SEEKER : ", plainText);

    // Encrypt model request with AES using RequestKey
    const params = EncryptAES(plainText, getRequestKey());

    service.serviceDetailSeeker(params).then((response: AxiosResponse) => {
      if (response.status === 200) {
        if (typeof response.data === "string") {
          // Decrypt model response with AES using ResponseKey
          const resJSON = DecryptAES(response.data, getResponseKey());
          const mResponse = new ResponseModel();

          mResponse.setObject(resJSON);
          if (mResponse.token) {
            RefreshKeyFromToken(mResponse.token);
          }

          console.log("RESPONSE DETAIL SEEKER : ", mResponse);

          if (mResponse.status) {
            state.seeker_detail.setObject(mResponse.results);
          }

          if (callback.success) {
            callback.success(mResponse);
          }
        } else {
          const { message } = response.data;
          toast.error(message);
          if (TOKEN_INVALID.includes(message)) {
            deleteToken();
            router.push({ name: "signin" }, () => {
              location.reload();
            });
          }
        }
      }
    });
  },
  actionSeekerPhoto({ state }, callback: any) {
    let data: any = null;

    if (callback && callback.id) {
      data = Object.assign({}, { id: callback.id });
    }

    if (!data) {
      toast.warning("Seeker not Found!");
      return;
    }

    const plainText = JSON.stringify(data);

    // Encrypt model request with AES using RequestKey
    const params = EncryptAES(plainText, getRequestKey());

    service.serviceDetailPhoto(params).then((response: AxiosResponse) => {
      if (response.status === 200) {
        if (typeof response.data === "string") {
          // Decrypt model response with AES using ResponseKey
          const resJSON = DecryptAES(response.data, getResponseKey());
          const mResponse = new ResponseModel();

          mResponse.setObject(resJSON);
          if (mResponse.token) {
            RefreshKeyFromToken(mResponse.token);
          }

          console.log("RESPONSE OTHER PHOTO SEEKER : ", mResponse);

          if (mResponse.status) {
            state.seeker_photo = mResponse.results;
          }
          if (mResponse.question) {
            state.seeker_detail_question = new Array<QuestionModel>();

            mResponse.question.forEach((item: any) => {
              const model_question = new QuestionModel();
              model_question.setObject(item);

              state.seeker_detail_question.push(model_question);
            });
          }

          if (callback.success) {
            callback.success(mResponse);
          }
        } else {
          const { message } = response.data;
          toast.error(message);
          if (TOKEN_INVALID.includes(message)) {
            deleteToken();
            router.push({ name: "signin" }, () => {
              location.reload();
            });
          }
        }
      }
    });
  },
  actionFavoriteSeeker({ state }, callback: any) {
    let params: any = null;

    if (callback && callback.params) {
      const data = Object.assign({}, callback.params);

      const plainText = JSON.stringify(data);

      // Encrypt model request with AES using RequestKey
      params = EncryptAES(plainText, getRequestKey());
    }

    service.serviceFavorite(params).then((response: AxiosResponse) => {
      if (response.status === 200) {
        if (typeof response.data === "string") {
          // Decrypt model response with AES using ResponseKey
          const resJSON = DecryptAES(response.data, getResponseKey());
          const mResponse = new ResponseModel();

          mResponse.setObject(resJSON);
          if (mResponse.token) {
            RefreshKeyFromToken(mResponse.token);
          }

          const { data, vip } = mResponse.results;
          state.favorite_list = new Array<SeekerModel>();
          state.vip_list = new Array<SeekerModel>();
          if (data) {
            state.favorite_list.push(...data)
          }
          if (vip) {
            state.vip_list.push(...vip);
          }

          if (callback && callback.success) {
            callback.success(mResponse);
          }
        } else {
          const { message } = response.data;
          toast.error(message);
          if (TOKEN_INVALID.includes(message)) {
            deleteToken();
            router.push({ name: "signin" }, () => {
              location.reload();
            });
          }
        }
      }
    });
  },
  actionSetFavorite({ state }, callback: any) {
    let params: any = null;

    if (!callback.params) {
      toast.warning("Seeker is required!");
      return;
    }

    const { id, is_favourite } = callback.params;
    params = Object.assign({}, { id, favourite_id: id });
    const plainText = JSON.stringify(params);

    // Encrypt model request with AES using RequestKey
    params = EncryptAES(plainText, getRequestKey());

    if (is_favourite) {
      service.serviceRemoveFavorite(params).then((response: AxiosResponse) => {
        if (response.status == 200) {
          if (typeof response.data === "string") {
            // Decrypt model response with AES using ResponseKey
            const resJSON = DecryptAES(response.data, getResponseKey());
            const mResponse = new ResponseModel();

            mResponse.setObject(resJSON);
            if (mResponse.token) {
              RefreshKeyFromToken(mResponse.token);
            }

            if (mResponse.status) {
              toast.success("Seeker has been removed from favorite list!");
              const indexFav = state.favorite_list.findIndex((item) => {
                return item.id === id;
              });
              if (indexFav !== -1) {
                state.favorite_list.splice(indexFav, 1);
              }
              if (callback && callback.success) {
                callback.success(mResponse);
              }
            }
          } else {
            const { message } = response.data;
            toast.error(message);
            if (TOKEN_INVALID.includes(message)) {
              deleteToken();
              router.push({ name: "signin" }, () => {
                location.reload();
              });
            }
          }
        }
      });
    } else {
      service.serviceAddFavorite(params).then((response: AxiosResponse) => {
        if (response.status == 200) {
          if (typeof response.data === "string") {
            // Decrypt model response with AES using ResponseKey
            const resJSON = DecryptAES(response.data, getResponseKey());
            const mResponse = new ResponseModel();

            mResponse.setObject(resJSON);
            if (mResponse.token) {
              RefreshKeyFromToken(mResponse.token);
            }

            if (mResponse.status) {
              toast.success("Seeker has been added to favorite list!");
              if (callback && callback.success) {
                callback.success(mResponse);
              }
            }
          } else {
            const { message } = response.data;
            toast.error(message);
            if (TOKEN_INVALID.includes(message)) {
              deleteToken();
              router.push({ name: "signin" }, () => {
                location.reload();
              });
            }
          }
        }
      });
    }
  },
  actionReportSeeker({ state }, params: any) {
    return new Promise((resolve, reject) => {
      const plainText = JSON.stringify(params);

      // Encrypt model request with AES using RequestKey
      params = EncryptAES(plainText, getRequestKey());

      service.serviceReportSeeker(params).then((response: AxiosResponse) => {
        if (response.status === 200) {
          if (typeof response.data === "string") {
            // Decrypt model response with AES using ResponseKey
            const resJSON = DecryptAES(response.data, getResponseKey());
            const mResponse = new ResponseModel();

            mResponse.setObject(resJSON);
            if (mResponse.token) {
              RefreshKeyFromToken(mResponse.token);
            }

            console.log(mResponse);

            resolve(mResponse)
          } else {
            const { message } = response.data;
            toast.error(message);
            reject(message)
            if (TOKEN_INVALID.includes(message)) {
              deleteToken();
              router.push({ name: "signin" }, () => {
                location.reload();
              });
            }
          }
        }
      });
    })

  },
  actionBlockSeeker({ state }, params: any) {
    return new Promise((resolve, reject) => {
      const plainText = JSON.stringify(params);

      // Encrypt model request with AES using RequestKey
      params = EncryptAES(plainText, getRequestKey());

      service.serviceBlockSeeker(params).then((response: AxiosResponse) => {
        if (response.status === 200) {
          if (typeof response.data === "string") {
            // Decrypt model response with AES using ResponseKey
            const resJSON = DecryptAES(response.data, getResponseKey());
            const mResponse = new ResponseModel();

            mResponse.setObject(resJSON);
            if (mResponse.token) {
              RefreshKeyFromToken(mResponse.token);
            }

            console.log(mResponse);
            // const { data, vip } = mResponse.results;


            resolve(mResponse);
          } else {
            const { message } = response.data;
            toast.error(message);
            reject(message)
            if (TOKEN_INVALID.includes(message)) {
              deleteToken();
              router.push({ name: "signin" }, () => {
                location.reload();
              });
            }
          }
        }
      });
    })
  },
};
