import { AxiosResponse } from "axios";
import { Base, BaseAuth } from "./base/base.service";
import { getAuthKey, getToken } from "@/functions/token.function";

export default class UserService {
  public serviceCheckUser(): Promise<AxiosResponse<any>> {
    return BaseAuth().get("user", {
      headers: {
        "Content-Type": "text/plain",
        Authorization: `Bearer ${getToken()}`,
      },
    });
  }
  public serviceCheckEmail(params: any): Promise<AxiosResponse<any>> {
    return BaseAuth().post("register/send-otp", params, {
      headers: {
        "Content-Type": "text/plain",
        Authorization: getAuthKey(),
      },
    });
  }
  public serviceLogin(params: any): Promise<AxiosResponse<any>> {
    return BaseAuth().post("login", params, {
      headers: {
        "Content-Type": "text/plain",
        Authorization: `Bearer ${getToken()}`,
      },
    });
  }

  public serviceAuthGoogle(params: any): Promise<AxiosResponse<any>> {
    return BaseAuth().post("login/google", params, {
      headers: {
        "Content-Type": "text/plain",
        Authorization: getAuthKey(),
      },
    });
  }
  public serviceAuthFacebook(params: any): Promise<AxiosResponse<any>> {
    return BaseAuth().post("login/facebook", params, {
      headers: {
        "Content-Type": "text/plain",
        Authorization: getAuthKey(),
      },
    });
  }
  public serviceAuthApple(params: any): Promise<AxiosResponse<any>> {
    return BaseAuth().post("login/apple", params, {
      headers: {
        "Content-Type": "text/plain",
        Authorization: getAuthKey(),
      },
    });
  }

  public serviceLogout(params: any): Promise<AxiosResponse<any>> {
    return BaseAuth().post("logout", params, {
      headers: {
        "Content-Type": "text/plain",
        Authorization: `Bearer ${getToken()}`,
      },
    });
  }
  public serviceVerifyOTP(params: any): Promise<AxiosResponse<any>> {
    return BaseAuth().post("register/verify-otp", params, {
      headers: {
        "Content-Type": "text/plain",
        Authorization: `Bearer ${getToken()}`,
      },
    });
  }
  public serviceCreatePassword(params: any): Promise<AxiosResponse<any>> {
    return BaseAuth().post("register/create-password", params, {
      headers: {
        "Content-Type": "text/plain",
        Authorization: `Bearer ${getToken()}`,
      },
    });
  }
  public serviceCreateProfile(params: any): Promise<AxiosResponse<any>> {
    return BaseAuth().post("register/personal-info", params, {
      headers: {
        "Content-Type": "text/plain",
        Authorization: `Bearer ${getToken()}`,
      },
    });
  }
  public serviceUploadPhoto(params: any): Promise<AxiosResponse<any>> {
    return BaseAuth().post("register/personal-photo", params, {
      headers: {
        "Content-Type": "text/plain",
        Authorization: `Bearer ${getToken()}`,
      },
    });
  }
  public serviceListPhoto(): Promise<AxiosResponse<any>> {
    return Base().get("user/photos", {
      headers: {
        "Content-Type": "text/plain",
        Authorization: `Bearer ${getToken()}`,
      },
    });
  }
  public serviceSetMainPhoto(params: any): Promise<AxiosResponse<any>> {
    return Base().post("user/photos/set-main", params, {
      headers: {
        "Content-Type": "text/plain",
        Authorization: `Bearer ${getToken()}`,
      },
    });
  }
  public serviceUpdateProfile(params: any): Promise<AxiosResponse<any>> {
    return Base().post("user/personal-info", params, {
      headers: {
        "Content-Type": "text/plain",
        Authorization: `Bearer ${getToken()}`,
      },
    });
  }
  public serviceUpdateProfilePhoto(
    data: FormData
  ): Promise<AxiosResponse<any>> {
    return Base().post("user/photos/edit", data, {
      headers: {
        "Content-Type": "text/plain",
        Authorization: `Bearer ${getToken()}`,
      },
    });
  }

  public serviceForgotPassword(params: any): Promise<AxiosResponse<any>> {
    return Base().post("auth/forgot-password", params, {
      headers: {
        "Content-Type": "text/plain",
        Authorization: `Bearer ${getToken()}`,
      },
    });
  }
  public serviceResetPassword(params: any): Promise<AxiosResponse<any>> {
    return Base().post("auth/reset-password", params, {
      headers: {
        "Content-Type": "text/plain",
        Authorization: `Bearer ${getToken()}`,
      },
    });
  }

  public serviceChangePassword(params: any): Promise<AxiosResponse<any>> {
    return Base().post("user/change-password", params, {
      headers: {
        "Content-Type": "text/plain",
        Authorization: `Bearer ${getToken()}`,
      },
    });
  }

  public serviceSelectMembership(): Promise<AxiosResponse<any>> {
    return Base().get("user/membership", {
      headers: {
        "Content-Type": "text/plain",
        Authorization: `Bearer ${getToken()}`,
      },
    });
  }
  public serviceSetMembership(params: any): Promise<AxiosResponse<any>> {
    return Base().post("user/membership", params, {
      headers: {
        "Content-Type": "text/plain",
        Authorization: `Bearer ${getToken()}`,
      },
    });
  }

  public serviceDeleteAccount(params: any): Promise<AxiosResponse<any>> {
    return Base().post("user/delete-account", params, {
      headers: {
        "Content-Type": "text/plain",
        Authorization: `Bearer ${getToken()}`,
      },
    });
  }
  public serviceDeleteConfirm(params: any): Promise<AxiosResponse<any>> {
    return Base().post("auth/confirm-password", params, {
      headers: {
        "Content-Type": "text/plain",
        Authorization: `Bearer ${getToken()}`,
      },
    });
  }
}
