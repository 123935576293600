import router from "@/router";
import toast from "@/functions/toast.function";

import { ActionTree } from "vuex";
import { RootState } from "../types";
import { AxiosResponse } from "axios";
import { PaymentState } from "./types";

import {
  deleteToken,
  getRequestKey,
  getResponseKey,
} from "@/functions/token.function";
import {
  DecryptAES,
  EncryptAES,
  RefreshKeyFromToken,
} from "@/functions/crypto.function";

import { ResponseModel } from "@/models/response/response.model";
import { MembershipModel } from "@/models/payment/payment.model";

import PaymentService from "@/services/payment.service";
import { createPagination } from "@/functions/global.function";
import { TOKEN_INVALID } from "@/constants/enum.constant";

const service: PaymentService = new PaymentService();

export const actions: ActionTree<PaymentState, RootState> = {
  actionListMembership({ state }, callback: any) {
    service.serviceListMembership().then((response: AxiosResponse) => {
      if (response.status === 200) {
        if (typeof response.data === "string") {
          // Decrypt model response with AES using ResponseKey
          const resJSON = DecryptAES(response.data, getResponseKey());
          const mResponse = new ResponseModel();

          mResponse.setObject(resJSON);
          if (mResponse.token) {
            RefreshKeyFromToken(mResponse.token);
          }

          if (mResponse.status) {
            state.array_membership = new Array<MembershipModel>();

            if (mResponse.results) {
              mResponse.results.forEach((item: MembershipModel) => {
                state.array_membership.push(item);
              });
            }

            if (callback && callback.success) {
              callback.success(mResponse);
            }
          }
        } else {
          const { message } = response.data;
          toast.error(message);
          if (TOKEN_INVALID.includes(message)) {
            deleteToken();
            router.push({ name: "signin" }, () => {
              location.reload();
            });
          }
        }
      } else {
        if (callback && callback.fail) {
          callback.fail();
        }
      }
    });
  },
  actionMembershipHistory({ state }, callback: any) {
    let params: any = null;

    if (callback && callback.params) {
      const filter = Object.assign({}, callback.params);

      const plainText = JSON.stringify(filter);

      // Encrypt model request with AES using RequestKey
      params = EncryptAES(plainText, getRequestKey());
    }

    service.serviceListHistory(params).then((response: AxiosResponse) => {
      if (response.status === 200) {
        if (typeof response.data === "string") {
          // Decrypt model response with AES using ResponseKey
          const resJSON = DecryptAES(response.data, getResponseKey());
          const mResponse = new ResponseModel();

          mResponse.setObject(resJSON);
          if (mResponse.token) {
            RefreshKeyFromToken(mResponse.token);
          }

          if (mResponse.status) {
            state.array_membership = new Array<MembershipModel>();

            const { data } = mResponse.results;
            state.array_history = data;
            state.pagination_history = createPagination(mResponse.results);

            if (callback && callback.success) {
              callback.success(mResponse);
            }
          }
        } else {
          const { message } = response.data;
          toast.error(message);
          if (TOKEN_INVALID.includes(message)) {
            deleteToken();
            router.push({ name: "signin" }, () => {
              location.reload();
            });
          }
        }
      } else {
        if (callback && callback.fail) {
          callback.fail();
        }
      }
    });
  },
  actionSetPaymentMethod({ state }, callback: any) {
    if (callback && callback.params) {
      state.payment_method.setObject(callback.params);
    }
  },
  actionCreatePaymentIntent({ state }, callback: any) {
    let params: any = null;

    if (callback && callback.params) {
      const plainText = JSON.stringify(callback.params);
      // Encrypt model request with AES using RequestKey
      params = EncryptAES(plainText, getRequestKey());
    }

    service
      .serviceCreatePaymentIntent(params)
      .then((response: AxiosResponse) => {
        if (response.status === 200) {
          if (typeof response.data === "string") {
            // Decrypt model response with AES using ResponseKey
            const resJSON = DecryptAES(response.data, getResponseKey());
            const mResponse = new ResponseModel();

            mResponse.setObject(resJSON);
            if (mResponse.token) {
              RefreshKeyFromToken(mResponse.token);
            }

            if (mResponse.status) {
              state.payment_intent.setObject(mResponse.results);
              if (callback && callback.success) {
                callback.success(mResponse);
              }
            }
          } else {
            const { message } = response.data;
            toast.error(message);
            if (TOKEN_INVALID.includes(message)) {
              deleteToken();
              router.push({ name: "signin" }, () => {
                location.reload();
              });
            }
          }
        } else {
          if (callback && callback.fail) {
            callback.fail();
          }
        }
      });
  },
  actionConfirmPaymentIntent({ state }, callback: any) {
    let params: any = null;

    if (callback && callback.params) {
      const plainText = JSON.stringify(callback.params);
      // Encrypt model request with AES using RequestKey
      params = EncryptAES(plainText, getRequestKey());
    }

    service
      .serviceConfirmPaymentIntent(params)
      .then((response: AxiosResponse) => {
        if (response.status === 200) {
          if (typeof response.data === "string") {
            // Decrypt model response with AES using ResponseKey
            const resJSON = DecryptAES(response.data, getResponseKey());
            const mResponse = new ResponseModel();

            mResponse.setObject(resJSON);
            if (mResponse.token) {
              RefreshKeyFromToken(mResponse.token);
            }

            if (mResponse.status) {
              state.payment_intent.setObject(mResponse.results);
              if (callback && callback.success) {
                callback.success(mResponse);
              }
            }
          } else {
            const { message } = response.data;
            toast.error(message);
            if (TOKEN_INVALID.includes(message)) {
              deleteToken();
              router.push({ name: "signin" }, () => {
                location.reload();
              });
            }
          }
        } else {
          if (callback && callback.fail) {
            callback.fail();
          }
        }
      });
  },

  actionPaymentTazapey({ state }, callback: any) {
    let params: any = null;

    if (callback && callback.params) {
      const plainText = JSON.stringify(callback.params);
      // Encrypt model request with AES using RequestKey
      params = EncryptAES(plainText, getRequestKey());
    }

    service.servicePaymentTazapey(params).then((response: AxiosResponse) => {
      if (response && typeof response.data === "string") {
        // Decrypt model response with AES using ResponseKey
        const resJSON = DecryptAES(response.data, getResponseKey());
        const mResponse = new ResponseModel();

        mResponse.setObject(resJSON);
        if (mResponse.token) {
          RefreshKeyFromToken(mResponse.token);
        }

        console.log("RESPONSE NEW PAYMENT", mResponse);
        console.log(state.payment_tazapey);
        if (mResponse.status) {
          if (callback && callback.success) {
            callback.success(mResponse);
          }
        }
      } else {
        const { message, redirect_url } = response.data;

        if (redirect_url) {
          state.payment_tazapey = redirect_url;
          location.href = redirect_url;
        } else if (message) {
          toast.error(message);
          if (TOKEN_INVALID.includes(message)) {
            deleteToken();
            router.push({ name: "signin" }, () => {
              location.reload();
            });
          }
        }
      }
    });
  },
};
