












































import Vue from "vue";
import Component from "vue-class-component";
import { Action, State } from "vuex-class";

import { ASSETS } from "@/services/base/apibase";
import { UserModel } from "@/models/user/user.model";
import { ChannelModel } from "@/models/chat/chat.model";
import { SeekerModel } from "@/models/seeker/seeker.model";

@Component
export default class ChatList extends Vue {
  /* VUEX */

  /* ACTIONS */
  @Action("actionSelectChannel", { namespace: "chat" })
  private actionSelectChannel: any;

  /* STATE */
  @State("user_profile", { namespace: "user" })
  private user_profile!: UserModel;
  @State("array_channel", { namespace: "chat" })
  private array_channel!: Array<ChannelModel>;

  /* INITIALIZATION */

  /* METHODS */
  private onClickChannel(selChannel: ChannelModel) {
    this.actionSelectChannel({
      params: selChannel,
      sender_from: this.user_profile,
      success: () => {
        this.$bvModal.show("usermessage");
      },
    });
  }

  private onDetail(seeker: SeekerModel) {
    this.$router.push({
      name: "detail-seeker",
      params: { id: seeker.id.toString() },
    });
  }

  private isUser(userId: number) {
    return this.user_profile.id === userId;
  }

  private userPhoto(filename: string, useThumb = false) {
    if (filename) {
      if (useThumb) {
        return ASSETS + "user/photo/thumb/" + filename;
      }
      return ASSETS + "user/photo/" + filename;
    }
    return ASSETS + "user/photo/default.jpg";
  }
}
