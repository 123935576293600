import { Request } from "./request.type";
import { BaseModel } from "../base/base.model";
import { APPLICATION_VERSION } from "@/constants/global.constant";

export class RequestModel extends BaseModel implements Request {
  public ApplicationVersion = APPLICATION_VERSION;
  public DeviceID = "";
  public IPAddresss = "";
  public Culture = "id-ID";
  public Data = "";
}
