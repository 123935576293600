




















































































import Vue from "vue";
import Component from "vue-class-component";
import { Action, State } from "vuex-class";

import { LocationModel } from "@/models/region/region.model";
import toast from "@/functions/toast.function";
import i18n from "@/i18n";
import { getToken } from "@/functions/token.function";

@Component
export default class Banner extends Vue {
  /* VUEX */

  /* ACTIONS */
  @Action("actionGetLocation", { namespace: "region" })
  private actionGetLocation: any;
  @Action("actionGetLocationDummy", { namespace: "region" })
  private actionGetLocationDummy: any;

  /* STATE */
  @State("array_location", { namespace: "region" })
  private array_location!: Array<LocationModel>;

  /* INITIALIZATION */
  private gender = "";
  private birthday = null;
  private location = null;

  private created() {
    localStorage.removeItem("user_gender");
    localStorage.removeItem("user_birthday");
    localStorage.removeItem("user_location");
    if (getToken()) {
      this.actionGetLocation();
    } else {
      this.actionGetLocationDummy();
    }
  }

  /* METHODS */
  private clickJoin() {
    if (!this.gender) {
      toast.warning(i18n.t("warning_choose_gender"));
      return;
    }
    // if (!this.birthday) {
    //   toast.warning(i18n.t("warning_choose_birthday"));
    //   return;
    // }
    if (!this.location) {
      toast.warning(i18n.t("warning_choose_location"));
      return;
    }

    localStorage.setItem("user_gender", this.gender);
    // localStorage.setItem("user_birthday", this.birthday as any);
    localStorage.setItem("user_location", JSON.stringify(this.location));

    this.$router.push({ name: "signup" });
  }

  private changeGender(gender: string) {
    this.gender = gender;
  }

  private get isOver18() {
    const date18YrsAgo = new Date();
    date18YrsAgo.setFullYear(date18YrsAgo.getFullYear() - 18);

    let dateYear: any = date18YrsAgo.getFullYear();
    let dateMonth: any = date18YrsAgo.getMonth() + 1;
    let dateDay: any = date18YrsAgo.getDate();

    if (dateMonth < 10) {
      dateMonth = "0" + dateMonth.toString();
    }
    if (dateDay < 10) {
      dateDay = "0" + dateDay.toString();
    }
    const maxDate = dateYear + "-" + dateMonth + "-" + dateDay;

    return maxDate;
  }
}
