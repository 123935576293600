






























































































































































































import Vue from "vue";
import Component from "vue-class-component";
import { State } from "vuex-class";

import { UserModel } from "@/models/user/user.model";
import { Watch } from "vue-property-decorator";

@Component
export default class Additional extends Vue {
  /* VUEX */

  /* ACTIONS */

  /* STATE */
  @State("user_profile", { namespace: "user" })
  private user_profile!: UserModel;
  @State("array_ethnic", { namespace: "misc" })
  private array_ethnic!: Array<any>;
  @State("array_eye_color", { namespace: "misc" })
  private array_eye_color!: Array<any>;
  @State("array_hair_color", { namespace: "misc" })
  private array_hair_color!: Array<any>;
  @State("array_language", { namespace: "misc" })
  private array_language!: Array<any>;
  @State("array_wear_glasses", { namespace: "misc" })
  private array_wear_glasses!: Array<any>;

  @Watch("user_profile", { deep: true, immediate: true })
  private onChangedUser(val: UserModel) {
    const { feet_height } = val;

    if (this.isUnitedStates && feet_height && !this.isLoadFeet) {
      // const splitedHeight = feet_height.replace(/[^0-9.$]/g, "").split("");
      const splitedHeight = feet_height.split(" ");
      const feetHeightInt = splitedHeight[0].replace("ft", "");
      const inchHeightInt = splitedHeight[1].replace("inch", "");
      this.isLoadFeet = true;

      console.log(`Data ${splitedHeight}`);
      this.user_profile.feet = feetHeightInt !== "0" ? feetHeightInt : "";
      this.user_profile.inches = inchHeightInt;
    }
  }

  /* INITIALIZATION */
  private isLoadFeet = false;

  private get isUnitedStates() {
    if (this.user_profile.location === "United States") {
      return true;
    }
    return false;
  }

  /* METHODS */
  private getFocus(id: string) {
    document.getElementById(id)?.focus();
  }

  private onSelectWearGlasses(val: any) {
    if (val) {
      this.user_profile.wear_glasses = val.name;
    }
  }

  private onInputIncome(event: any) {
    const { value } = event.target;

    if (value.split("").includes("$")) {
      this.user_profile.income = value;
    } else {
      this.user_profile.income = "$" + value;
    }
  }

  private onInputSalary(event: any) {
    const { value } = event.target;

    if (value.split("").includes("$")) {
      this.user_profile.salary = value;
    } else {
      this.user_profile.salary = "$" + value;
    }
  }
}
