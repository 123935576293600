import { Module } from "vuex";
import { RootState } from "../types";

import { actions } from "./actions";
import { mutations } from "./mutations";
import { getters } from "./getters";
import { UserState } from "./types";

import {
  UserModel,
  UserPasswordModel,
  UserPhotoModel,
} from "../../models/user/user.model";
import { QuestionModel } from "@/models/question/question.model";

export const state: UserState = {
  user_auth: new UserModel(),
  user_profile: new UserModel(),
  user_password: new UserPasswordModel(),
  user_photo: new Array<UserPhotoModel>(),
  user_detail_photo: new UserPhotoModel(),
  user_questions: new Array<QuestionModel>(),
};

const namespaced = true;

export const user: Module<UserState, RootState> = {
  namespaced,
  state,
  actions,
  getters,
  mutations,
};
