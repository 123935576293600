import axios, { AxiosInstance } from "axios";

import { BASE_API } from "./apibase";

/* BaseService - START */
// Description : BaseService is base of all class service on here you can initialize interceptor request,
// response and error handling.
// Author : Risman Abdilah.
// Created on : Mon, 25 Oct 2021.      Updated on : Mon, 25 Oct 2021.
// Created by : Risman Abdilah.        Updated by : Risman Abdilah.
/* Version : 1.0.0 */

// ADMIN
const tokenInstance = axios.CancelToken;
const tokenSource = tokenInstance.source();
const instance: AxiosInstance = axios.create({
  baseURL: BASE_API,
  cancelToken: tokenSource.token,
});
instance.defaults.timeout = 20000;

const tokenInstanceAuth = axios.CancelToken;
const tokenSourceAuth = tokenInstanceAuth.source();
const instanceAuth: AxiosInstance = axios.create({
  baseURL: BASE_API + "auth/",
  cancelToken: tokenSourceAuth.token,
});
instanceAuth.defaults.timeout = 20000;

export function Base(): AxiosInstance {
  return instance;
}

export function BaseAuth(): AxiosInstance {
  return instanceAuth;
}

/* BaseService - END */
