import { LocationType } from "./region.type";
import { BaseModel } from "../base/base.model";

export class LocationModel extends BaseModel implements LocationType {
  public id = 0;
  public name = "";
  public locale = "";
  public timezone = "";
  public location_id = "";
}
