import { Module } from "vuex";
import { RootState } from "../types";

import { actions } from "./actions";
import { ChatState } from "./types";

import { UserModel } from "@/models/user/user.model";
import { ChannelModel, MessageModel } from "@/models/chat/chat.model";

export const state: ChatState = {
  channel: new ChannelModel(),
  array_channel: new Array<ChannelModel>(),
  array_channel_cs: new Array<ChannelModel>(),
  message: new MessageModel(),
  array_message: new Array<MessageModel>(),
  free_chat: new UserModel(),
  validation: null,
};

const namespaced = true;

export const chat: Module<ChatState, RootState> = {
  namespaced,
  state,
  actions,
};
