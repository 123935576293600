import { Response } from "./response.type";
import { BaseModel } from "../base/base.model";

export class ResponseModel extends BaseModel implements Response {
  public status = false;
  public results: any = null;
  public message = "";
  public validation: any = null;
  public token = "";
  public question?: any = null;
}
