








































































































import Vue from "vue";
import $ from "jquery";
import filters from "@/filters";
import Component from "vue-class-component";
import { Action, State } from "vuex-class";

import { UserModel } from "@/models/user/user.model";
import { SeekerModel } from "@/models/seeker/seeker.model";

import SidebarLeft from "@/components/dashboard/SidebarLeft.vue";
import SidebarRight from "@/components/dashboard/SidebarRight.vue";
import SeekerList from "@/components/dashboard/main/SeekerList.vue";
import ChatList from "@/components/dashboard/main/ChatList.vue";
import ProfileDetail from "@/components/dashboard/main/ProfileDetail.vue";
import ModalMessageComponent from "@/components/modal/Message.vue";
import ModalNotificationComponent from "@/components/modal/Notification.vue";
import ModalFilterSeekerComponent from "@/components/modal/FilterSeeker.vue";
import { Watch } from "vue-property-decorator";

@Component({
  filters,
  components: {
    SidebarLeft,
    SidebarRight,
    SeekerList,
    ChatList,
    ProfileDetail,
    ModalMessageComponent,
    ModalNotificationComponent,
    ModalFilterSeekerComponent,
  },
})
export default class Dashboard extends Vue {
  /* VUEX */

  /* ACTION */
  @Action("appLayout")
  private appLayout: any;
  @Action("actionGetListPhoto", { namespace: "user" })
  private actionGetListPhoto: any;
  @Action("actionExploreSeeker", { namespace: "seeker" })
  private actionExploreSeeker: any;
  @Action("actionFavoriteSeeker", { namespace: "seeker" })
  private actionFavoriteSeeker: any;
  @Action("actionSelectNotification", { namespace: "notification" })
  private actionSelectNotification: any;
  @Action("actionListChannel", { namespace: "chat" })
  private actionListChannel: any;
  @Action("actionGetLocation", { namespace: "region" })
  private actionGetLocation: any;

  /* STATE */
  @State("user_profile", { namespace: "user" })
  private user_profile!: UserModel;
  @State("array_explore", { namespace: "seeker" })
  private array_explore!: Array<SeekerModel>;

  @Watch("$route.params", { immediate: true, deep: true }) onChangeRoute() {
    if((this.$route.query?.chat||null) === "1") this.changeTabContent(2)
  }

  /* INITIALIZATION */
  private tabContent = 1;

  private created() {
    this.appLayout();
  }

  private mounted() {
    this.scrollToTop();
    if (this.array_explore.length < 1 && this.user_profile.id) {
      this.getExplore();
    }
  }

  /* METHODS */
  private getExplore() {
    this.actionExploreSeeker({
      success: () => {
        this.actionSelectNotification();
        this.actionFavoriteSeeker({
          success: () => {
            // Todo
          },
        });
      },
    });
  }

  private onShowModalFilter() {
    this.actionGetLocation();
  }

  private changeTabContent(tab: number) {
    this.tabContent = tab;
    if (tab === 1) {
      this.getExplore();
    } else if (tab === 2) {
      this.actionListChannel({
        params: this.user_profile.id,
      });
    } else {
      this.actionGetListPhoto();
    }
  }

  private scrollToTop(target?:string) {
    $("html,body").animate({ scrollTop: target?$(target).offset()?.top:0 }, 800);
  }
}
