

















































import Vue from "vue";
import Component from "vue-class-component";
import { Action, State } from "vuex-class";

import { QuestionModel } from "@/models/question/question.model";

@Component
export default class SelectQuestion extends Vue {
  /* VUEX */

  /* ACTION */
  @Action("actionSelectQuestionAnswer", { namespace: "question" })
  private actionSelectQuestionAnswer: any;

  /* STATE */
  @State("user_questions", { namespace: "user" })
  private user_questions!: Array<QuestionModel>;
  @State("array_question", { namespace: "question" })
  private array_question!: Array<QuestionModel>;

  /* INITIALIZATION */

  private get array_question_unanswered() {
    const unanswered = this.array_question.filter((question) => {
      const isExist = this.user_questions.findIndex(
        (questionUser) => questionUser.id === question.id
      );

      if (isExist < 0) {
        return question;
      }
    });

    return unanswered;
  }

  /* METHODS */
  private onShowSelectAnswer(question: QuestionModel) {
    this.actionSelectQuestionAnswer(question);
    this.$bvModal.show("modal-answer-question");
  }
}
