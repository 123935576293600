import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import { getToken } from "@/functions/token.function";

import Landing from "@/views/Landing.vue";
import Signin from "@/views/Signin.vue";
import Signup from "@/views/Signup.vue";
import ForgotPassword from "@/views/ForgotPassword.vue";
import PageNotFound from "@/views/PageNotFound.vue";
import Dashboard from "@/views/pages/Dashboard.vue";
import Chat from "@/views/pages/Chat.vue";
import DetailSeeker from "@/views/pages/DetailSeeker.vue";
import Profile from "@/views/pages/Profile.vue";
import Information from "@/components/profile/Information.vue";
import ChangePassword from "@/components/profile/ChangePassword.vue";
import CloseAccount from "@/components/profile/CloseAccount.vue";
import Membership from "@/components/profile/Membership.vue";
import PurchaseHistory from "@/components/profile/PurchaseHistory.vue";
import FAQ from "@/views/pages/FAQ.vue";
import Join from "@/views/Join.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "landing",
    component: Landing,
  },
  {
    path: "/join",
    name: "join",
    component: Join,
  },
  {
    path: "/signin",
    name: "signin",
    component: Signin,
  },
  {
    path: "/signup",
    name: "signup",
    component: Signup,
  },
  {
    path: "/forgot-password",
    name: "forgot_password",
    component: ForgotPassword,
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: Dashboard,
    meta: { requiresAuth: true },
  },
  {
    path: "/chat",
    name: "chat",
    component: Chat,
    meta: { requiresAuth: true },
  },
  {
    path: "/detail-seeker/:id",
    name: "detail-seeker",
    component: DetailSeeker,
    meta: { requiresAuth: true },
  },
  {
    path: "",
    component: Profile,
    meta: { requiresAuth: true },
    children: [
      {
        path: "/profile",
        name: "profile",
        component: Information,
      },
      {
        path: "/profile/change-password",
        name: "change-password",
        component: ChangePassword,
      },
      {
        path: "/profile/close-account",
        name: "close-account",
        component: CloseAccount,
      },
      {
        path: "/profile/purchase",
        name: "membership",
        component: Membership,
      },
      {
        path: "/profile/purchase-history",
        name: "purchase-history",
        component: PurchaseHistory,
      },
    ],
  },
  {
    path: "/faq",
    name: "faq",
    component: FAQ,
    // meta: { requiresAuth: true },
  },
  {
    path: "*",
    component: PageNotFound,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (!getToken()) {
      if (from.name !== "signin") {
        next("/signin");
      }
    } else {
      next();
    }
  } else {
    // make sure to always call next()!
    next();
  }
});

export default router;
