

















































import Vue from "vue";
import $ from "jquery";
import Component from "vue-class-component";
import { Action, State } from "vuex-class";

import { UserModel, UserPhotoModel } from "@/models/user/user.model";
import { data2blob } from "@/functions/global.function";

@Component
export default class UploadPhoto extends Vue {
  /* VUEX */

  /* ACTIONS */
  @Action("actionResetPhoto", { namespace: "user" })
  private actionResetPhoto: any;
  @Action("actionResetMainPhoto", { namespace: "user" })
  private actionResetMainPhoto: any;

  /* STATE */
  @State("user_auth", { namespace: "user" })
  private user_auth!: UserModel;
  @State("user_photo", { namespace: "user" })
  private user_photo!: Array<UserPhotoModel>;

  /* INITIALIZATION */
  private showUploadImage = false;

  private mounted() {
    this.actionResetPhoto();
  }

  /* METHODS */
  private onSkip() {
    this.$emit("on-skip");
  }

  private onVerify() {
    this.$emit("on-success");
  }

  private onSetMain(index: any) {
    this.user_photo.forEach((item) => {
      item.is_main = false;
    });
    if (this.user_photo[index]) {
      this.user_photo[index].is_main = true;
    }
  }

  private onRemove(index: any) {
    this.actionResetMainPhoto(index);
    $("#file-upload-image").val("");
  }

  private clickUpload() {
    this.showUploadImage = true;
  }

  private onChangeImage(imageDataUrl: any) {
    const elImageCropUpload = this.$refs.imageCropUpload as any;
    const blobFile = data2blob(imageDataUrl, elImageCropUpload.mime as string);
    const filename = "seeker-" + Date.now().toString();
    const new_image: any = new File(
      [blobFile],
      filename + "." + elImageCropUpload.imgFormat
    );

    // Add to state
    const model_photo = new UserPhotoModel();
    model_photo.image = new_image;
    model_photo.path = URL.createObjectURL(new_image);

    // Set is_main: true if first image
    if (this.user_photo.length < 1) {
      model_photo.is_main = true;
    }
    this.user_photo.push(model_photo);

    this.showUploadImage = false;
    elImageCropUpload.setStep(1);
  }
}
