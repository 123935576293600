





















































































































































































import Vue from "vue";
import $ from "jquery";
import Component from "vue-class-component";
import { Action, State } from "vuex-class";

import { QuestionType } from "@/constants/enum.constant";
import {
  QuestionChoiceModel,
  QuestionModel,
} from "@/models/question/question.model";
import toast from "@/functions/toast.function";

@Component
export default class AnswerQuestion extends Vue {
  /* VUEX */

  /* ACTION */
  @Action("actionSaveAnswer", { namespace: "question" })
  private actionSaveAnswer: any;
  @Action("actionGetListPhoto", { namespace: "user" })
  private actionGetListPhoto: any;

  /* STATE */
  @State("answer_question", { namespace: "question" })
  private answer_question!: QuestionModel;

  /* INITIALIZATION */
  private questionType = QuestionType;
  private array_single_answer = new Array<any>();
  private array_single_write_answer = new Array<any>();
  private array_multiple_answer = new Array<any>();

  /* METHODS */
  private setSingleAnswer(choice: QuestionChoiceModel) {
    const isExist = this.array_single_answer.filter((el) => {
      return el.question_id == choice.question_id;
    });

    if (isExist.length == 0) {
      this.array_single_answer.push(choice);
    } else {
      const indexChoice = this.array_single_answer.findIndex((el) => {
        return el.question_id == choice.question_id;
      });
      this.array_single_answer.splice(indexChoice, 1, choice);
    }
  }

  private onSaveSingleAnswer() {
    this.actionSaveAnswer({
      params: {
        question: this.answer_question,
        answer: this.array_single_answer,
      },
      success: () => {
        this.callbackSuccess();
      },
    });
  }

  private setSingleWriteAnswer(
    choice: QuestionChoiceModel | null,
    inputId: string
  ) {
    if (!choice) {
      const elementInput: any = document.getElementById(inputId);
      elementInput.disabled = false;
    } else {
      const elementInput: any = document.getElementById(inputId);
      elementInput.disabled = true;

      const isExist = this.array_single_write_answer.filter((el) => {
        return el.question_id == choice.question_id;
      });

      if (isExist.length == 0) {
        this.array_single_write_answer.push(choice);
      } else {
        const indexChoice = this.array_single_write_answer.findIndex((el) => {
          return el.question_id == choice.question_id;
        });
        this.array_single_write_answer.splice(indexChoice, 1, choice);
      }
    }
  }

  private onSaveSingleWriteAnswer(inputId: string) {
    const elementInput = $(`#${inputId}`);

    if (!elementInput.prop("disabled")) {
      const choice = elementInput.val();

      if (!choice) {
        toast.warning("Please insert text answer!");
        return;
      }

      this.actionSaveAnswer({
        params: {
          question: this.answer_question,
          answer: [choice],
          is_write: true,
        },
        success: () => {
          this.callbackSuccess();
        },
      });
    } else {
      this.actionSaveAnswer({
        params: {
          question: this.answer_question,
          answer: this.array_single_write_answer,
        },
        success: () => {
          this.callbackSuccess();
        },
      });
    }
  }

  private setMultipleAnswer(choice: QuestionChoiceModel) {
    const isExist = this.array_multiple_answer.filter((el) => {
      return el.id == choice.id;
    });

    if (isExist.length == 0) {
      this.array_multiple_answer.push(choice);
    } else {
      const indexChoice = this.array_multiple_answer.findIndex((el) => {
        return el.id == choice.id;
      });
      this.array_multiple_answer.splice(indexChoice, 1);
    }
  }

  private onSaveMultipleAnswer() {
    this.actionSaveAnswer({
      params: {
        question: this.answer_question,
        answer: this.array_multiple_answer,
      },
      success: () => {
        this.callbackSuccess();
      },
    });
  }

  private callbackSuccess() {
    this.array_single_answer = new Array<any>();
    this.array_single_write_answer = new Array<any>();
    this.array_multiple_answer = new Array<any>();
    this.$bvModal.hide("modal-answer-question");
    this.$bvModal.hide("modal-select-question");
    this.actionGetListPhoto();
  }
}
