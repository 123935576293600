















































































import Vue from "vue";
import Component from "vue-class-component";
import { Action, State } from "vuex-class";
import { Prop, Watch } from "vue-property-decorator";

import { loadStripe } from "@stripe/stripe-js";
import { PaymentStatus } from "@/constants/enum.constant";

import { UserModel } from "@/models/user/user.model";
import {
  MembershipModel,
  PaymentIntentModel,
  PaymentMethodBillingModel,
  PaymentMethodModel,
} from "@/models/payment/payment.model";

import toast from "@/functions/toast.function";

@Component
export default class StripePayment extends Vue {
  /* VUEX */

  /* ACTIONS */
  @Action("actionSetPaymentMethod", { namespace: "payment" })
  private actionSetPaymentMethod: any;
  @Action("actionCreatePaymentIntent", { namespace: "payment" })
  private actionCreatePaymentIntent: any;
  @Action("actionConfirmPaymentIntent", { namespace: "payment" })
  private actionConfirmPaymentIntent: any;
  @Action("actionGetMembership", { namespace: "user" })
  private actionGetMembership: any;
  @Action("actionSetMembership", { namespace: "user" })
  private actionSetMembership: any;

  /* STATE */
  @State("user_profile", { namespace: "user" })
  private user_profile!: UserModel;
  @State("payment_method", { namespace: "payment" })
  private payment_method!: PaymentMethodModel;
  @State("payment_intent", { namespace: "payment" })
  private payment_intent!: PaymentIntentModel;

  @Prop({ default: false })
  private isShow!: boolean;
  @Prop({ default: null })
  private item!: MembershipModel;

  @Watch("isShow", { immediate: true, deep: true })
  private onShow(val: any) {
    if (val) {
      this.isLoading = false;
      this.user.name =
        this.user_profile.first_name + this.user_profile.last_name;
      this.user.email = this.user_profile.email;
      const { VUE_APP_STRIPE_API_KEY } = process.env;

      if (VUE_APP_STRIPE_API_KEY) {
        loadStripe(VUE_APP_STRIPE_API_KEY, {
          locale: "en-GB",
        })
          .then((plugin) => {
            this.stripe = plugin;
            this.createElement();
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
  }

  /* INITIALIZATION */
  private isLoading = false;
  private isError = false;
  private stripe: any = null;
  private elements: any = null;
  private card: any = null;
  private isValidCard = false;
  private user = new PaymentMethodBillingModel();

  /* METHODS */
  private actionPay() {
    if (!this.user.name) {
      toast.warning("Please enter name!");
      return;
    }

    if (!this.user.email) {
      toast.warning("Please enter email!");
      return;
    }

    if (!this.isValidCard) {
      toast.warning("Your card is invalid!");
      return;
    }

    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;
    this.isLoading = true;

    this.stripe
      .createPaymentMethod({
        type: "card",
        card: this.card,
        billing_details: {
          name: this.user.name,
          email: this.user.email,
        },
      })
      .then((result: any) => {
        self.isError = false;
        const { paymentMethod, error } = result;
        if (error) {
          self.isLoading = false;
          self.isError = true;
        } else {
          self.actionSetPaymentMethod({ params: paymentMethod });
          const params = Object.assign(
            {},
            {
              id: paymentMethod.id,
              amount: self.item.price + "00",
              currency: "USD",
            }
          );
          self.actionCreatePaymentIntent({
            params,
            success: () => {
              self.onSuccessPaymentIntent();
            },
          });
        }
      });
  }

  private onSuccessPaymentIntent() {
    const { id, status } = this.payment_intent;
    if (status === PaymentStatus.requires_confirmation) {
      this.actionConfirmPaymentIntent({
        params: Object.assign({}, { id }),
        success: () => this.onSuccessPaymentIntent(),
      });
    } else if (status === PaymentStatus.requires_action) {
      console.log("Require Actions!");
    } else if (status === PaymentStatus.succeeded) {
      this.actionSetMembership({
        params: Object.assign({}, { id: this.item.id, payment_intent_id: id }),
        success: () => {
          this.actionGetMembership({
            success: () => {
              this.closeModal();
              toast.success("Payment Successfully!");
            },
          });
        },
      });
    } else {
      this.isLoading = false;
      console.log("Cancelled!");
    }
  }

  private closeModal() {
    this.isLoading = false;
    this.user = new PaymentMethodBillingModel();
    this.card = null;
    this.stripe = null;
    this.elements = null;
    this.isValidCard = false;
    this.isError = false;
    document.getElementById("closeButton")?.click();
  }

  private createElement() {
    this.elements = this.stripe.elements({
      fonts: [
        {
          cssSrc: "https://fonts.googleapis.com/css?family=Roboto",
        },
      ],
    });

    this.card = (this.elements as any).create("card", {
      iconStyle: "solid",
      hidePostalCode: true,
      style: {
        base: {
          iconColor: "#333333",
          fontWeight: 500,
          fontSize: "22px",
          fontFamily: '"Fira Sans", sans-serif',
          fontSmoothing: "antialiased",
          ":-webkit-autofill": {
            color: "#942dd9",
          },
          "::placeholder": {
            color: "#333333",
          },
        },
        invalid: {
          iconColor: "#942dd9",
          color: "#942dd9",
        },
      },
    });
    this.card.mount("#payment-card");
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;
    this.card.on("change", function (event: any) {
      if (event.complete) {
        self.isValidCard = true;
      } else {
        self.isValidCard = false;
      }
    });
  }
}
