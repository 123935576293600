





















































































import Vue from "vue";
import $ from "jquery";
import Component from "vue-class-component";

@Component
export default class Footer extends Vue {
  /* VUEX */
  /* ACTIONS */
  /* STATE */
  /* INITIALIZATION */

  /* METHODS */
  private onClickFAQ() {
    const { name } = this.$route;

    if (name !== "faq") {
      this.$router.push({ name: "faq" });
    }

    $("html, body").animate({ scrollTop: 0 }, 400);
  }

  private onClickAbout() {
    const { name } = this.$route;

    if (name !== "landing") {
      this.$router.push({
        name: "landing",
        params: { about: "scroll" },
      });
    } else {
      const offsetAbout = $("#about").offset();
      $("html, body").animate(
        {
          scrollTop: offsetAbout ? offsetAbout.top - 150 : 0,
        },
        400
      );
    }
  }
}
