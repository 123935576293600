import { Module } from "vuex";
import { RootState } from "../types";

import { actions } from "./actions";
import { QuestionState } from "./types";

import { QuestionModel } from "@/models/question/question.model";

export const state: QuestionState = {
  array_question: new Array<QuestionModel>(),
  delete_answer: new QuestionModel(),
  answer_question: new QuestionModel(),
};

const namespaced = true;

export const question: Module<QuestionState, RootState> = {
  namespaced,
  state,
  actions,
};
