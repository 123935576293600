import { ActionTree } from "vuex";
import { AxiosResponse } from "axios";

import { RootState } from "../types";
import { MiscState } from "./types";

import { TOKEN_INVALID } from "@/constants/enum.constant";
import { ResponseModel } from "@/models/response/response.model";
import { deleteToken, getResponseKey } from "@/functions/token.function";
import { DecryptAES, RefreshKeyFromToken } from "@/functions/crypto.function";

import router from "@/router";
import toast from "@/functions/toast.function";

import MiscService from "@/services/misc.service";

const service: MiscService = new MiscService();

export const actions: ActionTree<MiscState, RootState> = {
  actionGetFilterList({ commit }, callback: any) {
    service.serviceFilterList().then((response: AxiosResponse) => {
      if (response.status === 200) {
        if (typeof response.data === "string") {
          // Decrypt model response with AES using ResponseKey
          const resJSON = DecryptAES(response.data, getResponseKey());
          const mResponse = new ResponseModel();

          mResponse.setObject(resJSON);
          if (mResponse.token) {
            RefreshKeyFromToken(mResponse.token);
          }

          if (mResponse.status) {
            commit("mutationList", mResponse);

            if (callback && callback.success) {
              callback.success(mResponse);
            }
          }
        } else {
          const { message } = response.data;
          toast.error(message);
          if (TOKEN_INVALID.includes(message)) {
            deleteToken();
            router.push({ name: "signin" }, () => {
              location.reload();
            });
          }
        }
      } else {
        if (callback && callback.fail) {
          callback.fail();
        }
      }
    });
  },
  actionGetFAQList({ commit }, callback: any) {
    service
      .serviceFAQList()
      .then((response: AxiosResponse) => {
        if (response.status == 200) {
          const { status, results } = response.data;

          console.log("RESPONSE FAQ : ", response.data);

          if (status) {
            commit("mutationFAQList", results);

            if (callback && callback.success) {
              callback.success(response);
            }
          } else {
            const { message } = response.data;
            toast.error(message);
            if (TOKEN_INVALID.includes(message)) {
              deleteToken();
              router.push({ name: "signin" }, () => {
                location.reload();
              });
            }
          }
        } else {
          if (callback && callback.fail) {
            callback.fail();
          }
        }
      })
      .catch((e: any) => {
        const { message } = e.response.data;
        toast.error(message);
        if (callback.error) {
          callback.error(message);
        }
        if (TOKEN_INVALID.includes(message)) {
          deleteToken();
          router.push({ name: "signin" }, () => {
            location.reload();
          });
        }
      });
  },
  actionSearchFAQ({ state, dispatch }, callback: any) {
    if (callback.params) {
      const result = state.array_faq.filter((el: any) => {
        if (
          el.question.includes(callback.params) ||
          el.answer.includes(callback.params)
        ) {
          return el;
        }
      });

      state.faq_search = callback.params;
      state.array_faq_filter = result;
    } else {
      state.faq_search = "";
      state.array_faq_filter = new Array<any>();
      dispatch("actionGetFAQList");
    }
  },
};
