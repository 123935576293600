import { AxiosResponse } from "axios";
import { Base } from "./base/base.service";
import { getToken } from "@/functions/token.function";

export default class PaymentService {
  public serviceListMembership(): Promise<AxiosResponse<any>> {
    return Base().get("filter/list-membership", {
      headers: {
        "Content-Type": "text/plain",
        Authorization: `Bearer ${getToken()}`,
      },
    });
  }
  public serviceListHistory(params: any): Promise<AxiosResponse<any>> {
    return Base().post("user/purchase-history", params, {
      headers: {
        "Content-Type": "text/plain",
        Authorization: `Bearer ${getToken()}`,
      },
    });
  }
  public serviceCreatePaymentIntent(params: any): Promise<AxiosResponse<any>> {
    return Base().post("payment/create/payment-intent", params, {
      headers: {
        "Content-Type": "text/plain",
        Authorization: `Bearer ${getToken()}`,
      },
    });
  }
  public serviceConfirmPaymentIntent(params: any): Promise<AxiosResponse<any>> {
    return Base().post("payment/confirm", params, {
      headers: {
        "Content-Type": "text/plain",
        Authorization: `Bearer ${getToken()}`,
      },
    });
  }

  public servicePaymentTazapey(params: any): Promise<AxiosResponse<any>> {
    return Base().post("payment/create", params, {
      headers: {
        "Content-Type": "text/plain",
        Authorization: `Bearer ${getToken()}`,
      },
    });
  }
}
