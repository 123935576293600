



















































































































































































import Vue from "vue";
import $ from "jquery";
import Component from "vue-class-component";
import { Action, State } from "vuex-class";
import { Watch } from "vue-property-decorator";

import { ASSETS } from "@/services/base/apibase";
import { UserModel } from "@/models/user/user.model";
import { ChannelModel } from "@/models/chat/chat.model";
import { SeekerModel } from "@/models/seeker/seeker.model";

@Component
export default class SidebarRight extends Vue {
  /* VUEX */

  /* ACTIONS */
  @Action("actionExploreSeeker", { namespace: "seeker" })
  private actionExploreSeeker: any;
  @Action("actionFavoriteSeeker", { namespace: "seeker" })
  private actionFavoriteSeeker: any;
  @Action("actionSetFavorite", { namespace: "seeker" })
  private actionSetFavorite: any;
  @Action("actionListChannel", { namespace: "chat" })
  private actionListChannel: any;
  @Action("actionCreateChannel", { namespace: "chat" })
  private actionCreateChannel: any;
  @Action("actionCheckOpponent", { namespace: "chat" })
  private actionCheckOpponent: any;
  @Action("actionSelectNotification", { namespace: "notification" })
  private actionSelectNotification: any;

  /* STATE */
  @State("user_profile", { namespace: "user" })
  private user_profile!: UserModel;
  @State("vip_list", { namespace: "seeker" })
  private vip_list!: Array<SeekerModel>;
  @State("favorite_list", { namespace: "seeker" })
  private favorite_list!: Array<SeekerModel>;
  @State("free_chat", { namespace: "chat" })
  private free_chat!: UserModel;

  @Watch("favorite_list")
  private onVIPListChanged(val: Array<SeekerModel>) {
    if (val.length > 0) {
      this.loadSlider();
    }
  }

  /* INITIALIZATION */
  private created() {
    this.loadSlider();
  }

  private mounted() {
    this.loadSlider();
  }

  /* METHODS */
  private getExplore() {
    this.actionExploreSeeker({
      success: () => {
        this.actionSelectNotification();
        this.actionFavoriteSeeker({
          success: () => {
            this.loadSlider();
          },
        });
      },
    });
  }

  private clickFavorite(seeker: SeekerModel) {
    this.actionSetFavorite({
      params: seeker,
      success: () => {
        this.getExplore();
      },
    });
  }

  private onDetail(seeker: SeekerModel) {
    this.$router.push({
      name: "detail-seeker",
      params: { id: seeker.id.toString() },
    });
  }

  private clickChat(seeker: SeekerModel) {
    this.$bvModal.show("lds-loader");

    const channel_data = new ChannelModel();
    channel_data.user_from_id = this.user_profile.id;
    channel_data.user_from.setObject(this.user_profile);

    if (this.user_profile.membership.id) {
      channel_data.user_to_id = seeker.id;
      channel_data.user_to.setObject(seeker);
      this.actionCreateChannel({
        params: channel_data,
        success: () => {
          this.$bvModal.hide("lds-loader");
          this.actionListChannel({ params: this.user_profile.id });
          this.$bvModal.show("usermessage");
        },
        error: () => {
          this.$bvModal.hide("lds-loader");
        },
      });
    } else {
      this.actionCheckOpponent({
        params: { id: seeker.id },
        success: () => {
          if (this.free_chat.id) {
            if (seeker.id === this.free_chat.id) {
              channel_data.user_to_id = this.free_chat.id;
              channel_data.user_to.setObject(this.free_chat);
              this.actionCreateChannel({
                params: channel_data,
                success: () => {
                  this.$bvModal.hide("lds-loader");
                  this.actionListChannel({ params: this.user_profile.id });
                  this.$bvModal.show("usermessage");
                },
                error: () => {
                  this.$bvModal.hide("lds-loader");
                },
              });
            } else {
              this.$bvModal.hide("lds-loader");
              this.$bvModal.show("modal-no-subscribe");
            }
          } else {
            channel_data.user_to_id = seeker.id;
            channel_data.user_to.setObject(seeker);
            this.actionCreateChannel({
              params: channel_data,
              success: () => {
                this.$bvModal.hide("lds-loader");
                this.actionListChannel({ params: this.user_profile.id });
                this.$bvModal.show("usermessage");
              },
              error: () => {
                this.$bvModal.hide("lds-loader");
              },
            });
          }
        },
        error: () => {
          this.$bvModal.hide("lds-loader");
        },
      });
    }
  }

  private getImage(image: string) {
    if (image) {
      return ASSETS + "user/photo/" + image;
    }
    return "";
  }

  private loadSlider() {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;
    $(document).ready(function () {
      self.sliderVIP();
      self.sliderFavorite();
    });
  }

  private sliderVIP() {
    // eslint-disable-next-line no-undef
    const jquery: any = jQuery;
    jquery(".slider-vip").owlCarousel({
      loop: true,
      responsiveClass: true,
      nav: true,
      dots: false,
      navText: [
        '<i class="fa fa-angle-left"></i>',
        '<i class="fa fa-angle-right"></i>',
      ],
      margin: 30,
      autoplay: false,
      autoplayTimeout: 2000,
      autoplayHoverPause: true,
      smartSpeed: 2000,
      center: true,
      responsive: {
        0: {
          items: 1,
        },
      },
    });
  }

  private sliderFavorite() {
    const isLoop = this.favorite_list.length > 8;
    // eslint-disable-next-line no-undef
    const jquery: any = jQuery;
    jquery(".slider-favorite").owlCarousel({
      loop: isLoop,
      responsiveClass: true,
      nav: true,
      dots: false,
      navText: [
        '<i class="fa fa-angle-left"></i>',
        '<i class="fa fa-angle-right"></i>',
      ],
      margin: 30,
      autoplay: false,
      autoplayTimeout: 2000,
      autoplayHoverPause: true,
      smartSpeed: 2000,
      center: true,
      responsive: {
        0: {
          items: 1,
        },
      },
    });
  }
}
