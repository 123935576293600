import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./registerServiceWorker";

import i18n from "@/i18n";

import $ from "jquery";
import vSelect from "vue-select";

import VueImageCropUpload from "vue-image-crop-upload";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";

// Import Bootstrap an BootstrapVue CSS files (order is important)
// import "bootstrap/dist/css/bootstrap.css";
// import "bootstrap-vue/dist/bootstrap-vue.css";

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);

import "vue-select/dist/vue-select.css";
import "izitoast/dist/css/iziToast.min.css";

Vue.component("v-select", vSelect);
Vue.component("v-image-crop", VueImageCropUpload);

Vue.config.productionTip = false;

const app = new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
  mounted() {
    $("[data-aos]").parent().addClass("hideOverflowOnMobile");
  },
}).$mount("#app");

export default app