

























import Vue from "vue";
import Component from "vue-class-component";
import { State } from "vuex-class";

import { UserModel } from "@/models/user/user.model";

import InputEmail from "@/components/forgotpassword/InputEmail.vue";
import ForgotPasswordVerify from "@/components/forgotpassword/Verify.vue";
import ResetPassword from "@/components/forgotpassword/ResetPassword.vue";

@Component({
  components: {
    InputEmail,
    ForgotPasswordVerify,
    ResetPassword,
  },
})
export default class ForgotPassword extends Vue {
  /* VUEX */

  /* ACTION */

  /* STATE */
  @State("user_auth", { namespace: "user" })
  private user_auth!: UserModel;

  /* INITIALIZATION */
  private activeStep = 1;

  private get isShowEmail() {
    return this.activeStep === 1;
  }

  private get isShowVerify() {
    return this.activeStep === 2;
  }

  private get isShowReset() {
    return this.activeStep === 3;
  }

  private created() {
    if (!this.user_auth.email) {
      this.$router.push({ name: "signin" });
    }
  }

  /* METHODS */
}
