







































































































































































































































import Vue from "vue";
import $ from "jquery";
import filters from "@/filters";
import Component from "vue-class-component";
import { Action, State } from "vuex-class";
import { Watch } from "vue-property-decorator";

import { ASSETS } from "@/services/base/apibase";
import { UserModel } from "@/models/user/user.model";
import { ChannelModel, MessageModel } from "@/models/chat/chat.model";

import toast from "@/functions/toast.function";
import EmojiPicker from "vue-emoji-picker";

@Component({
  filters,
  directives: {
    focus: {
      inserted(el) {
        el.focus();
      },
    },
  },
  components: {
    EmojiPicker,
  },
})
export default class ModalMessage extends Vue {
  /* VUEX */

  /* ACTIONS */
  @Action("actionSelectChannel", { namespace: "chat" })
  private actionSelectChannel: any;
  @Action("actionSendMessage", { namespace: "chat" })
  private actionSendMessage: any;
  @Action("actionListChannel", { namespace: "chat" })
  private actionListChannel: any;
  @Action("actionSetOpponent", { namespace: "chat" })
  private actionSetOpponent: any;
  @Action("actionEndChat", { namespace: "chat" })
  private actionEndChat: any;

  /* STATE */
  @State("user_profile", { namespace: "user" })
  private user_profile!: UserModel;
  @State("validation", { namespace: "chat" })
  private validation: any;
  @State("free_chat", { namespace: "chat" })
  private free_chat!: UserModel;
  @State("channel", { namespace: "chat" })
  private channel!: ChannelModel;
  @State("array_channel", { namespace: "chat" })
  private array_channel!: Array<ChannelModel>;
  @State("message", { namespace: "chat" })
  private message!: MessageModel;
  @State("array_message", { namespace: "chat" })
  private array_message!: Array<MessageModel>;

  @Watch("channel", { deep: true, immediate: true })
  private onChannelExist(val: any) {
    if (val.id.length>10) {
      this.showChat = true;
      this.scrollToBottom();
    }
  }

  /* INITIALIZATION */
  private showChat = false;
  private search = "";

  /* METHODS */
  private append(emoji: any) {
    this.message.text += ` ${emoji} `;
  }

  private isUser(userId: number) {
    return this.user_profile.id === userId;
  }

  private userPhoto(filename: string, useThumb = false) {
    if (filename) {
      if (useThumb) {
        return ASSETS + "user/photo/thumb/" + filename;
      }
      return ASSETS + "user/photo/" + filename;
    }

    if ((this.channel.user_to_id as any) === 'admin') return require("@/assets/icons/logo_smixy_round-border.jpg")
    else return ASSETS + "user/photo/default.jpg";
  }

  private onClickChannel(selChannel: ChannelModel) {
    this.actionSelectChannel({
      params: selChannel,
      sender_from: this.user_profile,
      success: () => {
        this.showChat = true;
      },
    });
  }

  private onSendMessage() {
    if (!this.message.text.trim()) {
      toast.warning("Please enter your message!");
      return;
    }

    this.actionSendMessage({
      params: { sender: this.user_profile },
      success: () => {
        if (!this.user_profile.membership.id) {
          if (!this.free_chat.id) {
            if (this.array_message.length < 1 && (this.channel.user_to_id as any) !== 'admin') {
              this.actionSetOpponent({
                params: { id: this.channel.user_to_id },
                success: () => {
                  // Todo
                },
              });
            }
          }
        }
        // this.actionListChannel({ params: this.user_profile.id });
        // this.actionSelectChannel({
        //   params: this.channel,
        //   sender_from: this.user_profile,
        //   success: () => {
        //     this.showChat = true;
        //   },
        // });
      },
    });
  }

  private scrollToBottom() {
    /**
     * Scroll to bottom to get the last of messages
     * Get element by id => chat
     * animate element scrolTop to scrollHeight
     */
    setTimeout(() => {
      const elchat = document.getElementById("chat");

      $("#chat").animate({ scrollTop: elchat?.scrollHeight }, 300);
    }, 600);
  }

  OnEndChat() {
    this.actionEndChat({
      params: this.channel,
      sender_from: this.user_profile,
      success: () => {
        this.$emit("onEnded", false)
        this.$bvModal.hide('usermessage')
      }
    })
  }
}
