














































import Vue from "vue";
import Component from "vue-class-component";

@Component
export default class JoinNavbar extends Vue {
  /* VUEX */
  /* ACTIONS */
  /* STATE */
  /* INITIALIZATION */
  /* METHODS */
  download() {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (window.pwa_event) window.pwa_event.prompt();
  }
}
