













































import Vue from "vue";
import Component from "vue-class-component";

import toast from "@/functions/toast.function";
import { Action } from "vuex-class";

@Component
export default class ModalDeleteQuestion extends Vue {
  /* VUEX */

  /* ACTION */
  @Action("actionDeleteAnswerQuestion", { namespace: "question" })
  private actionDeleteAnswerQuestion: any;
  @Action("actionGetListPhoto", { namespace: "user" })
  private actionGetListPhoto: any;

  /* STATE */

  /* INITIALIZATION */

  /* METHODS */
  private onDeleteQuestion() {
    this.actionDeleteAnswerQuestion({
      success: () => {
        this.actionGetListPhoto();
        toast.success("Success Delete Question!");
        this.$bvModal.hide("modal-delete-question");
      },
      fail: () => {
        this.$bvModal.hide("modal-delete-question");
      },
    });
  }
}
