import { Module } from "vuex";
import { RootState } from "../types";

import { actions } from "./actions";
import { NotificationState } from "./types";
import { createPagination } from "@/functions/global.function";
import { NotificationModel } from "@/models/notification/notification.model";

export const state: NotificationState = {
  unread_count: 0,
  array_notification_unread: new Array<number>(),
  array_notification_general: new Array<NotificationModel>(),
  array_notification_profile: new Array<NotificationModel>(),
  pagination_notification_profile: createPagination({}),
};

const namespaced = true;

export const notification: Module<NotificationState, RootState> = {
  namespaced,
  state,
  actions,
};
