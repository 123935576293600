










import Vue from "vue";
import Component from "vue-class-component";
import { Action } from "vuex-class";
import JoinNavbar from "@/components/join/JoinNavbar.vue";
import JoinHeader from "@/components/join/JoinHeader.vue";
import JoinProfile from "@/components/join/JoinProfile.vue";
import WhyJoinUs from "@/components/join/WhyJoinUs.vue";
import JoinFeatures from "@/components/join/JoinFeatures.vue";

@Component({
  components: {
    JoinNavbar,
    JoinHeader,
    JoinProfile,
    WhyJoinUs,
    JoinFeatures,
  },
})
export default class Join extends Vue {
  /* VUEX */
  /* ACTIONS */
  @Action("appLayout")
  private appLayout: any;
  /* STATE */
  /* INITIALIZATION */
  private created() {
    this.appLayout();
  }
  /* METHODS */
}
