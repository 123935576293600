import { MutationTree } from "vuex";
import { RootState } from "./types";

export const mutations: MutationTree<RootState> = {
  mutationLayout(state, payload: string) {
    state.layout = payload;
  },
  mutationLocale(state, payload: string) {
    state.locale = payload;
  },
};
