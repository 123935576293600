













































import Vue from "vue";
import Component from "vue-class-component";
import { Action, State } from "vuex-class";
import { Prop, Watch } from "vue-property-decorator";

import i18n from "@/i18n";
import toast from "@/functions/toast.function";

import { UserModel } from "@/models/user/user.model";

@Component
export default class ForgotPasswordVerify extends Vue {
  /* VUEX */

  /* ACTION */
  @Action("actionVerifyOTP", { namespace: "user" })
  private actionVerifyOTP: any;
  @Action("actionForgotPassword", { namespace: "user" })
  private actionForgotPassword: any;

  /* STATE */
  @State("user_auth", { namespace: "user" })
  private user_auth!: UserModel;

  @Prop({ default: false })
  private isShow!: boolean;

  @Watch("isShow")
  private onShowVerify(val: any) {
    if (val) {
      this.start = 60;
      this.startTimer();
    }
  }

  /* INITIALIZATION */
  private start = 60;
  private timer: any = null;
  private otp: any = null;

  /* METHODS */
  private startTimer() {
    this.timer = setInterval(() => {
      if (this.start < 1) {
        clearInterval(this.timer);
      } else {
        this.start = this.start - 1;
      }
    }, 1000);
  }

  private onVerify() {
    this.actionVerifyOTP({
      params: this.otp,
      success: (data: any) => {
        this.$emit("on-success", data);
      },
    });
  }

  private onResend() {
    this.actionForgotPassword({
      success: () => {
        toast.success(i18n.t("success_resend_otp"));
        this.start = 60;
        this.startTimer();
      },
    });
  }
}
