import { Module } from "vuex";
import { RootState } from "../types";

import { actions } from "./actions";
import { mutations } from "./mutations";
import { MiscState } from "./types";

export const state: MiscState = {
  array_ethnic: new Array<any>(),
  array_eye_color: new Array<any>(),
  array_gender: new Array<any>(),
  array_hair_color: new Array<any>(),
  array_language: new Array<any>(),
  array_location: new Array<any>(),
  array_state: new Array<any>(),
  array_looking_for: new Array<any>(),
  array_membership: new Array<any>(),
  array_reason_deleted: new Array<any>(),
  array_report_type: new Array<any>(),
  array_wear_glasses: new Array<any>(),
  array_faq: new Array<any>(),
  array_faq_filter: new Array<any>(),
  faq_search: "",
};

const namespaced = true;

export const misc: Module<MiscState, RootState> = {
  namespaced,
  state,
  actions,
  mutations,
};
