



































import Vue from "vue";
import Component from "vue-class-component";
import { Action, State } from "vuex-class";

import { UserModel } from "@/models/user/user.model";
import { deleteAuthKey } from "@/functions/token.function";

@Component
export default class CreatePassword extends Vue {
  /* VUEX */

  /* ACTIONS */
  @Action("actionCreatePassword", { namespace: "user" })
  private actionCreatePassword: any;
  @Action("actionGetLocation", { namespace: "region" })
  private actionGetLocation: any;

  /* STATE */
  @State("user_auth", { namespace: "user" })
  private user_auth!: UserModel;

  /* INITIALIZATION */

  /* METHODS */
  private onSubmit() {
    this.actionCreatePassword({
      success: (data: any) => {
        if (data.status) {
          this.$emit("on-success");
          deleteAuthKey();
          this.actionGetLocation();
        }
      },
    });
  }

  private getFocus(id: string) {
    document.getElementById(id)?.focus();
  }
}
