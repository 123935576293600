export function createPagination(data: any) {
  const result: any = Object.assign(
    {},
    {
      next: false,
      page_current: 0,
      page_length: 0,
    }
  );

  if (data.next) {
    result.next = data.next;
  }
  if (data.page_current) {
    result.page_current = data.page_current;
  }
  if (data.page_length) {
    result.page_length = data.page_length;
  }
  if (data.user_list) {
    result.user_list = data.user_list;
  }

  return result;
}

// Convert file to base64 string
export function fileToBase64(filePath: any, callback: any) {
  const reader = new FileReader();
  // Read file content on file loaded event
  reader.onload = function (event: any) {
    // use a regex to remove data url part
    const base64String = event.target.result
      .replace("data:", "")
      .replace(/^.+,/, "");
    callback(base64String);
  };

  // Convert data to base64
  reader.readAsDataURL(filePath);
}

export function getBase64(file: File) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

export function data2blob(data: string, mime: string): Blob {
  data = data.split(",")[1];
  data = window.atob(data);
  const ia = new Uint8Array(data.length);
  for (let i = 0; i < data.length; i++) {
    ia[i] = data.charCodeAt(i);
  }
  // canvas.toDataURL image/png
  return new Blob([ia], {
    type: mime,
  });
}

export function feetInchToCentimeter(feet: string, inches: string) {
  let calFeet: any = 0;
  let calInch: any = 0;

  if (feet) {
    calFeet = parseFloat(feet) * 30.48;
  }

  if (inches) {
    calInch = parseFloat(inches) * 2.54;
  }

  return calFeet + calInch;
}
