import { Module } from "vuex";
import { RootState } from "../types";

import { actions } from "./actions";
import { RegionState } from "./types";

import { LocationModel } from "@/models/region/region.model";

export const state: RegionState = {
  array_location: new Array<LocationModel>(),
  array_state: new Array<LocationModel>(),
};

const namespaced = true;

export const region: Module<RegionState, RootState> = {
  namespaced,
  state,
  actions,
};
