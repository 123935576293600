import { Module } from "vuex";
import { RootState } from "../types";

import { actions } from "./actions";
import { Handshake } from "../../models/handshake/handshake.type";
import { HandshakeModel } from "../../models/handshake/handshake.model";

export const state: Handshake = new HandshakeModel();

const namespaced = true;

export const handshake: Module<Handshake, RootState> = {
  namespaced,
  state,
  actions,
};
