


















































































import Vue from "vue";
import Component from "vue-class-component";
import { Action, State } from "vuex-class";

import firebase from "firebase";
import firebaseApp from "@/firebase";
import { UserModel } from "@/models/user/user.model";

import i18n from "@/i18n";
import toast from "@/functions/toast.function";

@Component
export default class Signin extends Vue {
  /* VUEX */

  /* ACTIONS */
  @Action("authLayout")
  private authLayout: any;
  @Action("actionCheckEmail", { namespace: "user" })
  private actionCheckEmail: any;
  @Action("actionLogin", { namespace: "user" })
  private actionLogin: any;
  @Action("actionLoginSosmed", { namespace: "user" })
  private actionLoginSosmed: any;
  @Action("actionGetMembership", { namespace: "user" })
  private actionGetMembership: any;
  @Action("actionExploreSeeker", { namespace: "seeker" })
  private actionExploreSeeker: any;
  @Action("actionFavoriteSeeker", { namespace: "seeker" })
  private actionFavoriteSeeker: any;
  @Action("actionGetLocation", { namespace: "region" })
  private actionGetLocation: any;
  @Action("actionListChannel", { namespace: "chat" })
  private actionListChannel: any;
  @Action("actionSelectNotification", { namespace: "notification" })
  private actionSelectNotification: any;

  /* STATE */
  @State("user_auth", { namespace: "user" })
  private user_auth!: UserModel;
  @State("user_profile", { namespace: "user" })
  private user_profile!: UserModel;

  /* INITIALIZATION */
  private show_password = false;

  private created() {
    this.authLayout();

    const { params } = this.$route;
    if (params.page) {
      switch (params.page) {
        case "password":
          this.show_password = true;
          break;
        default:
          this.show_password = false;
          break;
      }
    }
  }

  /* METHODS */
  private async onLogin() {
    if (this.show_password) {
      if (firebaseApp) {
        const fcm_token = await firebaseApp.fcmToken;
        if (fcm_token) {
          this.$bvModal.show("lds-loader");
          this.actionLogin({
            params: fcm_token,
            success: (res: any) => {
              if (res.status) {
                this.$bvModal.hide("lds-loader");
                this.navigateHome();
              }
            },
            error: () => {
              this.$bvModal.hide("lds-loader");
            },
          });
        } else {
          this.defaultLogin();
        }
      } else {
        this.defaultLogin();
      }
    } else {
      this.actionCheckEmail({
        success: (data: any) => {
          const { first_name, register_status } = data.results;
          if (first_name) {
            this.show_password = true;
          } else {
            if (register_status) {
              setTimeout(() => {
                this.onSignup({ page: "password" });
              }, 500);
            } else {
              toast.success(i18n.t("success_send_otp"));
              setTimeout(() => {
                this.onSignup({ page: "otp" });
              }, 500);
            }
          }
        },
      });
    }
  }

  private defaultLogin() {
    this.$bvModal.show("lds-loader");
    this.actionLogin({
      params: null,
      success: (res: any) => {
        if (res.status) {
          this.$bvModal.hide("lds-loader");
          this.navigateHome();
        }
      },
      error: () => {
        this.$bvModal.hide("lds-loader");
      },
    });
  }

  private onLoginGoogle() {
    this.$bvModal.show("lds-loader");

    const googleProvider = new firebase.auth.GoogleAuthProvider();
    googleProvider.addScope("email");
    googleProvider.addScope("https://www.googleapis.com/auth/userinfo.profile");
    googleProvider.addScope("https://www.googleapis.com/auth/user.gender.read");
    googleProvider.addScope(
      "https://www.googleapis.com/auth/user.birthday.read"
    );
    firebaseApp.auth
      .signInWithPopup(googleProvider)
      .then(async (res: any) => {
        const { email } = res.user;
        const { profile } = res.additionalUserInfo;
        const fcm_token = await firebaseApp.fcmToken;

        this.actionLoginSosmed({
          params: {
            platform: "google",
            email,
            first_name: profile.given_name,
            last_name: profile.family_name,
            fcm_token,
          },
          success: () => {
            if (!this.user_profile.location) {
              this.user_auth.setObject(this.user_profile);
              this.actionGetLocation({
                success: () => {
                  this.onSignup({ page: "create_profile" });
                },
              });
            } else {
              this.navigateHome();
            }
          },
        });
        this.$bvModal.hide("lds-loader");
      })
      .catch((e: any) => {
        console.log("ERROR : ", e);
        this.$bvModal.hide("lds-loader");
      });
  }

  private onLoginFacebook() {
    this.$bvModal.show("lds-loader");

    const facebookProvider = new firebase.auth.FacebookAuthProvider();

    facebookProvider.addScope("user_location");
    facebookProvider.addScope("user_gender");
    facebookProvider.addScope("user_birthday");
    facebookProvider.addScope("email");
    facebookProvider.addScope("public_profile");

    firebaseApp.auth
      .signInWithPopup(facebookProvider)
      .then(async (res: any) => {
        this.$bvModal.hide("lds-loader");
        console.log("Facebook Auth Response: ", res);
      })
      .catch((e: any) => {
        this.$bvModal.hide("lds-loader");
        console.log("Facebook Auth Error: ", e);
      });
  }

  private onSignup(params: any) {
    this.$router.push({ name: "signup", params });
  }

  private navigateHome() {
    this.$router.push({ name: "dashboard" }, () => {
      location.reload();
      this.actionGetMembership();
      this.getExplore();
      this.actionListChannel({ params: this.user_profile.id });
    });
  }

  private getExplore() {
    this.actionExploreSeeker({
      success: () => {
        this.actionSelectNotification();
        this.actionFavoriteSeeker();
      },
    });
  }
}
