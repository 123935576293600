import { AxiosResponse } from "axios";
import { Base } from "./base/base.service";
import { getToken } from "@/functions/token.function";

export default class ChatService {
  public serviceSelectOpponent(params: any): Promise<AxiosResponse<any>> {
    return Base().post("chat/check-opponent", params, {
      headers: {
        "Content-Type": "text/plain",
        Authorization: `Bearer ${getToken()}`,
      },
    });
  }
  public serviceUpdateOpponent(params: any): Promise<AxiosResponse<any>> {
    return Base().post("chat/set-opponent", params, {
      headers: {
        "Content-Type": "text/plain",
        Authorization: `Bearer ${getToken()}`,
      },
    });
  }
  public serviceValidation(params: any): Promise<AxiosResponse<any>> {
    return Base().post("chat/validation", params, {
      headers: {
        "Content-Type": "text/plain",
        Authorization: `Bearer ${getToken()}`,
      },
    });
  }
  public serviceSendMessage(params: any): Promise<AxiosResponse<any>> {
    return Base().post("chat/messaging/send", params, {
      headers: {
        "Content-Type": "text/plain",
        Authorization: `Bearer ${getToken()}`,
      },
    });
  }
}
