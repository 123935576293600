import moment from "moment";
import { ActionTree } from "vuex";
import { RootState } from "./types";

import i18n from "@/i18n";

export const actions: ActionTree<RootState, RootState> = {
  changeLocale({ commit, state }, payload: string) {
    commit("mutationLocale", payload);
    i18n.locale = state.locale;
    moment.locale(state.locale);
  },
  authLayout({ commit }) {
    commit("mutationLayout", "auth-layout");
  },
  appLayout({ commit }) {
    commit("mutationLayout", "app-layout");
  },
};
