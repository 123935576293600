



















































import Vue from "vue";
import $ from "jquery";
import Component from "vue-class-component";
import { Action } from "vuex-class";

import BannerComponent from "@/components/landing/Banner.vue";
import FeatureComponent from "@/components/landing/Feature.vue";
import StartFlirtingComponent from "@/components/landing/StartFlirting.vue";
import HowItWorkComponent from "@/components/landing/HowItWork.vue";
import AmazingFeatureComponent from "@/components/landing/AmazingFeature.vue";

@Component({
  components: {
    BannerComponent,
    FeatureComponent,
    StartFlirtingComponent,
    HowItWorkComponent,
    AmazingFeatureComponent,
  },
})
export default class Landing extends Vue {
  /* VUEX */

  /* ACTIONS */
  @Action("appLayout")
  private appLayout: any;

  /* STATE */

  /* INITIALIZATION */
  private created() {
    this.appLayout();
  }

  private mounted() {
    const { params } = this.$route;

    if (params && params.about) {
      const offsetAbout = $("#about").offset();
      $("html, body").animate(
        {
          scrollTop: offsetAbout ? offsetAbout.top - 150 : 0,
        },
        400
      );
    } else {
      $("html, body").animate({ scrollTop: 0 }, 400);
    }
  }

  /* METHODS */
}
