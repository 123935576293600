import Vue from "vue";
import Vuex, { StoreOptions } from "vuex";
import { RootState } from "./types";

import { actions } from "@/store/actions";
import { mutations } from "@/store/mutations";

import { user } from "@/store/user/index";
import { seeker } from "@/store/seeker/index";
import { region } from "@/store/region/index";
import { payment } from "@/store/payment/index";
import { handshake } from "@/store/handshake/index";
import { chat } from "@/store/chat/index";
import { notification } from "@/store/notification/index";
import { misc } from "@/store/misc/index";
import { question } from "@/store/question/index";

Vue.use(Vuex);

const store: StoreOptions<RootState> = {
  modules: {
    handshake,
    user,
    seeker,
    region,
    payment,
    chat,
    notification,
    misc,
    question,
  },
  actions,
  mutations,
  state: {
    layout: "",
    locale: "en",
  },
};

export default new Vuex.Store<RootState>(store);
