



































































































import Vue from "vue";
import Component from "vue-class-component";
import { Action, State } from "vuex-class";

import { UserModel } from "@/models/user/user.model";
import { LocationModel } from "@/models/region/region.model";
import { TARGET_GENDER_OPTIONS } from "@/constants/enum.constant";

@Component
export default class CreateProfile extends Vue {
  /* VUEX */

  /* ACTIONS */
  @Action("actionGetState", { namespace: "region" })
  private actionGetState: any;
  @Action("actionResetState", { namespace: "region" })
  private actionResetState: any;

  /* STATE */
  @State("user_auth", { namespace: "user" })
  private user_auth!: UserModel;
  @State("array_location", { namespace: "region" })
  private array_location!: Array<LocationModel>;
  @State("array_state", { namespace: "region" })
  private array_state!: Array<LocationModel>;

  /* INITIALIZATION */
  private location: any = null;
  private state: any = null;
  private targetGenderOptions = TARGET_GENDER_OPTIONS

  private created() {
    /**
     * Check data user from landing.
     * data { gender, birthday & location }
     * if exist, add to user model.
     */
    const gender = localStorage.getItem("user_gender");
    const birthday = localStorage.getItem("user_birthday");
    const location = localStorage.getItem("user_location");

    if (gender) {
      this.user_auth.gender = gender;
    }

    if (birthday) {
      this.user_auth.birth = birthday;
    }

    if (location) {
      const locTemp = new LocationModel();
      locTemp.setObject(JSON.parse(location));
      this.location = locTemp;
      this.onSelectLocation(locTemp);
    }
  }

  /* METHODS */
  private onSubmit() {
    if (this.user_auth.validateCreateProfile()) {
      this.$emit("on-success");
    }
  }

  private onSelectLocation(item: LocationModel) {
    if (item) {
      this.user_auth.location = item.name;
      this.actionGetState({
        params: item.id,
        success: () => {
          this.state = null;
          this.user_auth.state = null;
        },
      });
    } else {
      this.actionResetState();
      this.user_auth.location = null;
      this.state = null;
      this.user_auth.state = null;
    }
  }

  private onSelectState(item: LocationModel) {
    this.user_auth.state = item.name;
  }

  private changeGender(gender: string) {
    this.user_auth.gender = gender;
  }

  private get isOver18() {
    const date18YrsAgo = new Date();
    date18YrsAgo.setFullYear(date18YrsAgo.getFullYear() - 18);

    let dateYear: any = date18YrsAgo.getFullYear();
    let dateMonth: any = date18YrsAgo.getMonth() + 1;
    let dateDay: any = date18YrsAgo.getDate();

    if (dateMonth < 10) {
      dateMonth = "0" + dateMonth.toString();
    }
    if (dateDay < 10) {
      dateDay = "0" + dateDay.toString();
    }
    const maxDate = dateYear + "-" + dateMonth + "-" + dateDay;

    return maxDate;
  }

  private getFocus(id: string) {
    document.getElementById(id)?.focus();
  }
}
