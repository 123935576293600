/* eslint-disable @typescript-eslint/no-unused-vars */
import { ActionTree } from "vuex";
import { RootState } from "../types";
import { UserState } from "./types";
import { AxiosResponse } from "axios";

import i18n from "@/i18n";
import router from "@/router";
import toast from "@/functions/toast.function";
import UserService from "@/services/user.service";

import { mailformat, passwordChecker } from "@/constants/global.constant";
import { TOKEN_INVALID } from "@/constants/enum.constant";
import { ResponseModel } from "@/models/response/response.model";
import { HandshakeModel } from "@/models/handshake/handshake.model";
import {
  UserAdditionalModel,
  UserMembershipModel,
  UserPasswordModel,
  UserPhotoModel,
} from "@/models/user/user.model";
import {
  DecryptAES,
  EncryptAES,
  ParseHandshakeFromToken,
  RefreshKeyFromToken,
} from "@/functions/crypto.function";
import {
  deleteAuthKey,
  deleteToken,
  getRequestKey,
  getResponseKey,
  getToken,
  setRequestKey,
  setRequestStatus,
  setResponseKey,
  setToken,
} from "@/functions/token.function";
import { QuestionModel } from "@/models/question/question.model";
import { feetInchToCentimeter } from "@/functions/global.function";

const service: UserService = new UserService();

export const actions: ActionTree<UserState, RootState> = {
  actionCheckUser({ state }, callback: any) {
    const userToken = getToken();

    if (userToken) {
      setRequestStatus("loading");

      RefreshKeyFromToken(userToken);

      service.serviceCheckUser().then((response: AxiosResponse) => {
        if (response.status === 200) {
          if (typeof response.data === "string") {
            // Decrypt model response with AES using ResponseKey
            const resJSON = DecryptAES(response.data, getResponseKey());
            const mResponse = new ResponseModel();

            mResponse.setObject(resJSON);
            if (mResponse.token) {
              RefreshKeyFromToken(mResponse.token);
            }

            console.log("RESPONSE AUTH/USER : ", mResponse);

            setRequestStatus("done");
            if (mResponse.status) {
              state.user_profile.setObject(mResponse.results);
              if (callback && callback.success) {
                callback.success(mResponse);
              }
            } else {
              toast.warning(mResponse.message);
              if (TOKEN_INVALID.includes(mResponse.message)) {
                deleteToken();
                router.push({ name: "signin" }, () => {
                  location.reload();
                });
              }
            }
          } else {
            setRequestStatus("done");
            const { message } = response.data;
            toast.error(message);
            if (TOKEN_INVALID.includes(message)) {
              deleteToken();
              router.push({ name: "signin" }, () => {
                location.reload();
              });
            }
          }
        } else {
          setRequestStatus("done");
          if (callback && callback.fail) {
            callback.fail();
          }
        }
      });
    } else {
      if (callback && callback.fail) {
        callback.fail();
      }
    }
  },
  actionCheckEmail({ state }, callback: any) {
    if (state.user_auth.validateEmail()) {
      const plainText = JSON.stringify({
        email: state.user_auth.email,
      });

      console.log("PAYLOAD CHECK EMAIL : ", plainText);

      // Encrypt model request with AES using RequestKey
      const cipher = EncryptAES(plainText, getRequestKey());

      // Add wrap string for param
      const params = `"${cipher}"`;

      service.serviceCheckEmail(params).then((response: AxiosResponse) => {
        if (response.status === 200) {
          if (typeof response.data === "string") {
            // Decrypt model response with AES using ResponseKey
            const resJSON = DecryptAES(response.data, getResponseKey());
            const mResponse = new ResponseModel();
            mResponse.setObject(resJSON);

            console.log("RESPONSE AUTH/SEND-OTP : ", mResponse);

            if (mResponse.status) {
              const newHS = new HandshakeModel();

              newHS.setObject(ParseHandshakeFromToken(mResponse.token));
              setRequestKey(newHS.RequestKey);
              setResponseKey(newHS.ResponseKey);
              setToken(newHS.Token);

              if (callback && callback.success) {
                callback.success(mResponse);
              }
            } else {
              toast.warning(mResponse.message);
              if (TOKEN_INVALID.includes(mResponse.message)) {
                deleteToken();
                router.push({ name: "signin" }, () => {
                  location.reload();
                });
              }
            }
          } else {
            const { message } = response.data;
            toast.error(message);
            if (TOKEN_INVALID.includes(message)) {
              deleteToken();
              router.push({ name: "signin" }, () => {
                location.reload();
              });
            }
          }
        }
      });
    }
  },
  actionLogin({ state }, callback: any) {
    if (state.user_auth.validateLogin()) {
      const plainText = JSON.stringify({
        email: state.user_auth.email,
        password: state.user_auth.password,
        fcm_token: callback.params,
      });

      console.log("PAYLOAD LOGIN : ", plainText);

      // Encrypt model request with AES using RequestKey
      const params = EncryptAES(plainText, getRequestKey());

      service.serviceLogin(params).then((response: AxiosResponse) => {
        if (response.status === 200) {
          if (typeof response.data === "string") {
            // Decrypt model response with AES using ResponseKey
            const resJSON = DecryptAES(response.data, getResponseKey());
            const mResponse = new ResponseModel();

            mResponse.setObject(resJSON);
            if (mResponse.token) {
              mResponse.status = true;
              RefreshKeyFromToken(mResponse.token);
              deleteAuthKey();
            }

            console.log("RESPONSE AUTH/LOGIN : ", mResponse);

            mResponse.results = JSON.parse(resJSON);

            if (mResponse.status) {
              state.user_auth.setObject({});
              state.user_profile.setObject(mResponse.results);
              if (callback && callback.success) {
                callback.success(mResponse);
              }
            } else {
              toast.warning(mResponse.message);
              if (TOKEN_INVALID.includes(mResponse.message)) {
                deleteToken();
                router.push({ name: "signin" }, () => {
                  location.reload();
                });
              }
              if (callback && callback.error) {
                callback.error(mResponse.message);
              }
            }
          } else {
            const { message } = response.data;
            toast.error(message);
            if (TOKEN_INVALID.includes(message)) {
              deleteToken();
              router.push({ name: "signin" }, () => {
                location.reload();
              });
            }
            if (callback && callback.error) {
              callback.error(message);
            }
          }
        }
      });
    }
  },
  actionLogout({ state }, callback: any) {
    const fcm_token = callback.params
      ? JSON.stringify([callback.params])
      : null;

    const plainText = JSON.stringify({
      fcm_token,
    });

    // Encrypt model request with AES using RequestKey
    const params = EncryptAES(plainText, getRequestKey());

    service.serviceLogout(params).then((response: AxiosResponse) => {
      if (response.status === 200) {
        localStorage.clear();
        state.user_auth.setObject({});
        state.user_profile.setObject({});

        console.log("RESPONSE AUTH/LOGOUT : ", response);

        if (callback && callback.success) {
          callback.success();
        }
      }
    });
  },
  actionVerifyOTP({ state }, callback: any) {
    const plainText = JSON.stringify({
      otp: callback.params,
      email: state.user_auth.email,
    });

    console.log("PAYLOAD AUTH/VERIFY-OTP : ", plainText);

    // Encrypt model request with AES using RequestKey
    const params = EncryptAES(plainText, getRequestKey());

    service.serviceVerifyOTP(params).then((response: AxiosResponse) => {
      if (response.status === 200) {
        if (typeof response.data === "string") {
          // Decrypt model response with AES using ResponseKey
          const resJSON = DecryptAES(response.data, getResponseKey());
          const mResponse = new ResponseModel();

          mResponse.setObject(resJSON);
          if (mResponse.token) {
            RefreshKeyFromToken(mResponse.token);
          }

          console.log("RESPONSE AUTH/VERIFY-OTP : ", mResponse);

          state.user_auth.setObject(mResponse.results);
          toast.success(i18n.t("success_verify_otp"));
          if (callback.success) {
            callback.success(mResponse);
          }
        } else {
          const { message } = response.data;
          toast.error(message);
          if (TOKEN_INVALID.includes(message)) {
            deleteToken();
            router.push({ name: "signin" }, () => {
              location.reload();
            });
          }
        }
      }
    });
  },
  actionCreatePassword({ state }, callback: any) {
    if (state.user_auth.validateCreatePassword()) {
      const plainText = JSON.stringify({
        email: state.user_auth.email,
        password: state.user_auth.password,
      });

      console.log("PAYLOAD AUTH/CREATE-PASSWORD : ", plainText);

      // Encrypt model request with AES using RequestKey
      const params = EncryptAES(plainText, getRequestKey());

      service.serviceCreatePassword(params).then((response: AxiosResponse) => {
        if (response.status === 200) {
          if (typeof response.data === "string") {
            // Decrypt model response with AES using ResponseKey
            const resJSON = DecryptAES(response.data, getResponseKey());
            const mResponse = new ResponseModel();

            mResponse.setObject(resJSON);
            if (mResponse.token) {
              RefreshKeyFromToken(mResponse.token);
              toast.success(i18n.t("success_create_password"));
            }

            console.log("RESPONSE AUTH/CREATE-PASSWORD : ", mResponse);

            state.user_auth.setObject(mResponse.results);
            if (callback.success) {
              callback.success(mResponse);
            }
          } else {
            const { message } = response.data;
            toast.error(message);
            if (TOKEN_INVALID.includes(message)) {
              deleteToken();
              router.push({ name: "signin" }, () => {
                location.reload();
              });
            }
          }
        }
      });
    }
  },
  actionCreateProfile({ state }, callback: any) {
    const { first_name, last_name, birth, gender, location } = state.user_auth;
    const plainText = JSON.stringify({
      first_name,
      last_name,
      birth,
      gender,
      location,
      state: state.user_auth.state,
      target_gender: JSON.stringify(state.user_auth.target_gender)
    });

    // Encrypt model request with AES using RequestKey
    const params = EncryptAES(plainText, getRequestKey());

    service.serviceCreateProfile(params).then((response: AxiosResponse) => {
      if (response.status === 200) {
        if (typeof response.data === "string") {
          // Decrypt model response with AES using ResponseKey
          const resJSON = DecryptAES(response.data, getResponseKey());
          const mResponse = new ResponseModel();

          mResponse.setObject(resJSON);
          if (mResponse.token) {
            RefreshKeyFromToken(mResponse.token);
          }

          console.log("RESPONSE AUTH/REGISTER/PERSONAL-INFO : ", mResponse);

          if (callback.success) {
            callback.success(mResponse);
          }
        } else {
          const { message } = response.data;
          toast.error(message);
          if (TOKEN_INVALID.includes(message)) {
            deleteToken();
            router.push({ name: "signin" }, () => {
              location.reload();
            });
          }
        }
      }
    });
  },
  actionResetPhoto({ state }, callback: any) {
    state.user_photo = new Array<UserPhotoModel>();
  },
  actionResetMainPhoto({ state }, index: number) {
    const is_main = state.user_photo[index];

    // If image selected have is_main: true and not first item
    if (is_main && index !== 0) {
      state.user_photo[0].is_main = true;
      state.user_photo.splice(index, 1);
    } else {
      if (state.user_photo.length > 1) {
        state.user_photo[1].is_main = true;
      }
      state.user_photo.splice(index, 1);
    }
  },
  actionUploadPhoto({ state }, callback: any) {
    const formData = new FormData();

    formData.append("is_main", callback.params.is_main);

    state.user_photo.forEach((item) => {
      formData.append("photo[]", item.image);
    });

    service.serviceUploadPhoto(formData).then((response: AxiosResponse) => {
      if (response) {
        if (typeof response.data === "string") {
          // Decrypt model response with AES using ResponseKey
          const resJSON = DecryptAES(response.data, getResponseKey());
          const mResponse = new ResponseModel();

          mResponse.setObject(resJSON);
          if (mResponse.token) {
            RefreshKeyFromToken(mResponse.token);
          }

          console.log("RESPONSE AUTH/REGISTER/PHOTO : ", mResponse);

          if (mResponse.status) {
            toast.success(i18n.t("success_create_profile"));
            if (callback.success) {
              callback.success(mResponse);
            }
          }
        } else {
          const { message } = response.data;
          toast.error(message);
          if (callback && callback.error) {
            callback.error(message);
          }
          if (TOKEN_INVALID.includes(message)) {
            deleteToken();
            router.push({ name: "signin" }, () => {
              location.reload();
            });
          }
        }
      }
    });
  },
  actionEditProfilePhoto({ state }, callback: any) {
    const { uploaded_photo, removed_photo, is_main } = callback.params;

    const formData = new FormData();

    if (uploaded_photo) {
      formData.append("uploaded_photo[]", uploaded_photo);
    }

    if (removed_photo) {
      formData.append("removed_id[]", removed_photo);
    }

    console.log("IS MAIN PHOTO : ", is_main);

    if (is_main) {
      formData.append("is_main", String(is_main));
    }

    service
      .serviceUpdateProfilePhoto(formData)
      .then((response: AxiosResponse) => {
        if (response && response.status == 200) {
          if (typeof response.data === "string") {
            // Decrypt model response with AES using ResponseKey
            const resJSON = DecryptAES(response.data, getResponseKey());
            const mResponse = new ResponseModel();

            mResponse.setObject(resJSON);
            if (mResponse.token) {
              RefreshKeyFromToken(mResponse.token);
            }

            console.log("RESPONSE USER/EDIT/PHOTO : ", mResponse);

            if (mResponse.status) {
              if (callback.success) {
                callback.success(mResponse);
              }
            } else {
              toast.error(mResponse.message);
            }
          } else {
            const { message } = response.data;
            toast.error(message);
            if (callback && callback.error) {
              callback.error(message);
            }
            if (TOKEN_INVALID.includes(message)) {
              deleteToken();
              router.push({ name: "signin" }, () => {
                location.reload();
              });
            }
          }
        }
      });
  },

  actionForgotPassword({ state }, callback: any) {
    const { email } = state.user_auth;

    if (!email) {
      toast.warning("Enter email address!");
      return false;
    }

    if (!mailformat.test(email)) {
      toast.warning("Invalid email address!");
      return false;
    }

    const plainText = JSON.stringify({ email });

    console.log("PAYLOAD AUTH/FORGOT-PASSWORD : ", plainText);

    // Encrypt model request with AES using RequestKey
    const params = EncryptAES(plainText, getRequestKey());

    service.serviceForgotPassword(params).then((response: AxiosResponse) => {
      if (response.status == 200) {
        if (typeof response.data === "string") {
          // Decrypt model response with AES using ResponseKey
          const resJSON = DecryptAES(response.data, getResponseKey());
          const mResponse = new ResponseModel();

          mResponse.setObject(resJSON);
          if (mResponse.token) {
            RefreshKeyFromToken(mResponse.token);
          }

          console.log("RESPONSE AUTH/FORGOT-PASSWORD : ", mResponse);

          if (callback.success) {
            callback.success(mResponse);
          }
        } else {
          const { message } = response.data;
          toast.error(message);
          if (TOKEN_INVALID.includes(message)) {
            deleteToken();
            router.push({ name: "signin" }, () => {
              location.reload();
            });
          }
        }
      }
    });
  },
  actionResetPassword({ state }, callback: any) {
    const { password } = state.user_auth;

    if (!password) {
      toast.warning("Enter email address!");
      return false;
    }

    if (!passwordChecker.test(password)) {
      toast.warning(
        "Password must be at least 8 characters long, and contains a lower-case letter, upper-case letter and number!"
      );
      return false;
    }

    const plainText = JSON.stringify({ password });

    console.log("PAYLOAD AUTH/RESET-PASSWORD : ", plainText);

    // Encrypt model request with AES using RequestKey
    const params = EncryptAES(plainText, getRequestKey());

    service.serviceResetPassword(params).then((response: AxiosResponse) => {
      if (response.status == 200) {
        if (typeof response.data === "string") {
          // Decrypt model response with AES using ResponseKey
          const resJSON = DecryptAES(response.data, getResponseKey());
          const mResponse = new ResponseModel();

          mResponse.setObject(resJSON);
          if (mResponse.token) {
            RefreshKeyFromToken(mResponse.token);
          }

          console.log("RESPONSE AUTH/RESET-PASSWORD : ", mResponse);

          if (callback.success) {
            callback.success(mResponse);
          }
        } else {
          const { message } = response.data;
          toast.error(message);
          if (TOKEN_INVALID.includes(message)) {
            deleteToken();
            router.push({ name: "signin" }, () => {
              location.reload();
            });
          }
        }
      }
    });
  },

  actionLoginSosmed({ state, commit }, callback: any) {
    const { platform } = callback.params;

    const { email, first_name, last_name, fcm_token } = callback.params;
    const plainText = JSON.stringify({
      email,
      first_name,
      last_name,
      fcm_token,
    });

    console.log(callback.params);
    // Encrypt model request with AES using RequestKey
    const params = EncryptAES(plainText, getRequestKey());

    switch (platform) {
      case "google":
        service.serviceAuthGoogle(params).then((response: AxiosResponse) => {
          if (response.status === 200) {
            if (typeof response.data === "string") {
              // Decrypt model response with AES using ResponseKey
              const resJSON = DecryptAES(response.data, getResponseKey());
              const mResponse = new ResponseModel();

              mResponse.setObject(resJSON);
              if (mResponse.token) {
                RefreshKeyFromToken(mResponse.token);
                deleteAuthKey();
              }

              console.log("RESPONSE AUTH/LOGIN/GOOGLE : ", mResponse);

              if (mResponse.status) {
                state.user_profile.setObject(mResponse.results);
              }
              if (callback.success) {
                callback.success(mResponse);
              }
            } else {
              const { message } = response.data;
              toast.error(message);
              if (TOKEN_INVALID.includes(message)) {
                deleteToken();
                router.push({ name: "signin" }, () => {
                  location.reload();
                });
              }
            }
          }
        });
        break;
      default:
        toast.warning("Platform auth not supported!");
        break;
    }
  },

  actionGetListPhoto({ state }, callback: any) {
    service.serviceListPhoto().then((response: AxiosResponse) => {
      if (response.status === 200) {
        if (typeof response.data === "string") {
          // Decrypt model response with AES using ResponseKey
          const resJSON = DecryptAES(response.data, getResponseKey());
          const mResponse = new ResponseModel();

          mResponse.setObject(resJSON);
          if (mResponse.token) {
            RefreshKeyFromToken(mResponse.token);
          }

          console.log("RESPONSE USER/LIST-PHOTO : ", mResponse);

          if (mResponse.results) {
            state.user_photo = new Array<UserPhotoModel>();
            mResponse.results.forEach((item: any) => {
              const modelPhoto = new UserPhotoModel();
              modelPhoto.setObject(item);
              state.user_photo.push(modelPhoto);
            });
          }

          if (mResponse.question) {
            state.user_questions = new Array<QuestionModel>();

            mResponse.question.forEach((question: any) => {
              const model_question = new QuestionModel();
              model_question.setObject(question);

              state.user_questions.push(model_question);
            });
          }

          if (callback && callback.success) {
            callback.success(mResponse);
          }
        } else {
          const { message } = response.data;
          toast.error(message);
          if (TOKEN_INVALID.includes(message)) {
            deleteToken();
            router.push({ name: "signin" }, () => {
              location.reload();
            });
          }
        }
      }
    });
  },
  actionEditProfile({ state }, callback: any) {
    const { first_name, last_name, about, gender, birth, location, target_gender } = state.user_profile;

    const full_name = last_name ? first_name + " " + last_name : first_name;

    const plainText = JSON.stringify({
      first_name,
      last_name,
      full_name,
      gender,
      birth,
      location,
      about,
      state: state.user_profile.state,
      target_gender: JSON.stringify(target_gender)
    });

    console.log("PAYLOAD EDIT PROFILE : ", plainText);

    // Encrypt model request with AES using RequestKey
    const params = EncryptAES(plainText, getRequestKey());

    service.serviceUpdateProfile(params).then((response: AxiosResponse) => {
      if (response.status == 200) {
        if (typeof response.data === "string") {
          // Decrypt model response with AES using ResponseKey
          const resJSON = DecryptAES(response.data, getResponseKey());
          const mResponse = new ResponseModel();

          mResponse.setObject(resJSON);
          if (mResponse.token) {
            RefreshKeyFromToken(mResponse.token);
          }
          console.log("RESPONSE EDIT PROFILE : ", plainText);

          if (callback.success) {
            callback.success(mResponse);
          }
        } else {
          const { message } = response.data;
          toast.error(message);
          if (callback.error) {
            callback.error(message);
          }
          if (TOKEN_INVALID.includes(message)) {
            deleteToken();
            router.push({ name: "signin" }, () => {
              location.reload();
            });
          }
        }
      }
    });
  },
  actionEditAdditional({ state }, callback: any) {
    const data = new UserAdditionalModel();

    const { income, salary, location } = state.user_profile;

    if (income) {
      state.user_profile.income = income.replace("$", "");
    }

    if (salary) {
      state.user_profile.salary = salary.replace("$", "");
    }

    if (location === "United States") {
      const { feet, inches } = state.user_profile;
      state.user_profile.height = String(feetInchToCentimeter(feet, inches));
    }

    data.setObject(state.user_profile);
    const plainText = JSON.stringify(data);

    console.log("PAYLOAD EDIT ADDITIONAL : ", plainText);

    // Encrypt model request with AES using RequestKey
    const params = EncryptAES(plainText, getRequestKey());

    service.serviceUpdateProfile(params).then((response: AxiosResponse) => {
      if (response.status == 200) {
        if (typeof response.data === "string") {
          // Decrypt model response with AES using ResponseKey
          const resJSON = DecryptAES(response.data, getResponseKey());
          const mResponse = new ResponseModel();

          mResponse.setObject(resJSON);
          if (mResponse.token) {
            RefreshKeyFromToken(mResponse.token);
          }
          console.log("RESPONSE EDIT ADDITIONAL : ", mResponse);

          if (callback.success) {
            callback.success(mResponse);
          }
        } else {
          const { message } = response.data;
          toast.error(message);
          if (callback.error) {
            callback.error(message);
          }
          if (TOKEN_INVALID.includes(message)) {
            deleteToken();
            router.push({ name: "signin" }, () => {
              location.reload();
            });
          }
        }
      }
    });
  },

  actionChangePassword({ state }, callback: any) {
    const { new_password, old_password } = state.user_password;

    if (state.user_password.validateChangePassword()) {
      const plainText = JSON.stringify({
        old_password,
        new_password,
      });

      // Encrypt model request with AES using RequestKey
      const params = EncryptAES(plainText, getRequestKey());

      service.serviceChangePassword(params).then((response: AxiosResponse) => {
        if (response.status === 200) {
          if (typeof response.data === "string") {
            // Decrypt model response with AES using ResponseKey
            const resJSON = DecryptAES(response.data, getResponseKey());
            const mResponse = new ResponseModel();

            mResponse.setObject(resJSON);
            if (mResponse.token) {
              RefreshKeyFromToken(mResponse.token);
            }

            toast.success("Change password successfully!");
            state.user_password = new UserPasswordModel();
            if (callback.success) {
              callback.success(mResponse);
            }
          } else {
            const { message } = response.data;
            toast.error(message);
            if (TOKEN_INVALID.includes(message)) {
              deleteToken();
              router.push({ name: "signin" }, () => {
                location.reload();
              });
            }
          }
        }
      });
    }
  },

  actionGetMembership({ state }, callback: any) {
    service.serviceSelectMembership().then((response: AxiosResponse) => {
      if (response.status === 200) {
        if (typeof response.data === "string") {
          // Decrypt model response with AES using ResponseKey
          const resJSON = DecryptAES(response.data, getResponseKey());
          const mResponse = new ResponseModel();

          mResponse.setObject(resJSON);
          if (mResponse.token) {
            RefreshKeyFromToken(mResponse.token);
          }

          console.log("RESPONSE MEMBERSHIP : ", mResponse);

          if (mResponse.status) {
            const new_model = new UserMembershipModel();

            new_model.setObject(mResponse.results);
            const { membership_id } = mResponse.results;

            if (!new_model.free_member && membership_id) {
              new_model.id = membership_id;
              state.user_profile.membership.setObject(new_model);
            } else {
              state.user_profile.membership_id = 0;
              state.user_profile.membership = new UserMembershipModel();
            }
          } else {
            state.user_profile.membership_id = 0;
            state.user_profile.membership = new UserMembershipModel();
          }

          if (callback && callback.success) {
            callback.success(mResponse);
          }
        } else {
          const { message } = response.data;
          toast.error(message);
          if (TOKEN_INVALID.includes(message)) {
            deleteToken();
            router.push({ name: "signin" }, () => {
              location.reload();
            });
          }
        }
      }
    });
  },
  actionSetMembership({ state }, callback: any) {
    let params: any = null;

    if (callback && callback.params) {
      const plainText = JSON.stringify(callback.params);
      // Encrypt model request with AES using RequestKey
      params = EncryptAES(plainText, getRequestKey());
    }

    service.serviceSetMembership(params).then((response: AxiosResponse) => {
      if (response.status === 200) {
        if (typeof response.data === "string") {
          // Decrypt model response with AES using ResponseKey
          const resJSON = DecryptAES(response.data, getResponseKey());
          const mResponse = new ResponseModel();

          mResponse.setObject(resJSON);
          if (mResponse.token) {
            RefreshKeyFromToken(mResponse.token);
          }

          if (callback.success) {
            callback.success(mResponse);
          }
        } else {
          const { message } = response.data;
          toast.error(message);
          if (TOKEN_INVALID.includes(message)) {
            deleteToken();
            router.push({ name: "signin" }, () => {
              location.reload();
            });
          }
        }
      }
    });
  },

  actionDeleteAccount({ state }, callback: any) {
    let params: any = null;
    const { password } = callback.params;

    if (!password) {
      toast.warning("Please enter your password!");
      return;
    }

    const plainText = JSON.stringify(callback.params);

    // Encrypt model request with AES using RequestKey
    params = EncryptAES(plainText, getRequestKey());

    service.serviceDeleteAccount(params).then((response: AxiosResponse) => {
      if (response.status == 200) {
        if (typeof response.data === "string") {
          // Decrypt model response with AES using ResponseKey
          const resJSON = DecryptAES(response.data, getResponseKey());
          const mResponse = new ResponseModel();

          mResponse.setObject(resJSON);
          if (mResponse.token) {
            RefreshKeyFromToken(mResponse.token);
          }

          if (mResponse.status) {
            toast.success("Delete Account successfully!");
            if (callback.success) {
              callback.success(mResponse);
            }
          }
        } else {
          const { message } = response.data;
          toast.error(message);
          if (TOKEN_INVALID.includes(message)) {
            deleteToken();
            router.push({ name: "signin" }, () => {
              location.reload();
            });
          }
          if (callback.error) {
            callback.success(message);
          }
        }
      }
    });
  },
};
