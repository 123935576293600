







































import Vue from "vue";
import Component from "vue-class-component";
import { Action, State } from "vuex-class";

import { UserModel } from "@/models/user/user.model";
import { QuestionModel } from "@/models/question/question.model";

@Component
export default class Question extends Vue {
  /* VUEX */

  /* ACTIONS */
  @Action("actionGetListQuestion", { namespace: "question" })
  private actionGetListQuestion: any;
  @Action("actionSelectDeleteAnswer", { namespace: "question" })
  private actionSelectDeleteAnswer: any;

  /* STATE */
  @State("user_profile", { namespace: "user" })
  private user_profile!: UserModel;
  @State("user_questions", { namespace: "user" })
  private user_questions!: Array<QuestionModel>;

  /* INITIALIZATION */
  private get isAddQuestion() {
    const question_limit = Number(this.user_profile.question_limit);

    if (this.user_questions.length < question_limit) {
      return true;
    }

    return false;
  }

  /* METHODS */
  private onDeleteQuestion(question: QuestionModel) {
    this.actionSelectDeleteAnswer(question);
    this.$bvModal.show("modal-delete-question");
  }

  private onShowSelectQuestion() {
    this.$bvModal.show("modal-select-question");
    this.actionGetListQuestion();
  }

  private getArrayToString(array_value: any) {
    if (array_value) {
      let array = [];

      if (typeof array_value === "string") {
        array = JSON.parse(array_value);
      } else {
        array = array_value;
      }

      return array.join(", ");
    }

    return null;
  }
}
