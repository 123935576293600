







































/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import Vue from "vue";
import { Action, State } from "vuex-class";
import Component from "vue-class-component";

import firebaseApp from "@/firebase";
import { UserModel } from "@/models/user/user.model";

@Component
export default class Chat extends Vue {
  /* VUEX */

  /* ACTION */
  @Action("appLayout")
  private appLayout: any;

  /* STATE */
  @State("user_profile", { namespace: "user" })
  private user_profile!: UserModel;

  /* INITIALIZATION */
  private snapshot: any = [];

  private get channels() {
    if (firebaseApp) {
      firebaseApp.channelsQuery.onSnapshot((snapshot: any) => {
        this.snapshot = [];
        snapshot.docs.forEach((doc: any) => {
          const isChannel = doc.data().members.includes(this.user_profile.id);
          if (isChannel) {
            this.snapshot.push({ id: doc.id, ...doc.data() });
          }
        });
      });
    }

    return this.snapshot.reverse();
  }

  private created() {
    this.appLayout();
  }
}
