/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import router from "@/router";

import aes from "aes-cross";

import { HandshakeModel } from "@/models/handshake/handshake.model";
import {
  deleteAuthKey,
  deleteRequestKey,
  deleteResponseKey,
  getToken,
  setRequestKey,
  setResponseKey,
  setToken,
} from "./token.function";

export function EncryptAES(plaintext: any, iv: any) {
  try {
    const key = Array.prototype.slice.call(Buffer.from(iv, "utf8"), 0);
    aes.setKeySize(256);
    return aes.encText(plaintext, key);
  } catch (error) {
    deleteRequestKey();
    deleteResponseKey();
    deleteAuthKey();
    router.push("/");
    return false;
  }
}

export function DecryptAES(cipher: any, iv: any) {
  try {
    const key = Array.prototype.slice.call(Buffer.from(iv, "utf8"), 0);
    aes.setKeySize(256);
    return aes.decText(cipher, key);
  } catch (error) {
    deleteRequestKey();
    deleteResponseKey();
    deleteAuthKey();
    router.push("/");
    return false;
  }
}

export function TokenParse(token: any) {
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
}

export function ParseHandshakeFromToken(token: any) {
  const tokenPayload = TokenParse(token);
  return JSON.stringify({
    RequestKey: tokenPayload.rqk,
    ResponseKey: tokenPayload.rpk,
    Token: token,
  });
}

export function RefreshKeyFromToken(token?: string) {
  let tokenVal;
  if (!token) {
    tokenVal = getToken();
  } else {
    tokenVal = token;
  }
  const modelHandshake = new HandshakeModel();
  modelHandshake.setObject(ParseHandshakeFromToken(tokenVal));

  setRequestKey(modelHandshake.RequestKey);
  setResponseKey(modelHandshake.ResponseKey);
  setToken(modelHandshake.Token);
}
