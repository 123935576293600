
















































































































import Vue from "vue";
import $ from "jquery";
import Component from "vue-class-component";
import { Action, Getter, State } from "vuex-class";
import { UserModel } from "@/models/user/user.model";
import { ChannelModel } from "@/models/chat/chat.model";
import { Watch } from "vue-property-decorator";

@Component({
  components: {
    // Message: () => import("@/components/modal/CustomenSupportMessage.vue")
    Message: () => import("@/components/modal/Message.vue")
  }
})
export default class FAQ extends Vue {
  /* VUEX */

  /* ACTIONS */
  @Action("appLayout")
  private appLayout: any;
  @Action("actionGetFAQList", { namespace: "misc" })
  private actionGetFAQList: any;
  @Action("actionSearchFAQ", { namespace: "misc" })
  private actionSearchFAQ: any;
  @Action("actionListChannel", { namespace: "chat" })
  private actionListChannel: any;
  @Action("actionCreateChannel", { namespace: "chat" })
  private actionCreateChannel: any;

  @State("user_profile", { namespace: "user" })
  private user_profile!: UserModel;
  @State("array_channel_cs", { namespace: "chat" })
  private array_channel_cs!: any[];
  @State("array_message", { namespace: "chat" })
  private array_message!: any[];
  @State("channel", { namespace: "chat" })
  private channel!: ChannelModel;

  @Getter("isLoggedIn", { namespace: "user" })
  private isLoggedIn!: boolean;

  /* STATE */
  @State("array_faq", { namespace: "misc" })
  private array_faq!: Array<any>;
  @State("array_faq_filter", { namespace: "misc" })
  private array_faq_filter!: Array<any>;
  @State("faq_search", { namespace: "misc" })
  private faq_search!: boolean;

  /* INITIALIZATION */
  private faqLoaded = false;
  private helpServiceLoaded = false;
  private timer: any = null;
  private waitTime = 500;
  onGoingChat = 0

  @Watch("array_channel_cs", {deep: true, immediate: true}) onChangeChannelCS() {
    this.onGoingChat = this.array_channel_cs.filter(v => v.is_ended === false).length || 0
  }

  private created() {
    this.appLayout();
    this.actionGetFAQList();
  }

  private mounted() {
    $(document).ready(function () {
      const condition = $("#collapseOne").hasClass("show");
      if (!condition) {
        $(".collapseOne").click();
      }
    });
    setTimeout(this.getChannel, 1000);
  }

  /* METHODS */
  getChannel() {
    if (this.isLoggedIn) {
      this.actionListChannel({
        params: this.user_profile.id,
        to: 'admin',
        success: () => {
          this.onGoingChat = this.array_channel_cs.filter(v => v.is_ended === false).length || 0
        }
      });
    }
  }

  private onSearchFAQ(e: any) {
    const { value } = e.target;

    clearTimeout(this.timer);

    this.timer = setTimeout(() => {
      this.actionSearchFAQ({ params: value });
    }, this.waitTime);
  }

  private loadFaq() {
    if (!this.faqLoaded) {
      this.faqLoaded = true;
      $(".faq-wrapper .faq-title").on("click", function () {
        var element = $(this).parent(".faq-item");
        if (element.hasClass("open")) {
          element.removeClass("open");
          element.find(".faq-content").removeClass("open");
          element.find(".faq-content").slideUp(300, "swing");
        } else {
          element.addClass("open");
          element.children(".faq-content").slideDown(300, "swing");
          element
            .siblings(".faq-item")
            .children(".faq-content")
            .slideUp(300, "swing");
          element.siblings(".faq-item").removeClass("open");
          element
            .siblings(".faq-item")
            .find(".faq-title, .faq-title-two")
            .removeClass("open");
          element
            .siblings(".faq-item")
            .find(".faq-content")
            .slideUp(300, "swing");
        }
      });
    }
  }

  onClickViewChat() {
    this.helpServiceLoaded = true
    if (this.isLoggedIn) {
      let channel_data = new ChannelModel();
      channel_data = new ChannelModel();
      channel_data.user_from_id = this.user_profile.id;
      channel_data.user_from.setObject(this.user_profile);
      channel_data.user_to_id = ('admin' as any);

      let admin = new UserModel()
      admin.id = ('admin' as any);
      admin.username = "customer_support";
      admin.email = "cs@smixy.com";
      admin.first_name = "smixy";
      admin.last_name = "customer support";
      admin.full_name = "smixy customer support";

      channel_data.user_to.setObject(admin);
      channel_data.is_ended = false

      this.actionCreateChannel({
        params: channel_data,
        success: () => {
          this.actionListChannel({ params: this.user_profile.id, to: 'admin' });
          this.helpServiceLoaded = false
          this.$bvModal.hide("lds-loader");
          this.$bvModal.show("usermessage");
        },
        error: () => {
          this.helpServiceLoaded = false
          this.$bvModal.hide("lds-loader");
        },
      });
    }
  }

  OnChatEnded() {
    setTimeout(this.getChannel, 1000);
  }
}
