


















































import Vue from "vue";
import Component from "vue-class-component";

@Component
export default class StartFlirting extends Vue {
  /* VUEX */
  /* ACTIONS */
  /* STATE */
  /* INITIALIZATION */
  /* METHODS */
}
