import { AxiosResponse } from "axios";
import { Base } from "./base/base.service";
import { getToken } from "@/functions/token.function";

export default class MiscService {
  public serviceFilterList(): Promise<AxiosResponse<any>> {
    return Base().get("filter/list", {
      headers: {
        "Content-Type": "text/plain",
        Authorization: `Bearer ${getToken()}`,
      },
    });
  }
  public serviceFAQList(): Promise<AxiosResponse<any>> {
    return Base().get("misc/faq", {
      headers: {
        "Content-Type": "text/plain",
        Authorization: `Bearer ${getToken()}`,
      },
    });
  }
}
