import { BaseModel } from "../base/base.model";
import {
  UserAdditionalType,
  UserMembershipType,
  UserPasswordType,
  UserPhotoType,
  UserType,
} from "./user.type";
import { mailformat, passwordChecker } from "@/constants/global.constant";

import toast from "@/functions/toast.function";

export class UserModel extends BaseModel implements UserType {
  public id = 0;
  public username = "";
  public email = "";
  public password = "";
  public confirm_password = "";
  public first_name = "";
  public last_name = "";
  public full_name = "";
  public birth = "";
  public gender = "";
  public location: any = null;
  public state: any = null;
  public age = 0;
  public about = "";
  public occupation = "";
  public membership = new UserMembershipModel();
  public membership_id = 0;
  public photo = "";
  public total_favourite = 0;
  public work = "";
  public income = "";
  public income_format = "";
  public salary = "";
  public salary_format = "";
  public looking_for = "";
  public height = "";
  public feet_height = "";
  public feet = "";
  public inches = "";
  public hair_color = "";
  public eye_color = "";
  public wear_glasses = "";
  public ethnic = "";
  public lang_spoken = null;
  public question_limit: any = null;
  public target_gender = [];

  private AttributeCast() {
    if (typeof this.target_gender === 'string' && (this.target_gender as string).length > 0) {
      console.log(this)
      this.target_gender = JSON.parse(this.target_gender)
      if(typeof this.target_gender === 'string') this.target_gender = [this.target_gender]
    }
  }

  public validateEmail(): boolean {
    if (!this.email) {
      toast.warning("Enter email address!");
      return false;
    }
    if (!mailformat.test(this.email)) {
      toast.warning("Invalid email address!");
      return false;
    }
    return true;
  }

  public validateLogin(): boolean {
    this.validateEmail();

    if (!this.password) {
      toast.warning("Enter password!");
      return false;
    }
    return true;
  }

  public validateCreatePassword(): boolean {
    if (!this.password) {
      toast.warning("Enter password!");
      return false;
    }

    if (!passwordChecker.test(this.password)) {
      toast.warning(
        "Password must be at least 8 characters long, and contains a lower-case letter, upper-case letter and number!"
      );
      return false;
    }

    if (this.password !== this.confirm_password) {
      toast.warning("Please make sure your passwords match");
      return false;
    }

    return true;
  }

  public validateCreateProfile(): boolean {
    if (!this.first_name) {
      toast.warning("Enter first name!");
      return false;
    }

    if (this.first_name.length < 3) {
      toast.warning("Your first/last name must be at least 2 characters long!");
      return false;
    }

    if (this.last_name && this.last_name.length < 3) {
      toast.warning("Your first/last name must be at least 2 characters long!");
      return false;
    }

    if (!this.gender) {
      toast.warning("Please choose gender!");
      return false;
    }

    if (!this.birth) {
      toast.warning("Please choose birthday!");
      return false;
    }

    if (!this.location) {
      toast.warning("Please choose country!");
      return false;
    }

    if (!this.state) {
      toast.warning("Please choose state!");
      return false;
    }

    if (this.target_gender instanceof Array) {
      if (this.target_gender.length == 0) {
        toast.warning("Please choose state!");
        return false;
      }
    } else {
      toast.warning("Please choose state!");
      return false;
    }

    return true;
  }
}

export class UserPasswordModel implements UserPasswordType {
  public old_password = "";
  public new_password = "";
  public confirm_password = "";

  public validateChangePassword(): boolean {
    if (!this.old_password) {
      toast.warning("Enter old password!");
      return false;
    }

    if (!this.new_password) {
      toast.warning("Enter new password!");
      return false;
    }

    if (!passwordChecker.test(this.new_password)) {
      toast.warning(
        "Password must be at least 8 characters long, and contains a lower-case letter, upper-case letter and number!"
      );
      return false;
    }

    if (this.new_password === this.old_password) {
      toast.warning(
        "The new password cannot be the same as the previous password!"
      );
      return false;
    }

    if (this.new_password !== this.confirm_password) {
      toast.warning("Please make sure your passwords match");
      return false;
    }

    return true;
  }
}

export class UserMembershipModel
  extends BaseModel
  implements UserMembershipType
{
  public id = 0;
  public free_member = false;
  public percentase = 0;
  public day_left = 0;
  public total_days = 0;
  public billing_date = "";
  public expire_date = "";
}

export class UserPhotoModel extends BaseModel implements UserPhotoType {
  public id = 0;
  public user_id = 0;
  public image: any = null;
  public path = "";
  public photo = "";
  public is_main = false;
  public is_save = false;
  public approved = false;
  public rejected = false;
}

export class UserAdditionalModel
  extends BaseModel
  implements UserAdditionalType
{
  public work = "";
  public occupation = "";
  public income = "";
  public salary = "";
  public looking_for = "";
  public height = "";
  public hair_color = "";
  public eye_color = "";
  public wear_glasses = "";
  public ethnic = "";
  public lang_spoken = new Array<string>();
}
