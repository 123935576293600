import { Module } from "vuex";
import { RootState } from "../types";

import { actions } from "./actions";
import { SeekerState } from "./types";
import { createPagination } from "@/functions/global.function";

import {
  FilterSeekerModel,
  SeekerModel,
  SeekerPhotoModel,
} from "@/models/seeker/seeker.model";
import { QuestionModel } from "@/models/question/question.model";

export const state: SeekerState = {
  filter_explore: new FilterSeekerModel(),
  array_explore: new Array<SeekerModel>(),
  pagination_explore: createPagination({}),
  array_favorite: new Array<SeekerModel>(),
  pagination_favorite: createPagination({}),
  seeker_detail: new SeekerModel(),
  seeker_detail_question: new Array<QuestionModel>(),
  seeker_photo: new Array<SeekerPhotoModel>(),

  pagination_temp: {},

  favorite_list: new Array<SeekerModel>(),
  vip_list: new Array<SeekerModel>(),
};

const namespaced = true;

export const seeker: Module<SeekerState, RootState> = {
  namespaced,
  state,
  actions,
};
