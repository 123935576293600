/* ApiBase - START */
/* Version : 1.0:1. */

// Staging
// export const BASE_API = "http://206.189.84.212/api/v1/";
// export const ASSETS = "http://206.189.84.212/";

// Production
export const BASE_API = "https://api.smixy.com/api/v1/";
export const ASSETS = "https://api.smixy.com/";

// Local
// export const BASE_API = "http://api.smixy.local/api/v1/";
// export const ASSETS = "http://api.smixy.local/";

// Firebase FCM
export const BASE_FCM = "https://fcm.googleapis.com/";
