

































































































































































import Vue from "vue";
import $ from "jquery";
import Component from "vue-class-component";
import { Action, State } from "vuex-class";

import { UserModel } from "@/models/user/user.model";
import { Watch } from "vue-property-decorator";

@Component
export default class Profile extends Vue {
  /* VUEX */

  /* ACTIONS */
  @Action("appLayout")
  private appLayout: any;
  @Action("actionListMembership", { namespace: "payment" })
  private actionListMembership: any;
  @Action("actionGetFilterList", { namespace: "misc" })
  private actionGetFilterList: any;
  @Action("actionGetListPhoto", { namespace: "user" })
  private actionGetListPhoto: any;
  @Action("actionMembershipHistory", { namespace: "payment" })
  private actionMembershipHistory: any;
  @Action("actionGetListQuestion", { namespace: "question" })
  private actionGetListQuestion: any;

  /* STATE */
  @State("user_profile", { namespace: "user" })
  private user_profile!: UserModel;
  @State("array_eye_color", { namespace: "misc" })
  private array_eye_color!: Array<any>;

  @Watch("user_profile.id", { deep: true, immediate: true })
  private onUserProfileChanged(val: number) {
    if (val) {
      const { name } = this.$route;
      switch (name) {
        case "profile":
          this.actionGetListPhoto({
            success: () => {
              this.actionGetFilterList({
                success: () => {
                  this.actionGetListQuestion();
                },
              });
            },
          });
          return;
        case "membership":
          this.actionListMembership();
          return;
        case "purchase-history":
          this.actionMembershipHistory();
          return;
        default:
          // Todo
          return;
      }
    }
  }

  /* INITIALIZATION */
  private created() {
    this.appLayout();
  }

  private mounted() {
    this.scrollToTop();
  }

  /* METHODS */
  private checkActive(menu: string[]): boolean {
    const { name } = this.$route;

    if (menu) {
      return menu.includes(String(name));
    }
    return false;
  }

  private clickMenu(menu: string) {
    const { name } = this.$route;

    if (name !== menu) {
      this.scrollToTop();
      if (menu === "membership") {
        this.actionListMembership();
      } else if (menu === "purchase-history") {
        this.actionMembershipHistory();
      }
      this.$router.push({ name: menu });
    }
  }

  private scrollToTop() {
    $("html,body").animate({ scrollTop: 0 }, 800);
  }
}
