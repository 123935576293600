import { AxiosResponse } from "axios";
import { BaseAuth } from "./base/base.service";
import { getAuthKey } from "@/functions/token.function";

/* HandshakeService - START */
// Description : HandshakeService class of service for handling user information.
// Author : Risman Abdilah.
// Created on : Mon, 25 Oct 2021.      Updated on : Mon, 25 Oct 2021.
// Created by : Risman Abdilah.        Updated by : Risman Abdilah.
/* Version : 1.0.0 */

export default class HandshakeService {
  public generateToken(params: any): Promise<AxiosResponse<any>> {
    return BaseAuth().post("pair", params, {
      headers: { "Content-Type": "text/plain" },
    });
  }

  public refreshToken(): Promise<AxiosResponse<any>> {
    return BaseAuth().get("refresh", {
      headers: {
        "Content-Type": "text/plain",
        Authorization: `Bearer ${getAuthKey()}`,
      },
    });
  }
}

/* HandshakeService - END */
