import { BaseModel } from "../base/base.model";
import {
  QuestionAnswerType,
  QuestionChoiceType,
  QuestionType,
} from "./question.type";

export class QuestionModel extends BaseModel implements QuestionType {
  public id = 0;
  public question = "";
  public type = 0;
  public choice = new Array<QuestionChoiceModel>();
  public answer = new Array<string>();
  public answer_id = 0;
  public answer_limit = 0;
}

export class QuestionAnswerModel
  extends BaseModel
  implements QuestionAnswerType
{
  public id = 0;
  public choice = new Array<string>();
}

export class QuestionChoiceModel
  extends BaseModel
  implements QuestionChoiceType
{
  public id = 0;
  public text = "";
  public question_id = 0;
  public choice_index = 0;
  public is_selected = false;
}
