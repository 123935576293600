









































import Vue from "vue";
import $ from "jquery";
import Component from "vue-class-component";

@Component
export default class AuthLayout extends Vue {
  /* VUEX */

  /* ACTIONS */

  /* STATE */

  /* INITIALIZATION */
  private mounted() {
    $(".preloader").hide();
  }

  /* METHODS */
}
