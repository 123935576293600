



































































































































































import Vue from "vue";
import Component from "vue-class-component";
import { Action, Mutation, State } from "vuex-class";
import { Watch } from "vue-property-decorator";

import { ASSETS } from "@/services/base/apibase";
import { data2blob } from "@/functions/global.function";
import { LocationModel } from "@/models/region/region.model";
import { UserModel, UserPhotoModel } from "@/models/user/user.model";

import toast from "@/functions/toast.function";
import {TARGET_GENDER_OPTIONS} from "@/constants/enum.constant";

import ModalDeletePhoto from "@/components/modal/DeletePhoto.vue";
import ModalSetMainPhoto from "@/components/modal/SetMainPhoto.vue";
import ModalSelectQuestion from "@/components/modal/SelectQuestion.vue";
import ModalDeleteQuestion from "@/components/modal/DeleteQuestion.vue";
import ModalAnswerQuestion from "@/components/modal/AnswerQuestion.vue";
import InformationAdditional from "@/components/profile/information/Additional.vue";
import InformationQuestion from "@/components/profile/information/Question.vue";

@Component({
  components: {
    ModalDeletePhoto,
    ModalSetMainPhoto,
    ModalSelectQuestion,
    ModalDeleteQuestion,
    ModalAnswerQuestion,
    InformationAdditional,
    InformationQuestion,
  },
})
export default class Information extends Vue {
  /* VUEX */

  /* ACTIONS */
  @Action("actionGetState", { namespace: "region" })
  private actionGetState: any;
  @Action("actionEditProfile", { namespace: "user" })
  private actionEditProfile: any;
  @Action("actionEditAdditional", { namespace: "user" })
  private actionEditAdditional: any;
  @Action("actionEditProfilePhoto", { namespace: "user" })
  private actionEditProfilePhoto: any;
  @Action("actionGetListPhoto", { namespace: "user" })
  private actionGetListPhoto: any;
  @Action("actionResetState", { namespace: "region" })
  private actionResetState: any;
  @Action("actionCheckUser", { namespace: "user" })
  private actionCheckUser: any;
  @Action("actionGetMembership", { namespace: "user" })
  private actionGetMembership: any;

  /* STATE */
  @State("array_location", { namespace: "misc" })
  private array_location!: Array<LocationModel>;
  @State("array_state", { namespace: "region" })
  private array_state!: Array<LocationModel>;
  @State("user_profile", { namespace: "user" })
  private user_profile!: UserModel;
  @State("user_photo", { namespace: "user" })
  private user_photo!: Array<UserPhotoModel>;

  @Mutation("mutationUserDetailPhoto", { namespace: "user" })
  private mutationUserDetailPhoto: any;

  @Watch("array_location", { deep: true, immediate: true })
  private onUserProfileChanged(val: Array<any>) {
    if (val.length > 0) {
      this._getCurrentLocationAndState();
    }
  }

  /* INITIALIZATION */
  private location: any = null;
  private state: any = null;
  private showUploadImage = false;
  private targetGenderOptions = TARGET_GENDER_OPTIONS

  private get isOver18() {
    const date18YrsAgo = new Date();
    date18YrsAgo.setFullYear(date18YrsAgo.getFullYear() - 18);

    let dateYear: any = date18YrsAgo.getFullYear();
    let dateMonth: any = date18YrsAgo.getMonth() + 1;
    let dateDay: any = date18YrsAgo.getDate();

    if (dateMonth < 10) {
      dateMonth = "0" + dateMonth.toString();
    }
    if (dateDay < 10) {
      dateDay = "0" + dateDay.toString();
    }
    const maxDate = dateYear + "-" + dateMonth + "-" + dateDay;

    return maxDate;
  }

  /* METHODS */
  private onEditProfile() {
    this.$bvModal.show("lds-loader");
    this.actionEditProfile({
      success: () => {
        this.actionEditAdditional({
          success: () => {
            toast.success("Update profile information successfully!");
            this.$bvModal.hide("lds-loader");
          },
          error: () => {
            this.$bvModal.hide("lds-loader");
          },
        });
      },
      error: () => {
        this.$bvModal.hide("lds-loader");
      },
    });
  }

  private onChangeImage(imageDataUrl: any) {
    const elImageCropUpload = this.$refs.imageCropUpload as any;
    const blobFile = data2blob(imageDataUrl, elImageCropUpload.mime as string);
    const filename = "seeker-" + Date.now().toString();
    const new_image: any = new File(
      [blobFile],
      filename + "." + elImageCropUpload.imgFormat
    );

    const params: any = Object.assign({}, { uploaded_photo: new_image });

    this.actionEditProfilePhoto({
      params,
      success: () => {
        toast.success(this.$i18n.t("success_edit_profile_photo"));
        if (this.user_photo.length < 2) {
          this.actionGetListPhoto({
            success: () => {
              this.onSetMainPhoto(this.user_photo[0].id);
            },
          });
        } else {
          this.actionGetListPhoto();
        }
        this.showUploadImage = false;
        elImageCropUpload.setStep(1);
      },
      error: () => {
        this.showUploadImage = false;
        elImageCropUpload.setStep(1);
      },
    });
  }

  private _getCurrentLocationAndState() {
    if (this.user_profile.location) {
      let currLocModel = new LocationModel();
      const userLocation = this.array_location.find(
        (loc) => loc.name === this.user_profile.location
      );

      if (!userLocation) {
        return;
      }

      this.location = userLocation;
      currLocModel.setObject(userLocation);
      this.actionGetState({
        params: currLocModel.id,
        success: () => {
          if (this.user_profile.state) {
            const userState = this.array_state.find(
              (loc) => loc.name === this.user_profile.state
            );

            this.state = userState;
          }
        },
      });
    }
  }

  private onSelectLocation(item: LocationModel) {
    if (item) {
      this.user_profile.location = item.name;
      this.actionGetState({
        params: item.id,
        success: () => {
          this.state = null;
          this.user_profile.state = null;
        },
      });
    } else {
      this.onClearLocation();
    }
  }

  private onClearLocation() {
    this.location = null;
    this.user_profile.location = null;
    this.state = null;
    this.user_profile.state = null;
    this.actionResetState();
  }

  private onSelectState(item: LocationModel) {
    this.user_profile.state = item.name;
  }

  private onShowModalSetMain(item: UserPhotoModel) {
    if (!item.is_main) {
      this.mutationUserDetailPhoto(item);
      this.$bvModal.show("modal-main-photo");
    }
  }

  private onShowModalDelete(item: UserPhotoModel) {
    /**
     * Check the photo
     * if not rejected, show confirmation
     * else
     * delete without confirmation
     */
    if (!item.rejected) {
      this.mutationUserDetailPhoto(item);
      this.$bvModal.show("modal-delete-photo");
    } else {
      /**
       * Create params object
       * if image is main, set new is_main
       */
      let params: any = Object.assign({}, { removed_photo: item.id });

      if (item.is_main) {
        const filterUser = this.user_photo.filter((val) => val.id !== item.id);

        if (filterUser.length > 0) {
          params.is_main = filterUser[0].id;
        } else {
          params.is_main = 1;
        }
      }

      this.actionEditProfilePhoto({
        params,
        success: () => {
          if (item.is_main) {
            this.actionCheckUser({
              success: () => {
                this.actionGetMembership();
                this.actionGetListPhoto();
                this.mutationUserDetailPhoto({});
                toast.success(this.$i18n.t("success_delete_profile_photo"));
              },
            });
          } else {
            this.actionGetListPhoto();
            this.mutationUserDetailPhoto({});
            toast.success(this.$i18n.t("success_delete_profile_photo"));
          }
        },
      });
    }
  }

  private onSetMainPhoto(id: number) {
    this.actionEditProfilePhoto({
      params: { is_main: id },
      success: () => {
        this.actionCheckUser({
          success: () => {
            this.actionGetMembership();
            this.actionGetListPhoto();
          },
        });
      },
    });
  }

  private userPhoto(filename: string) {
    if (filename) {
      return ASSETS + "user/photo/" + filename;
    }
    return "";
  }

  private clickUpload() {
    const elImageCropUpload = this.$refs.imageCropUpload as any;
    elImageCropUpload.setStep(1);
    this.showUploadImage = true;
  }

  private getFocus(id: string) {
    document.getElementById(id)?.focus();
  }
}
