import { AxiosResponse } from "axios";
import { Base } from "./base/base.service";
import { getToken } from "@/functions/token.function";

export default class SeekerService {
  public serviceExplore(params: any): Promise<AxiosResponse<any>> {
    return Base().post("explore/seeker", params, {
      headers: {
        "Content-Type": "text/plain",
        Authorization: `Bearer ${getToken()}`,
      },
    });
  }
  public serviceDetailSeeker(params: any): Promise<AxiosResponse<any>> {
    return Base().post("explore/user/detail", params, {
      headers: {
        "Content-Type": "text/plain",
        Authorization: `Bearer ${getToken()}`,
      },
    });
  }
  public serviceDetailPhoto(params: any): Promise<AxiosResponse<any>> {
    return Base().post("explore/user/other-foto", params, {
      headers: {
        "Content-Type": "text/plain",
        Authorization: `Bearer ${getToken()}`,
      },
    });
  }
  public serviceFavorite(params: any): Promise<AxiosResponse<any>> {
    return Base().get("favourite", {
      params,
      headers: {
        "Content-Type": "text/plain",
        Authorization: `Bearer ${getToken()}`,
      },
    });
  }
  public serviceAddFavorite(params: any): Promise<AxiosResponse<any>> {
    return Base().post("favourite", params, {
      headers: {
        "Content-Type": "text/plain",
        Authorization: `Bearer ${getToken()}`,
      },
    });
  }
  public serviceRemoveFavorite(params: any): Promise<AxiosResponse<any>> {
    return Base().post("favourite/remove", params, {
      headers: {
        "Content-Type": "text/plain",
        Authorization: `Bearer ${getToken()}`,
      },
    });
  }
  public serviceReportSeeker(params: any): Promise<AxiosResponse<any>> {
    return Base().post("explore/user/report-user", params, {
      headers: {
        "Content-Type": "text/plain",
        Authorization: `Bearer ${getToken()}`,
      },
    });
  }
  public serviceBlockSeeker(params: any): Promise<AxiosResponse<any>> {
    return Base().post("explore/user/block-user", params, {
      headers: {
        "Content-Type": "text/plain",
        Authorization: `Bearer ${getToken()}`,
      },
    });
  }
}
