








































import Vue from "vue";
import Component from "vue-class-component";

@Component
export default class ModalNoSubscribe extends Vue {
  /* VUEX */
  /* ACTIONS */
  /* STATE */
  /* INITIALIZATION */
  /* METHODS */
  private hideModal() {
    this.$router.push({ name: "membership" });
    this.$bvModal.hide("modal-no-subscribe");
  }
}
