
























































import Vue from "vue";
import $ from "jquery";
import filters from "@/filters";
import Component from "vue-class-component";
import { Action, State } from "vuex-class";

import { InvoiceModel } from "@/models/payment/payment.model";
import { PaginationType } from "@/store/types";

@Component({
  filters,
})
export default class PurchaseHistory extends Vue {
  /* VUEX */

  /* ACTIONS */
  @Action("actionMembershipHistory", { namespace: "payment" })
  private actionMembershipHistory: any;

  /* STATE */
  @State("array_history", { namespace: "payment" })
  private array_history!: Array<InvoiceModel>;
  @State("pagination_history", { namespace: "payment" })
  private pagination_history!: PaginationType;

  /* INITIALIZATION */
  private mounted() {
    $(document).ready(function () {
      const condition = $("#collapseThree").hasClass("show");
      if (!condition) {
        $(".collapseThree").click();
      }
    });
  }

  /* METHODS */
  private onClickPage(page: number) {
    if (page !== this.pagination_history.page_current) {
      this.actionMembershipHistory({
        filter: { page },
        success: () => {
          // Todo
        },
      });
    }
  }
}
