
























































import Vue from "vue";
import $ from "jquery";
import Component from "vue-class-component";

import NavbarComponent from "@/components/Navbar.vue";
import FooterComponent from "@/components/Footer.vue";
import ModalNoSubscribe from "@/components/modal/NoSubscribe.vue";
import PWAPrompt from "@/components/pwa/PWAPrompt.vue";

@Component({
  components: {
    NavbarComponent,
    FooterComponent,
    ModalNoSubscribe,
    "pwa-prompt": PWAPrompt,
  },
})
export default class AppLayout extends Vue {
  /* VUEX */

  /* ACTIONS */

  /* STATE */

  /* INITIALIZATION */

  private mounted() {
    $(".preloader").hide();
  }

  /* METHODS */
}
