import { Module } from "vuex";
import { RootState } from "../types";

import { actions } from "./actions";
import { mutations } from "./mutations";

import { PaymentState } from "./types";
import { createPagination } from "@/functions/global.function";

import {
  InvoiceModel,
  MembershipModel,
  PaymentIntentModel,
  PaymentMethodModel,
} from "@/models/payment/payment.model";

export const state: PaymentState = {
  array_membership: new Array<MembershipModel>(),
  array_history: new Array<InvoiceModel>(),
  pagination_history: createPagination({}),
  payment_method: new PaymentMethodModel(),
  payment_intent: new PaymentIntentModel(),
  payment_tazapey: null,
};

const namespaced = true;

export const payment: Module<PaymentState, RootState> = {
  namespaced,
  state,
  actions,
  mutations,
};
