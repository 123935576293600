/* Token Function - START */
// Description : Token Function is handler get & set data storage.
// Author : Risman Abdilah.
// Created on : Mon, 25 Oct 2021.      Updated on : Mon, 25 Oct 2021.
// Created by : Risman Abdilah.        Updated by : Risman Abdilah.
/* Version : 1.0.0 */

const token_key = "token";
const request_key = "RequestKey";
const response_key = "ResponseKey";
const auth_key = "UUIDKey";
const request_status_key = "request_status";

export function getToken() {
  const token = localStorage.getItem(token_key);

  if (token) {
    return token;
  }
  return null;
}

export function setToken(token: any) {
  if (token) {
    localStorage.setItem(token_key, (token as string).trim());
  }
}

export function deleteToken() {
  localStorage.clear();
}

export function getRequestKey() {
  const request = localStorage.getItem(request_key);

  if (request) {
    return request;
  }
  return null;
}

export function setRequestKey(data: any) {
  localStorage.setItem(request_key, data);
}

export function deleteRequestKey() {
  localStorage.removeItem(request_key);
}

export function getResponseKey() {
  const response = localStorage.getItem(response_key);

  if (response) {
    return response;
  }
  return null;
}

export function setResponseKey(data: any) {
  localStorage.setItem(response_key, data);
}

export function deleteResponseKey() {
  localStorage.removeItem(response_key);
}

export function getAuthKey() {
  const auth = localStorage.getItem(auth_key);

  if (auth) {
    return auth;
  }
  return null;
}

export function setAuthKey(data: any) {
  localStorage.setItem(auth_key, data);
}

export function deleteAuthKey() {
  localStorage.removeItem(auth_key);
}

export function getRequestStatus() {
  const status = localStorage.getItem(request_status_key);

  if (status) {
    return status;
  }
  return null;
}

export function setRequestStatus(data: any) {
  localStorage.setItem(request_status_key, data);
}
