import { AxiosResponse } from "axios";
import { Base } from "./base/base.service";
import { getToken } from "@/functions/token.function";

export default class RegionService {
  public serviceLocation(): Promise<AxiosResponse<any>> {
    return Base().get("filter/list-location", {
      headers: {
        "Content-Type": "text/plain",
        Authorization: `Bearer ${getToken()}`,
      },
    });
  }
  public serviceState(locationId: any): Promise<AxiosResponse<any>> {
    if (locationId) {
      return Base().get(`filter/list-location/${locationId}`, {
        headers: {
          "Content-Type": "text/plain",
          Authorization: `Bearer ${getToken()}`,
        },
      });
    }
    return Base().get("filter/list-location-state/", {
      headers: {
        "Content-Type": "text/plain",
        Authorization: `Bearer ${getToken()}`,
      },
    });
  }
}
