




























































import Vue from "vue";
import $ from "jquery";
import Component from "vue-class-component";
import { Action, State } from "vuex-class";

import { UserPasswordModel } from "@/models/user/user.model";

@Component
export default class ChangePassword extends Vue {
  /* VUEX */

  /* ACTIONS */
  @Action("actionChangePassword", { namespace: "user" })
  private actionChangePassword: any;

  /* STATE */
  @State("user_password", { namespace: "user" })
  private user_password!: UserPasswordModel;

  /* INITIALIZATION */
  private mounted() {
    $(document).ready(function () {
      const condition = $("#collapseTwo").hasClass("show");
      if (!condition) {
        $(".collapseTwo").click();
      }
    });
  }

  /* METHODS */
  private getFocus(id: string) {
    document.getElementById(id)?.focus();
  }
}
