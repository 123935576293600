import i18n from "@/i18n";

export enum PaymentStatus {
  requires_confirmation = "requires_confirmation",
  requires_action = "requires_action",
  succeeded = "succeeded",
}

export enum QuestionType {
  SINGLE = 1,
  SINGLE_WRITE = 2,
  MULTIPLE = 3,
}

export const TOKEN_INVALID = [
  "Invalid token",
  "Token expired",
  "handshake not found",
  "User not logged in",
  "HANDSHAKE NOT FOUND",
];

export const TARGET_GENDER_OPTIONS = [
  {text: i18n.t("man"), value: "Male"},
  {text: i18n.t("woman"), value: "Female"}
]