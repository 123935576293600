






















































































































































import Vue from "vue";
import filters from "@/filters";
import Component from "vue-class-component";
import { State } from "vuex-class";

import { UserModel } from "@/models/user/user.model";
import { QuestionModel } from "@/models/question/question.model";

@Component({
  filters,
})
export default class ProfileDetail extends Vue {
  /* VUEX */

  /* ACTIONS */

  /* STATE */
  @State("user_profile", { namespace: "user" })
  private user_profile!: UserModel;
  @State("user_questions", { namespace: "user" })
  private user_questions!: Array<QuestionModel>;

  /* INITIALIZATION */

  /* METHODS */
  private getArrayToString(array_value: any) {
    if (array_value) {
      let array = [];

      if (typeof array_value === "string") {
        array = JSON.parse(array_value);
      } else {
        array = array_value;
      }

      return array.join(", ");
    }

    return null;
  }
}
