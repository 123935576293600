













































































import Vue from "vue";
import Component from "vue-class-component";
import { Action, State } from "vuex-class";

import { PaginationType } from "@/store/types";
import { ASSETS } from "@/services/base/apibase";
import { SeekerModel } from "@/models/seeker/seeker.model";
import { ChannelModel } from "@/models/chat/chat.model";
import { UserModel } from "@/models/user/user.model";

@Component
export default class SeekerList extends Vue {
  /* VUEX */

  /* ACTIONS */
  @Action("actionExploreSeeker", { namespace: "seeker" })
  private actionExploreSeeker: any;
  @Action("actionFavoriteSeeker", { namespace: "seeker" })
  private actionFavoriteSeeker: any;
  @Action("actionSetFavorite", { namespace: "seeker" })
  private actionSetFavorite: any;
  @Action("actionListChannel", { namespace: "chat" })
  private actionListChannel: any;
  @Action("actionCreateChannel", { namespace: "chat" })
  private actionCreateChannel: any;
  @Action("actionCheckOpponent", { namespace: "chat" })
  private actionCheckOpponent: any;
  @Action("actionSelectChannel", { namespace: "chat" })
  private actionSelectChannel: any;
  @Action("actionSelectNotification", { namespace: "notification" })
  private actionSelectNotification: any;

  /* STATE */
  @State("user_profile", { namespace: "user" })
  private user_profile!: UserModel;
  @State("array_explore", { namespace: "seeker" })
  private array_explore!: Array<SeekerModel>;
  @State("pagination_explore", { namespace: "seeker" })
  private pagination_explore!: PaginationType;
  @State("free_chat", { namespace: "chat" })
  private free_chat!: UserModel;

  /* INITIALIZATION */
  per_page = 5
  page_current = 1

  public get paginator(): number[] {
    let median = Math.floor((this.per_page / 2))
    let start = this.page_current - median
    if (start <= 0) start = 1
    return Array(this.per_page).fill(start).map((v, i)=>v+i)
  }


  /* METHODS */
  private getExplore() {
    this.actionExploreSeeker({
      success: () => {
        this.actionSelectNotification();
        this.actionFavoriteSeeker({
          success: () => {
            // Todo
          },
        });
      },
    });
  }

  private clickChat(seeker: SeekerModel) {
    this.$bvModal.show("lds-loader");

    const channel_data = new ChannelModel();
    channel_data.user_from_id = this.user_profile.id;
    channel_data.user_from.setObject(this.user_profile);

    if (this.user_profile.membership.id) {
      channel_data.user_to_id = seeker.id;
      channel_data.user_to.setObject(seeker);
      this.actionCreateChannel({
        params: channel_data,
        success: () => {
          this.$bvModal.hide("lds-loader");
          this.actionListChannel({ params: this.user_profile.id });
          this.$bvModal.show("usermessage");
        },
        error: () => {
          this.$bvModal.hide("lds-loader");
        },
      });
    } else {
      this.actionCheckOpponent({
        params: { id: seeker.id },
        success: () => {
          if (this.free_chat.id) {
            if (seeker.id === this.free_chat.id) {
              channel_data.user_to_id = this.free_chat.id;
              channel_data.user_to.setObject(this.free_chat);
              this.actionCreateChannel({
                params: channel_data,
                success: () => {
                  this.$bvModal.hide("lds-loader");
                  this.actionListChannel({ params: this.user_profile.id });
                  this.$bvModal.show("usermessage");
                },
                error: () => {
                  this.$bvModal.hide("lds-loader");
                },
              });
            } else {
              this.$bvModal.hide("lds-loader");
              this.$bvModal.show("modal-no-subscribe");
            }
          } else {
            channel_data.user_to_id = seeker.id;
            channel_data.user_to.setObject(seeker);
            this.actionCreateChannel({
              params: channel_data,
              success: () => {
                this.$bvModal.hide("lds-loader");
                this.actionListChannel({ params: this.user_profile.id });
                this.$bvModal.show("usermessage");
              },
              error: () => {
                this.$bvModal.hide("lds-loader");
              },
            });
          }
        },
        error: () => {
          this.$bvModal.hide("lds-loader");
        },
      });
    }
  }

  private clickFavorite(seeker: SeekerModel) {
    this.actionSetFavorite({
      params: seeker,
      success: () => {
        this.getExplore();
      },
    });
  }

  private onDetail(seeker: SeekerModel) {
    this.$router.push({
      name: "detail-seeker",
      params: { id: seeker.id.toString() },
    });
  }

  private onClickPage(page: number) {
    if (page !== this.pagination_explore.page_current) {
      if (page <= this.pagination_explore.page_length) {
        this.actionExploreSeeker({
          page,
          success: () => {
            this.$emit("on-pagination", ".profile-main-content");
            this.page_current = page
          },
        });
      }
    }
  }

  private getImage(image: string) {
    if (image) {
      return ASSETS + "user/photo/" + image;
    }
    return "";
  }
}
