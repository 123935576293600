


















































































import Vue from "vue";
import $ from "jquery";
import filters from "@/filters";
import Component from "vue-class-component";
import { Action, State } from "vuex-class";
import { Watch } from "vue-property-decorator";

import { UserModel } from "@/models/user/user.model";
import { MembershipModel } from "@/models/payment/payment.model";

import StripePayment from "@/components/modal/StripePayment.vue";

@Component({
  filters,
  components: {
    StripePayment,
  },
})
export default class Membership extends Vue {
  /* VUEX */

  /* ACTIONS */
  @Action("actionPaymentTazapey", { namespace: "payment" })
  private actionPaymentTazapey: any;

  /* STATE */
  @State("user_profile", { namespace: "user" })
  private user_profile!: UserModel;
  @State("array_membership", { namespace: "payment" })
  private array_membership!: Array<MembershipModel>;

  @Watch("showModalPayment")
  private listenModalPayment(val: any) {
    if (!val) {
      this.selected_membership = new MembershipModel();
    }
  }

  /* INITIALIZATION */
  private showModalPayment = false;
  private selected_membership = new MembershipModel();

  private mounted() {
    $(document).ready(function () {
      const condition = $("#collapseThree").hasClass("show");
      if (!condition) {
        $(".collapseThree").click();
      }
    });
  }

  /* METHODS */
  private async actionBuy(item: MembershipModel) {
    this.showModalPayment = true;
    this.selected_membership = item;
  }

  private actionTazapey(item: MembershipModel) {
    this.selected_membership = item;
    this.actionPaymentTazapey({
      params: { membership_id: item.id },
      success: () => {
        // todo
      },
    });
  }
}
