/* eslint-disable @typescript-eslint/no-unused-vars */
import { ActionTree } from "vuex";
import { AxiosResponse } from "axios";

import { RootState } from "../types";
import { NotificationState } from "./types";

import {
  DecryptAES,
  EncryptAES,
  RefreshKeyFromToken,
} from "@/functions/crypto.function";
import {
  deleteToken,
  getRequestKey,
  getResponseKey,
} from "@/functions/token.function";

import { createPagination } from "@/functions/global.function";
import { ResponseModel } from "@/models/response/response.model";
import { NotificationModel } from "@/models/notification/notification.model";

import router from "@/router";
import toast from "@/functions/toast.function";
import NotificationService from "@/services/notification.service";
import { TOKEN_INVALID } from "@/constants/enum.constant";

const service: NotificationService = new NotificationService();

export const actions: ActionTree<NotificationState, RootState> = {
  actionSelectNotification({ state }, callback: any) {
    service.serviceSelectNotification().then((response: AxiosResponse) => {
      if (response.status == 200) {
        if (typeof response.data === "string") {
          // Decrypt model response with AES using ResponseKey
          const resJSON = DecryptAES(response.data, getResponseKey());
          const mResponse = new ResponseModel();

          mResponse.setObject(resJSON);
          if (mResponse.token) {
            RefreshKeyFromToken(mResponse.token);
          }

          if (mResponse.status) {
            state.pagination_notification_profile = createPagination(
              mResponse.results
            );
            const { general, profile } = mResponse.results.data;
            state.array_notification_unread = new Array<number>();
            state.array_notification_general = new Array<NotificationModel>();
            state.array_notification_profile = new Array<NotificationModel>();

            /**
             * Notification general
             */
            general.forEach((notif: NotificationModel) => {
              if (notif.is_read == "not read") {
                state.array_notification_unread.push(notif.id);
              }
              state.array_notification_general.push(notif);
            });

            /**
             * Notification profile
             */
            profile.forEach((notif: NotificationModel) => {
              if (notif.is_read == "not read") {
                state.array_notification_unread.push(notif.id);
              }
              state.array_notification_profile.push(notif);
            });

            /**
             * Set unread notification count
             */
            state.unread_count = state.array_notification_unread.length;
          }

          if (callback && callback.success) {
            callback.success(mResponse);
          }
        } else {
          const { message } = response.data;
          toast.error(message);
          if (TOKEN_INVALID.includes(message)) {
            deleteToken();
            router.push({ name: "signin" }, () => {
              location.reload();
            });
          }
        }
      }
    });
  },
  actionUpdateNotification({ state }, callback: any) {
    let data: any = null;

    if (callback && callback.params) {
      data = Object.assign({}, callback.params);
    }

    if (!data) {
      toast.warning("Seeker not Found!");
      return;
    }

    // Encrypt model request with AES using RequestKey
    const params = EncryptAES(JSON.stringify(data), getRequestKey());

    service
      .serviceUpdateNotification(params)
      .then((response: AxiosResponse) => {
        if (response.status == 200) {
          if (typeof response.data === "string") {
            // Decrypt model response with AES using ResponseKey
            const resJSON = DecryptAES(response.data, getResponseKey());
            const mResponse = new ResponseModel();

            mResponse.setObject(resJSON);
            if (mResponse.token) {
              RefreshKeyFromToken(mResponse.token);
            }

            if (callback.success) {
              callback.success(mResponse);
            }
          } else {
            const { message } = response.data;
            toast.error(message);
            if (TOKEN_INVALID.includes(message)) {
              deleteToken();
              router.push({ name: "signin" }, () => {
                location.reload();
              });
            }
          }
        }
      });
  },
};
