










































































































import Vue from "vue";
import Component from "vue-class-component";
import { Action, State } from "vuex-class";

import toast from "@/functions/toast.function";

import { PaginationType } from "@/store/types";
import { LocationModel } from "@/models/region/region.model";
import { FilterSeekerModel, SeekerModel } from "@/models/seeker/seeker.model";
import { UserModel } from "@/models/user/user.model";
import { TARGET_GENDER_OPTIONS } from "@/constants/enum.constant";

@Component
export default class ModalFilterSeeker extends Vue {
  /* VUEX */

  /* ACTIONS */
  @Action("actionExploreSeeker", { namespace: "seeker" })
  private actionExploreSeeker: any;
  @Action("actionGetState", { namespace: "region" })
  private actionGetState: any;
  @Action("actionResetState", { namespace: "region" })
  private actionResetState: any;

  /* STATE */
  @State("filter_explore", { namespace: "seeker" })
  private filter_explore!: FilterSeekerModel;
  @State("array_explore", { namespace: "seeker" })
  private array_explore!: Array<SeekerModel>;
  @State("pagination_explore", { namespace: "seeker" })
  private pagination_explore!: PaginationType;
  @State("array_location", { namespace: "region" })
  private array_location!: Array<LocationModel>;
  @State("array_state", { namespace: "region" })
  private array_state!: Array<LocationModel>;
  @State("user_profile", { namespace: "user" })
  private user_profile!: UserModel;

  /* INITIALIZATION */
  private target_gender = TARGET_GENDER_OPTIONS.map((v)=>v.value)
  private targetGenderOptions = TARGET_GENDER_OPTIONS

  private created() {
    this.filter_explore.age_start = "";
    this.filter_explore.age_end = "";
    this.filter_explore.location = null;
    this.filter_explore.filter_location = "";
    this.filter_explore.state = null;
    this.filter_explore.filter_state = "";
    this.filter_explore.gender = "";
  }

  /* METHODS */
  private OnChangeTargetGender() {
    this.filter_explore.gender = this.target_gender.length===2?"":this.target_gender[0]
  }

  private onClickFilter() {
    if (this.filter_explore.age_start) {
      const intAgeStart = Number(this.filter_explore.age_start);
      if (intAgeStart < 18) {
        toast.warning(
          "Smixy is only for users that are at least 18 years old"
        );
        return;
      }

      if (this.filter_explore.age_end) {
        const intAgeEnd = Number(this.filter_explore.age_end);

        if (intAgeStart >= intAgeEnd) {
          toast.warning("Maximum age must be higher than minimum age");
          return;
        }
      }
    }

    if (this.filter_explore.age_end) {
      const intAgeEnd = Number(this.filter_explore.age_end);

      if (intAgeEnd < 18) {
        toast.warning(
          "Smixy is only for users that are at least 18 years old"
        );
        return;
      }
    }

    this.actionExploreSeeker({
      success: () => {
        document.getElementById("modal-btn-close")?.click();
      },
    });
  }

  private onSelectLocation(item: LocationModel) {
    if (item) {
      this.filter_explore.filter_location = item.name;
      this.actionGetState({
        params: item.id,
        success: () => {
          this.filter_explore.state = null;
          this.filter_explore.filter_state = "";
        },
      });
    } else {
      this.onClearLocation();
    }
  }

  private onClearLocation() {
    this.filter_explore.location = null;
    this.filter_explore.filter_location = "";
    this.filter_explore.state = null;
    this.filter_explore.filter_state = "";
    this.actionResetState();
  }

  private onSelectState(item: LocationModel) {
    this.filter_explore.filter_state = item.name;
  }

  private changeGender(gender: string) {
    this.filter_explore.gender = gender;
  }
}
