









































import Vue from "vue";
import $ from "jquery";
import { Action } from "vuex-class";
import Component from "vue-class-component";
import { deleteToken } from "@/functions/token.function";

@Component
export default class CloseAccount extends Vue {
  /* VUEX */

  /* ACTIONS */
  @Action("actionDeleteAccount", { namespace: "user" })
  private actionDeleteAccount: any;

  /* STATE */

  /* INITIALIZATION */
  private reason = null;
  private password = null;

  private mounted() {
    $(document).ready(function () {
      const condition = $("#collapseTwo").hasClass("show");
      if (!condition) {
        $(".collapseTwo").click();
      }
    });
  }

  /* METHODS */
  private actionDelete() {
    this.$bvModal.show("lds-loader");
    this.actionDeleteAccount({
      params: { reason: this.reason, password: this.password },
      success: () => {
        this.$bvModal.hide("lds-loader");
        deleteToken();
        this.$router.push({ name: "signin" }, () => {
          location.reload();
        });
      },
      error: () => {
        this.$bvModal.hide("lds-loader");
      },
    });
  }

  private getFocus(id: string) {
    document.getElementById(id)?.focus();
  }
}
