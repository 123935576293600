import { ActionTree } from "vuex";
import { AxiosResponse } from "axios";

import { RootState } from "../types";
import { ChatState } from "./types";

import {
  DecryptAES,
  EncryptAES,
  RefreshKeyFromToken,
} from "@/functions/crypto.function";
import {
  deleteToken,
  getRequestKey,
  getResponseKey,
} from "@/functions/token.function";

import { TOKEN_INVALID } from "@/constants/enum.constant";

import router from "@/router";
import firebaseApp from "@/firebase";
import toast from "@/functions/toast.function";
import ChatService from "@/services/chat.service";

import { UserModel } from "@/models/user/user.model";
import { ResponseModel } from "@/models/response/response.model";
import {
  ChannelModel,
  ChannelUserModel,
  MessageModel,
} from "@/models/chat/chat.model";

const service: ChatService = new ChatService();

export const actions: ActionTree<ChatState, RootState> = {
  actionCheckOpponent({ state }, callback: any) {
    let data: any = null;

    if (callback && callback.params) {
      data = Object.assign({}, callback.params);
    }

    if (!data) {
      toast.warning("Seeker is required!");
      return;
    }

    const plainText = JSON.stringify(data);

    console.log("PAYLOAD CHECK OPPONENT : ", plainText);

    // Encrypt model request with AES using RequestKey
    const params = EncryptAES(plainText, getRequestKey());

    service
      .serviceSelectOpponent(params)
      .then((response: AxiosResponse) => {
        if (response.status == 200) {
          if (typeof response.data === "string") {
            // Decrypt model response with AES using ResponseKey
            const resJSON = DecryptAES(response.data, getResponseKey());
            const mResponse = new ResponseModel();

            mResponse.setObject(resJSON);
            if (mResponse.token) {
              RefreshKeyFromToken(mResponse.token);
            }

            if (mResponse.status) {
              state.free_chat.setObject(mResponse.results);
            } else {
              state.free_chat = new UserModel();
              state.validation = mResponse.validation;
            }

            console.log("RESPONSE CHECK OPPONENT : ", mResponse);

            if (callback.success) {
              callback.success(mResponse);
            }
          } else {
            const { message } = response.data;
            toast.error(message);
            if (TOKEN_INVALID.includes(message)) {
              deleteToken();
              router.push({ name: "signin" }, () => {
                location.reload();
              });
            }
          }
        }
      })
      .catch((e: any) => {
        const { message } = e.response.data;
        toast.error(message);
        if (callback.error) {
          callback.error(message);
        }
        if (TOKEN_INVALID.includes(message)) {
          deleteToken();
          router.push({ name: "signin" }, () => {
            location.reload();
          });
        }
      });
  },
  actionSetOpponent({ state }, callback: any) {
    let data: any = null;

    if (callback && callback.params) {
      data = Object.assign({}, callback.params);
    }

    if (!data) {
      toast.warning("Seeker is required!");
      return;
    }

    const plainText = JSON.stringify(data);

    // Encrypt model request with AES using RequestKey
    const params = EncryptAES(plainText, getRequestKey());

    service
      .serviceUpdateOpponent(params)
      .then((response: AxiosResponse) => {
        if (response.status == 200) {
          if (typeof response.data === "string") {
            // Decrypt model response with AES using ResponseKey
            const resJSON = DecryptAES(response.data, getResponseKey());
            const mResponse = new ResponseModel();

            mResponse.setObject(resJSON);
            if (mResponse.token) {
              RefreshKeyFromToken(mResponse.token);
            }

            if (mResponse.status) {
              state.validation = null;
              toast.success(
                "You have now used your 1 free chat as a regular regular member"
              );
            }

            if (callback.success) {
              callback.success(mResponse);
            }
          } else {
            const { message } = response.data;
            toast.error(message);
            if (TOKEN_INVALID.includes(message)) {
              deleteToken();
              router.push({ name: "signin" }, () => {
                location.reload();
              });
            }
          }
        }
      })
      .catch((e: any) => {
        const { message } = e.response.data;
        toast.error(message);
        if (TOKEN_INVALID.includes(message)) {
          deleteToken();
          router.push({ name: "signin" }, () => {
            location.reload();
          });
        }
      });
  },
  actionListChannel({ state }, callback: any) {
    /**
     * Find userID
     * reset array_channel store.
     * ref the channel collections into new variable
     */

    let userId = 0;
    if (callback && callback.params) {
      userId = callback.params;
    }
    if (userId < 1) {
      return;
    }

    const new_array_channel_cs:ChannelModel[] = []
    const channelRef = (firebaseApp.channelsCollection as firebase.default.firestore.CollectionReference<firebase.default.firestore.DocumentData>);
    channelRef.where("user_from_id", "==", userId).where("user_to_id", "in", [userId, "admin"])
    channelRef.orderBy("updated_at", "desc").get().then((snapshot: any) => {
      snapshot.docs.forEach((doc: any) => {
        /**
         * validate channel by user_id into user_from_id and user_to_id from
         * if doesn't exist, push into array_channel store.
         */
        const isUserFrom = doc.data().user_from_id == userId;
        const isUserTo = doc.data().user_to_id == userId;
        const isCustomerSupport = doc.data().user_to_id === "admin"
        const isExist = state.array_channel.filter((el) => el.id === doc.id);

        const document = { id: doc.id, ...doc.data() }

        if ((isUserFrom && isExist.length === 0) || (isUserTo && isExist.length === 0)) {
          if (!isCustomerSupport) {
            state.array_channel.push(document);
          }
        }

        if (isUserFrom && isCustomerSupport) new_array_channel_cs.push(document);

        if (callback && callback.success) {
          callback.success();
        }
      });

      state.array_channel_cs = new_array_channel_cs
    }).catch((error: any) => {
      console.log("Error getting documents: ", error);
    });
  },
  actionCreateChannel({ state, dispatch }, callback: any) {
    /**
     * Create channel model
     * get user_from & user_to from callback.params
     * input the created_at & updated_at from new Date().toISOString()
     * delete channel.id to handle empty value from firestore
     */
    const { user_from, user_to } = callback.params;

    const data = {
      ...callback.params,
      user_from: Object.assign({}, user_from),
      user_to: Object.assign({}, user_to),
      created_at: new Date().toISOString(),
      updated_at: new Date().toISOString(),
    };

    delete data.id;

    /**
     * Fetch channel by user_from_id and user_to_id
     * if data is empty, create the channel.
     * if data is exist, set to channel store.
     */
    (firebaseApp.channelsCollection as firebase.default.firestore.CollectionReference<firebase.default.firestore.DocumentData>)
      .where("user_from_id", "==", callback.params.user_from_id)
      .where("user_to_id", "==", callback.params.user_to_id)
      .get()
      .then((snapshot) => {
        const { user_from_id, user_to_id } = callback.params;

        /**
         * Step 1
         * Filter channel by user_from_id
         */
        const filterByUserFrom = snapshot.docs.filter((newVal: any) => {
          if (
            newVal.data().user_from_id == user_from_id ||
            newVal.data().user_to_id == user_from_id
          ) {
            return newVal;
          }
        });

        /**
         * Step 2
         * Filter data from filterByUserFrom by user_to_id
         */
        const filterByUserTo = filterByUserFrom.filter((newVal: any) => {
          if (
            newVal.data().user_from_id == user_to_id ||
            newVal.data().user_to_id == user_to_id
          ) {
            return newVal;
          }
        });

        /**
         * Step 4
         * Check if channel exist or not
         * if exist, set channel data & channel id
         * else
         * create new channel with data
         */
        if (filterByUserTo.length === 1 && user_to_id !== "admin") {
          // state.channel.id = filterByUserTo[0].id;
          // state.channel.setObject(filterByUserTo[0].data());

          dispatch("actionSelectChannel", {
            params: {id: filterByUserTo[0].id, ...filterByUserTo[0].data()},
            sender_from: user_from,
          });

          if (callback.success) {
            callback.success();
          }
        } else {
          const filtered = state.array_channel_cs.filter(v => v.is_ended === false);
          if (filtered.length > 0 && user_to_id === "admin") {
            state.array_message = new Array<MessageModel>();
            dispatch("actionSelectChannel", {
              params: filtered[0],
              sender_from: user_from,
            });

            if (callback.success) {
              callback.success();
            }
          } else {
            state.array_message = new Array<MessageModel>();
            firebaseApp.channelsCollection
              .add(data)
              .then((docRef: any) => {
                state.channel.setObject(data);
                state.channel.id = docRef.id;

                if (user_to_id === "admin") {
                  dispatch("actionSelectChannel", {
                    params: state.channel,
                    sender_from: user_from,
                  });
                }

                if (callback.success) {
                  callback.success();
                }
              })
              .catch((e: any) => {
                if (callback.error) {
                  callback.error(e);
                }
                console.error("Error adding document: ", e);
              });
          }
        }
      })
      .catch((e: any) => {
        if (callback.error) {
          callback.error(e);
        }
        console.error("Error load snapshot: ", e);
      });
  },
  actionSelectChannel({ state }, callback: any) {
    /**
     * Reset Channel Store and set again from callback.params
     * Fetch all messages by channel_id and order by created_at descending
     */
    state.channel = new ChannelModel();
    state.channel.setObject(callback.params);

    firebaseApp.messagesCollection
      .where("channel_id", "==", state.channel.id)
      .orderBy("created_at")
      .onSnapshot((snapshot: any) => {
        /**
         * Reset store data message & array_message
         */
        state.message = new MessageModel();
        state.array_message = new Array<MessageModel>();

        snapshot.docs.forEach((doc: any) => {
          /**
           * Update All Message where sender_from_id not same with user_profile.id
           * change is_read: true
           */
          if (callback.sender_from.id !== doc.data().sender_from_id) {
            firebaseApp.messagesCollection.doc(doc.id).update({
              is_read: true,
            });
          }

          /**
           * Create validation exists
           */
          const isExist = state.array_message.filter((el) => {
            return el.id === doc.id;
          });

          /**
           * If message doesn't exist, push to store array_message
           */
          if (isExist.length === 0) {
            state.array_message.push({ id: doc.id, ...doc.data() });
          }
        });

        if (callback.success) {
          callback.success();
        }
      });
  },
  actionSendMessage({ state }, callback: any) {
    /**
     * Create model from message
     * get sender from callback.params
     * text, channel_id, created_at, updated_at, sender_from_id, sender_from is required.
     * delete param id to handler empty value in firestore
     */
    const { sender } = callback.params;
    state.message.channel_id = state.channel.id;
    state.message.created_at = new Date().toISOString();
    state.message.updated_at = new Date().toISOString();
    state.message.sender_from_id = sender.id;
    state.message.sender_from.setObject(sender);

    const senderObj = Object.assign({}, state.message.sender_from);

    const messageVal: any = Object.assign(
      {},
      { ...state.message, sender_from: senderObj }
    );

    delete messageVal.id;

    firebaseApp.messagesCollection
      .add(messageVal)
      .then(() => {
        /**
         * Update the last_message and updated_at on channel
         * Reset the message store
         */
        state.channel.last_message = messageVal.text;
        state.array_channel.map((channelItem) => {
          if (channelItem.id === state.channel.id) {
            channelItem.last_message = messageVal.text;
            if (channelItem.user_from_id == sender.id) {
              const new_user_from = new ChannelUserModel();
              new_user_from.setObject(sender);
              channelItem.user_from = Object.assign({}, new_user_from);
            }

            if (channelItem.user_to_id == sender.id) {
              channelItem.user_to.setObject(sender);
            }
          }

          return channelItem;
        });

        const newChannel: any = Object.assign(
          {},
          {
            last_message: messageVal.text,
            updated_at: new Date().toISOString(),
            unread_count: state.array_message.filter((v)=>v.is_read === false).length
          }
        );
        if (state.channel.user_from_id == sender.id) {
          const new_user_from = new ChannelUserModel();
          new_user_from.setObject(sender);
          newChannel.user_from = Object.assign({}, new_user_from);
        }

        if (state.channel.user_to_id == sender.id) {
          const new_user_to = new ChannelUserModel();
          new_user_to.setObject(sender);
          newChannel.user_to = Object.assign({}, new_user_to);
        }

        firebaseApp.channelsCollection
          .doc(state.channel.id)
          .update(newChannel)
          .then(() => {
            state.message = new MessageModel();
            if (callback.success) {
              callback.success();
            }
          });
      })
      .catch((e: any) => {
        console.error("Error adding document: ", e);
      });
  },
  actionEndChat({ state, dispatch }, callback: any) {
    firebaseApp.channelsCollection
    .doc(callback.params.id)
    .update({is_ended: true})
      .then(() => {
        if (callback.success) {
          callback.success();
        }
      });
  }
};
