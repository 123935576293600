
















































import Vue from "vue";
import Component from "vue-class-component";
import { Action, State } from "vuex-class";
import { Prop } from "vue-property-decorator";

import { UserModel } from "@/models/user/user.model";
import { deleteToken } from "@/functions/token.function";
import toast from "@/functions/toast.function";

@Component
export default class ResetPassword extends Vue {
  /* VUEX */

  /* ACTION */
  @Action("actionResetPassword", { namespace: "user" })
  private actionResetPassword: any;
  @Action("actionGenerateToken", { namespace: "handshake" })
  private actionGenerateToken: any;

  /* STATE */
  @State("user_auth", { namespace: "user" })
  private user_auth!: UserModel;

  @Prop({ default: false })
  private isShow!: boolean;

  /* INITIALIZATION */

  /* METHODS */
  private onSubmit() {
    this.actionResetPassword({
      success: (data: any) => {
        if (data.status) {
          this.$emit("on-success");
          toast.success("Your password has been changed!");
          deleteToken();
          this.generateToken();
        }
      },
    });
  }

  private generateToken() {
    this.actionGenerateToken({
      params: null,
      success: () => {
        this.$router.push({ name: "signin" });
      },
      fail: () => {
        // TODO
      },
    });
  }

  private getFocus(id: string) {
    document.getElementById(id)?.focus();
  }
}
