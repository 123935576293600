/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/messaging";
import "firebase/firestore";

const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
  measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID,
};

firebase.initializeApp(firebaseConfig);

const auth = firebase.auth();
const firestore = firebase.firestore();

let messaging = null;
let fcmToken = null;

if (firebase.messaging.isSupported()) {
  messaging = firebase.messaging();
  Notification.requestPermission()
    .then(() => {
      console.log("Permission notification granted!");
      fcmToken = messaging
        .getToken({ vapidKey: process.env.VUE_APP_FIREBASE_MESSAGING_APIKEY })
        .then((token) => {
          return token;
        });
    })
    .catch(function (err) {
      console.log("Permission notification denied!");
      console.log("Error : ", err);
    });
}

const channelsCollection = firestore.collection("channels");
const channelsQuery = channelsCollection.orderBy("updated_at", "desc");

const messagesCollection = firestore.collection("messages");
const messagesQuery = messagesCollection
  .orderBy("createdAt", "desc")
  .limit(100);

export default {
  firebase,
  auth,
  firestore,
  messaging,
  fcmToken,
  channelsCollection,
  channelsQuery,
  messagesCollection,
  messagesQuery,
};
