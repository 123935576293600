import moment from "moment";

export default {
  date: (value: Date | string, format: string) => {
    if (value) {
      if (format) {
        return moment(value).format(format);
      } else {
        return moment(value).format("DD/MM/YYYY");
      }
    }
    return "";
  },
  formater: (value: any) => {
    if (value) {
      value = value.toString();
      const result = parseInt(value, 10)
        .toFixed(2)
        .replace(/(\d)(?=(\d{3})+\.)/g, "$1.");
      return result.substr(0, result.length - 3);
    } else {
      return 0;
    }
  },
  translation: (value: any, locale: string, label = "name") => {
    if (locale === "id") {
      return value["translation"];
    }
    return value[label];
  },
  limitSpace: (value: string, spaceCount = 17) => {
    if (value) {
      return value.split(" ").slice(0, spaceCount).join(" ");
    }
    return "";
  },
};
