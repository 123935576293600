import iZtoast from "izitoast";
import i18n from "@/i18n";

/* toast - START */
// Description : toast is for message info for make simple use iztoast.
// Author : Risman Abdilah.
// Created on : Mon, 25 Oct 2021.      Updated on : Mon, 25 Oct 2021.
// Created by : Risman Abdilah.        Updated by : Risman Abdilah.
/* Version : 1.0.0 */

const toast = {
  error: (message: any, title = i18n.t("error") as string) => {
    return iZtoast.error({
      title,
      message,
      position: "bottomCenter",
    });
  },
  warning: (message: any, title = i18n.t("warning") as string) => {
    return iZtoast.warning({
      title,
      message,
      position: "bottomCenter",
    });
  },
  success: (message: any, title = i18n.t("success") as string) => {
    return iZtoast.success({
      title,
      message,
      position: "bottomCenter",
    });
  },
};

export default toast;

/* toast - END */
