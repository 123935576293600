import { Base } from "./base.type";

export class BaseModel implements Base {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public setObject(value: any) {
    if (typeof value === "string") {
      const obj = JSON.parse(value);
      for (const key in this) {
        if (obj[key]) {
          if (this[key] instanceof Date) {
            this[key] = new (this[key] as any).constructor(obj[key]);
          } else {
            this[key] = obj[key];
          }
        }
      }
    } else {
      const obj = value;
      for (const key in this) {
        if (obj[key]) {
          this[key] = obj[key];
        }
      }
    }

    if(typeof (this as any).AttributeCast === 'function') (this as any).AttributeCast()
  }

  public getString(): string {
    return JSON.stringify(this);
  }
}
