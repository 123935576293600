import { FilterSeekerType, SeekerPhotoType, SeekerType } from "./seeker.type";
import { BaseModel } from "../base/base.model";

export class SeekerModel extends BaseModel implements SeekerType {
  public id = 0;
  public username = "";
  public email = "";
  public password = "";
  public confirm_password = "";
  public first_name = "";
  public last_name = "";
  public full_name = "";
  public gender = "";
  public location: any = null;
  public state: any = null;
  public age = 0;
  public about = "";
  public birth = "";
  public feet_height = "";
  public membership_id = 0;
  public photo = "";
  public is_favourite = false;
  public total_favorite = 0;
  public work = "";
  public occupation = "";
  public income = "";
  public income_format = "";
  public salary = "";
  public salary_format = "";
  public looking_for = "";
  public height = "";
  public hair_color = "";
  public eye_color = "";
  public wear_glasses = "";
  public ethnic = "";
  public lang_spoken: any = null;
}

export class SeekerPhotoModel extends BaseModel implements SeekerPhotoType {
  id = 0;
  user_id = 0;
  photo = 0;
  is_main = false;
  approved = false;
}

export class FilterSeekerModel extends BaseModel implements FilterSeekerType {
  age_start = "";
  age_end = "";
  location = null;
  filter_location = "";
  state = null;
  filter_state = "";
  gender = "";
}
