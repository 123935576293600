import { AxiosResponse } from "axios";
import { Base } from "./base/base.service";
import { getToken } from "@/functions/token.function";

export default class NotificationService {
  public serviceSelectNotification(): Promise<AxiosResponse<any>> {
    return Base().get("notification", {
      headers: {
        "Content-Type": "text/plain",
        Authorization: `Bearer ${getToken()}`,
      },
    });
  }
  public serviceUpdateNotification(params: any): Promise<AxiosResponse<any>> {
    return Base().post("notification/update", params, {
      headers: {
        "Content-Type": "text/plain",
        Authorization: `Bearer ${getToken()}`,
      },
    });
  }
}
