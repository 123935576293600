











































import Vue from "vue";
import Component from "vue-class-component";
import { Action, Mutation, State } from "vuex-class";

import toast from "@/functions/toast.function";
import { UserPhotoModel } from "@/models/user/user.model";

@Component
export default class ModalDeletePhoto extends Vue {
  /* VUEX */

  /* ACTIONS */
  @Action("actionEditProfilePhoto", { namespace: "user" })
  private actionEditProfilePhoto: any;
  @Action("actionGetListPhoto", { namespace: "user" })
  private actionGetListPhoto: any;
  @Action("actionCheckUser", { namespace: "user" })
  private actionCheckUser: any;
  @Action("actionGetMembership", { namespace: "user" })
  private actionGetMembership: any;

  /* STATE */
  @State("user_photo", { namespace: "user" })
  private user_photo!: Array<UserPhotoModel>;
  @State("user_detail_photo", { namespace: "user" })
  private user_detail_photo!: UserPhotoModel;

  @Mutation("mutationUserDetailPhoto", { namespace: "user" })
  private mutationUserDetailPhoto: any;

  /* INITIALIZATION */

  /* METHODS */
  private onDeletePhoto() {
    let params: any = Object.assign(
      {},
      { removed_photo: this.user_detail_photo.id }
    );

    if (this.user_detail_photo.is_main) {
      const filterUser = this.user_photo.filter(
        (item) => item.id !== this.user_detail_photo.id
      );

      if (filterUser.length > 0) {
        params.is_main = filterUser[0].id;
      } else {
        params.is_main = 1;
      }
    }

    this.actionEditProfilePhoto({
      params,
      success: () => {
        this.onSuccessDelete();
      },
    });
  }

  private onSuccessDelete() {
    if (this.user_detail_photo.is_main) {
      this.actionCheckUser({
        success: () => {
          this.actionGetMembership();
          this.actionGetListPhoto();
          this.mutationUserDetailPhoto({});
          toast.success(this.$i18n.t("success_delete_profile_photo"));
          this.$bvModal.hide("modal-main-photo");
        },
        fail: () => {
          this.$bvModal.hide("modal-main-photo");
        },
      });
    } else {
      this.actionGetListPhoto();
      this.mutationUserDetailPhoto({});
      toast.success(this.$i18n.t("success_delete_profile_photo"));
      this.$bvModal.hide("modal-delete-photo");
    }
  }
}
