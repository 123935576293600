































import toast from "@/functions/toast.function";
import i18n from "@/i18n";
import Vue from "vue";
import Component from "vue-class-component";
import { Action } from "vuex-class";

@Component
export default class VerifyOTP extends Vue {
  /* VUEX */

  /* ACTIONS */
  @Action("actionCheckEmail", { namespace: "user" })
  private actionCheckEmail: any;
  @Action("actionVerifyOTP", { namespace: "user" })
  private actionVerifyOTP: any;

  /* STATE */

  /* INITIALIZATION */
  private start = 60;
  private timer: any = null;
  private otp: any = null;

  private created() {
    this.start = 60;
    this.startTimer();
  }

  /* METHODS */
  private startTimer() {
    this.timer = setInterval(() => {
      if (this.start < 1) {
        clearInterval(this.timer);
      } else {
        this.start = this.start - 1;
      }
    }, 1000);
  }

  private onVerify() {
    this.actionVerifyOTP({
      params: this.otp,
      success: (data: any) => {
        this.$emit("on-success", data);
      },
    });
  }

  private onResend() {
    this.actionCheckEmail({
      success: (data: any) => {
        const { first_name } = data.results;
        if (!first_name) {
          toast.success(i18n.t("success_resend_otp"));
          this.start = 60;
          this.startTimer();
        }
      },
    });
  }
}
