














































































import Vue from "vue";
import Component from "vue-class-component";
import { Action, Getter, State } from "vuex-class";

import { fileToBase64 } from "@/functions/global.function";
import { UserModel, UserPhotoModel } from "@/models/user/user.model";

import i18n from "@/i18n";
import firebase from "firebase";
import firebaseApp from "@/firebase";
import toast from "@/functions/toast.function";

import VerifyOTP from "@/components/signup/VerifyOTP.vue";
import CreatePassword from "@/components/signup/CreatePassword.vue";
import CreateProfile from "@/components/signup/CreateProfile.vue";
import UploadPhoto from "@/components/signup/UploadPhoto.vue";

@Component({
  components: {
    "verify-otp": VerifyOTP,
    "create-password": CreatePassword,
    "create-profile": CreateProfile,
    "upload-photo": UploadPhoto,
  },
})
export default class Signup extends Vue {
  /* VUEX */

  /* ACTIONS */
  @Action("authLayout")
  private authLayout: any;
  @Action("actionCheckEmail", { namespace: "user" })
  private actionCheckEmail: any;
  @Action("actionCreateProfile", { namespace: "user" })
  private actionCreateProfile: any;
  @Action("actionUploadPhoto", { namespace: "user" })
  private actionUploadPhoto: any;
  @Action("actionLogin", { namespace: "user" })
  private actionLogin: any;
  @Action("actionLoginSosmed", { namespace: "user" })
  private actionLoginSosmed: any;
  @Action("actionGetLocation", { namespace: "region" })
  private actionGetLocation: any;
  @Action("actionListChannel", { namespace: "chat" })
  private actionListChannel: any;
  @Action("actionSelectNotification", { namespace: "notification" })
  private actionSelectNotification: any;
  @Action("actionGetMembership", { namespace: "user" })
  private actionGetMembership: any;
  @Action("actionExploreSeeker", { namespace: "seeker" })
  private actionExploreSeeker: any;
  @Action("actionFavoriteSeeker", { namespace: "seeker" })
  private actionFavoriteSeeker: any;

  /* STATE */
  @State("user_auth", { namespace: "user" })
  private user_auth!: UserModel;
  @State("user_profile", { namespace: "user" })
  private user_profile!: UserModel;
  @State("user_photo", { namespace: "user" })
  private user_photo!: Array<UserPhotoModel>;

  @Getter("isLoggedIn", { namespace: "user" })
  private isLoggedIn!: boolean;

  /* INITIALIZATION */
  private tabActive = 1;

  private created() {
    this.authLayout();
    const { params } = this.$route;
    if (this.isLoggedIn) {
      this.navigateHome();
    }

    if (params.page) {
      switch (params.page) {
        case "otp":
          this.tabActive = 2;
          break;
        case "password":
          this.tabActive = 3;
          break;
        case "create_profile":
          this.tabActive = 4;
          break;
        case "upload_photo":
          this.tabActive = 5;
          break;
        default:
          this.tabActive = 1;
          break;
      }
    }
  }

  /* METHODS */
  private onUploadPhoto() {
    if (this.user_photo.length < 1) {
      toast.warning("Please upload at least 1 photo of yourself");
      return;
    }
    this.$bvModal.show("lds-loader");

    this.actionCreateProfile({
      success: () => {
        const fileMap = Object.assign({});
        const photoMap = new Array<any>();

        this.user_photo.forEach(async (item) => {
          if (item.is_main) {
            fileMap.is_main = this.user_photo.indexOf(item);
          }

          fileToBase64(item.image, (res: any) => {
            photoMap.push(res);
          });
        });

        fileMap.photo = photoMap;

        this.actionUploadPhoto({
          params: fileMap,
          success: () => {
            this.handleLogin();
          },
          error: () => {
            this.$bvModal.hide("lds-loader");
          },
        });
      },
    });
  }

  private onCreateProfile() {
    this.$bvModal.show("lds-loader");
    this.actionCreateProfile({
      success: () => {
        this.onChangeTab(1);
        this.$bvModal.hide("lds-loader");
        this.handleLogin();
      },
    });
  }

  private onCheckEmail() {
    this.actionCheckEmail({
      success: (data: any) => {
        const { first_name, register_status } = data.results;
        if (first_name) {
          this.$router.push({ name: "signin", params: { page: "password" } });
        } else {
          if (register_status) {
            this.onChangeTab(3);
          } else {
            this.onChangeTab(2);
            toast.success(i18n.t("success_send_otp"));
          }
        }
      },
    });
  }

  private async handleLogin() {
    if (this.user_auth.password) {
      if (firebaseApp) {
        const fcm_token = await firebaseApp.fcmToken;
        if (fcm_token) {
          this.actionLogin({
            params: fcm_token,
            success: (res: any) => {
              this.$bvModal.hide("lds-loader");
              if (res.status) {
                localStorage.removeItem("user_gender");
                localStorage.removeItem("user_birthday");
                localStorage.removeItem("user_location");
                this.onChangeTab(1);
                this.navigateHome();
              }
            },
            error: () => this.onErrorLogin(),
          });
        } else {
          this.defaultLogin();
        }
      } else {
        this.defaultLogin();
      }
    }
  }

  private defaultLogin() {
    this.actionLogin({
      params: null,
      success: (res: any) => {
        this.$bvModal.hide("lds-loader");
        if (res.status) {
          localStorage.removeItem("user_gender");
          localStorage.removeItem("user_birthday");
          localStorage.removeItem("user_location");
          this.onChangeTab(1);
          this.navigateHome();
        }
      },
      error: () => this.onErrorLogin(),
    });
  }

  private onErrorLogin() {
    this.$bvModal.hide("lds-loader");
    localStorage.clear();
    this.$router.push({ name: "landing" }, () => {
      location.reload();
    });
  }

  private onLoginGoogle() {
    this.$bvModal.show("lds-loader");

    const googleProvider = new firebase.auth.GoogleAuthProvider();
    googleProvider.addScope("email");
    googleProvider.addScope("https://www.googleapis.com/auth/userinfo.profile");
    googleProvider.addScope("https://www.googleapis.com/auth/user.gender.read");
    googleProvider.addScope(
      "https://www.googleapis.com/auth/user.birthday.read"
    );
    firebaseApp.auth
      .signInWithPopup(googleProvider)
      .then(async (res: any) => {
        const { email } = res.user;
        const { profile } = res.additionalUserInfo;
        const fcm_token = await firebaseApp.fcmToken;

        this.actionLoginSosmed({
          params: {
            platform: "google",
            email,
            first_name: profile.given_name,
            last_name: profile.family_name,
            fcm_token,
          },
          success: () => {
            if (!this.user_profile.location) {
              this.user_auth.setObject(this.user_profile);
              this.actionGetLocation({
                success: () => {
                  this.$router.push({
                    name: "signup",
                    params: { page: "create_profile" },
                  });
                },
              });
            } else {
              this.$router.push({ name: "dashboard" }, () => {
                location.reload();
              });
            }
          },
        });
        this.$bvModal.hide("lds-loader");
      })
      .catch((e: any) => {
        console.log("ERROR : ", e);
        this.$bvModal.hide("lds-loader");
      });
  }

  private onChangeTab(tab: number) {
    this.tabActive = tab;
  }

  private navigateHome() {
    this.$router.push({ name: "dashboard" }, () => {
      location.reload();
      this.actionGetMembership();
      this.getExplore();
      this.actionListChannel({ params: this.user_profile.id });
    });
  }

  private getExplore() {
    this.actionExploreSeeker({
      success: () => {
        this.actionSelectNotification();
        this.actionFavoriteSeeker();
      },
    });
  }
}
