import { ActionTree } from "vuex";
import router from "@/router";

import { RootState } from "../types";
import { JSEncrypt } from "jsencrypt";
import { Handshake } from "@/models/handshake/handshake.type";
import { HandshakeModel } from "@/models/handshake/handshake.model";
import { RequestModel } from "@/models/request/request.model";
import { ResponseModel } from "@/models/response/response.model";

import HandshakeService from "@/services/handshake.service";
import toast from "@/functions/toast.function";

import {
  DecryptAES,
  ParseHandshakeFromToken,
} from "@/functions/crypto.function";
import {
  deleteAuthKey,
  deleteRequestKey,
  deleteResponseKey,
  getAuthKey,
  getRequestKey,
  getResponseKey,
  getToken,
  setAuthKey,
  setRequestKey,
  setResponseKey,
  setToken,
} from "@/functions/token.function";

const serviceHandshake: HandshakeService = new HandshakeService();

export const actions: ActionTree<Handshake, RootState> = {
  actionGenerateToken({ state }, callback: any) {
    const RequestKey = getRequestKey();
    const ResponseKey = getResponseKey();
    if (
      !getToken() ||
      (RequestKey && RequestKey.length != 32) ||
      (ResponseKey && ResponseKey.length != 32)
    ) {
      deleteRequestKey();
      deleteResponseKey();
      deleteAuthKey();

      let privateKey = localStorage.getItem("p") ?? "";
      let publicKey = localStorage.getItem("b");

      if (!privateKey && !publicKey) {
        const encrypt: JSEncrypt = new JSEncrypt({ default_key_size: "1024" });
        encrypt.getKey();

        const arrayPublicKey = encrypt.getPublicKey().split("\n");
        arrayPublicKey.shift();
        arrayPublicKey.pop();

        publicKey = arrayPublicKey.join("");
        privateKey = encrypt.getPrivateKey();
        state.RequestKey = publicKey;
      }

      const modelRequest: RequestModel = new RequestModel();
      modelRequest.Data = state.getString();

      const params = publicKey;
      serviceHandshake
        .generateToken(params)
        .then((response) => {
          const decrypt: JSEncrypt = new JSEncrypt();
          decrypt.setPrivateKey(privateKey);

          if (response.status === 200) {
            const decryptRes = decrypt.decrypt(response.data);
            const data = JSON.parse(decryptRes.toString());

            const modelHandshake = new HandshakeModel();
            modelHandshake.RequestKey = data.c;
            modelHandshake.ResponseKey = data.d;
            modelHandshake.Token = data.e;

            setRequestKey(modelHandshake.RequestKey);
            setResponseKey(modelHandshake.ResponseKey);
            setAuthKey(modelHandshake.Token);

            callback.success(data);
          } else {
            callback.fail(response);
          }
        })
        .catch((response: any) => {
          console.log(response);
        });
    }
  },
  actionRefreshToken({ state, dispatch }, callback: any) {
    const authorizekey = getAuthKey();
    if (authorizekey) {
      const modelRequest: RequestModel = new RequestModel();

      // Put model user into data field model request
      state.Token = authorizekey as any;
      modelRequest.Data = state.getString();

      serviceHandshake
        .refreshToken()
        .then((response: any) => {
          if (response.status === 200) {
            // Decrypt model response with AES using ResponseKey
            if (response.headers["content-type"] === "application/json") {
              toast.warning(response.data.message, "Warning");
              dispatch("actionGenerateToken", {
                params: null,
                success: () => {
                  // todo
                },
                fail: () => {
                  // TODO
                },
              });
              return;
            }

            const plaintext = DecryptAES(response.data, getResponseKey());
            const modelResponse = new ResponseModel();
            modelResponse.setObject(plaintext);

            try {
              const modelHandshake = new HandshakeModel();
              modelHandshake.setObject(
                ParseHandshakeFromToken(response.headers.token)
              );

              setRequestKey(modelHandshake.RequestKey);
              setResponseKey(modelHandshake.ResponseKey);
              setToken(modelHandshake.Token);
            } catch {
              // TODO
            }

            callback.success(modelResponse);
          } else {
            // TODO
            callback.fail(response);
          }
        })
        .catch((response: any) => {
          console.log(response);
        });
    } else {
      localStorage.clear();
      router.push({ name: "signin" });
      dispatch("actionGenerateToken", {
        params: null,
        success: () => {
          // TODO
        },
        fail: () => {
          // TODO
        },
      });
    }
  },
};
