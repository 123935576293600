import { BaseModel } from "../base/base.model";
import { ChannelType, ChannelUserType, MessageType } from "./chat.type";

export class ChannelModel extends BaseModel implements ChannelType {
  public id = "";
  public created_at = "";
  public updated_at = "";
  public user_from_id = 0;
  public user_to_id = 0;
  public last_message = "";
  public is_blocked = false;
  public is_reported = false;
  public is_deleted = false;
  public is_online = false;
  public is_ended = false
  public unread_count = 0;
  public user_from = new ChannelUserModel();
  public user_to = new ChannelUserModel();
}

export class MessageModel extends BaseModel implements MessageType {
  public id = "";
  public text = "";
  public created_at = "";
  public updated_at = "";
  public channel_id = "";
  public sender_from_id = "";
  public is_read = false;
  public is_liked = false;
  public is_deleted = false;
  public sender_from = new ChannelUserModel();
}

export class ChannelUserModel extends BaseModel implements ChannelUserType {
  public id = 0;
  public age = 0;
  public email = "";
  public about = "";
  public birth = "";
  public gender = "";
  public full_name = "";
  public last_name = "";
  public first_name = "";
  public occupation = "";
  public membership_id = 0;
  public photo: any = null;
  public state: any = null;
  public location: any = null;
  public created_at: any = null;
  public updated_at: any = null;
}
